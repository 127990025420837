import styled from "styled-components";
import { primaryPalette } from "../../../styles/theme";
import { Button, CardContent } from "@mui/material";

export const StyledCardContent = styled(CardContent)`
  width: 100%;
  padding: 4px 4px 10px 8px;
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const ArticleFilterButton = styled(Button)`
  background: ${primaryPalette.teal.neutral_teal_t2};
  color: ${primaryPalette.teal.neutral_teal_t6};
  text-transform: unset !important;
  display: flex;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  text-align: center;
  font-family: Rubik Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &:hover {
    background: unset;
  }
`;
