import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  GetArticlesResponse,
  GetClinVarArticlesResponse,
} from "../../network/articles";
import { CurationRecordVariant } from "../../types/articles";

export interface MenuItem {
  id: string;
  label: string;
  proOnly: boolean;
  asc: boolean;
  localSort?: boolean;
  biDirectional?: boolean;
}

export const filterItems: MenuItem[] = [
  {
    id: "supplemental",
    label: "All Articles",
    proOnly: false,
    asc: false,
  },
  {
    id: "fulltext",
    label: "Full Text Only",
    proOnly: true,
    asc: false,
  },
  // TODO: Uncomment when we can filter by curated and clinvar evidence
  // {
  //   id: "curated",
  //   label: "Curated Evidence",
  // },
  // {
  //   id: "clinvar",
  //   label: "ClinVar Evidence",
  // },
];

export const sortItems: MenuItem[] = [
  {
    id: "date",
    label: "Publication Date",
    asc: false,
    localSort: false,
    proOnly: false,
    biDirectional: true,
  },
  {
    id: "journal",
    label: "Journal Name",
    asc: true,
    localSort: false,
    proOnly: false,
    biDirectional: true,
  },
  {
    id: "impactScore",
    label: "Impact Factor",
    asc: false,
    localSort: false,
    proOnly: false,
  },
  {
    id: "relevance",
    label: "Relevance",
    asc: false,
    localSort: false,
    proOnly: true,
  },
  {
    id: "match_count",
    label: "Match Count",
    asc: false,
    localSort: false,
    proOnly: true,
  },
];

export const defaultSortNonPro = sortItems.find((val) => val.id === "date");
export const defaultSortPro = sortItems.find((val) => val.id === "relevance");

export interface ArticleState {
  articleSearchFilter: string;
  articleFilterItem: MenuItem;
  articleSortItem: MenuItem;
  articleListData?: GetArticlesResponse;
  clinVarArticles: GetClinVarArticlesResponse;
  showCuratedContent: boolean;
  selectedVariant?: CurationRecordVariant;
}

export const initialArticleSliceState: ArticleState = {
  articleSearchFilter: "",
  articleFilterItem: filterItems[0],
  articleSortItem: sortItems[0],
  articleListData: undefined,
  clinVarArticles: {
    pmids: {},
  },
  showCuratedContent: false,
  selectedVariant: undefined,
};

export const articleSlice = createSlice({
  name: "article",
  initialState: initialArticleSliceState,
  reducers: {
    updateArticleSearchFilter: (
      state: ArticleState,
      action: PayloadAction<string>
    ) => {
      state.articleSearchFilter = action.payload;
    },
    updateArticleSearchFilterItem: (
      state: ArticleState,
      action: PayloadAction<MenuItem>
    ) => {
      state.articleFilterItem = action.payload;
    },
    updateArticleSearchSortItem: (
      state: ArticleState,
      action: PayloadAction<MenuItem>
    ) => {
      state.articleSortItem = action.payload;
    },
    resetArticleFilter: (state: ArticleState) => {
      state.articleSearchFilter = "";
    },
    updateArticleListData: (
      state: ArticleState,
      action: PayloadAction<GetArticlesResponse>
    ) => {
      state.articleListData = action.payload;
    },
    updateClinVarArticlesData: (
      state: ArticleState,
      action: PayloadAction<GetClinVarArticlesResponse>
    ) => {
      state.clinVarArticles = action.payload;
    },
    displayCuratedContent: (
      state: ArticleState,
      action: PayloadAction<boolean>
    ) => {
      state.showCuratedContent = action.payload;
    },
    updateSelectedVariant: (
      state: ArticleState,
      action: PayloadAction<CurationRecordVariant>
    ) => {
      state.selectedVariant = action.payload;
    },
  },
});

export const {
  updateArticleSearchFilter,
  updateArticleSearchFilterItem,
  updateArticleSearchSortItem,
  resetArticleFilter,
  updateArticleListData,
  updateClinVarArticlesData,
  displayCuratedContent,
  updateSelectedVariant,
} = articleSlice.actions;

export default articleSlice.reducer;
