import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { AcmgCriteria } from "../../../../types/gene";
import {
  initialACMGCriteria,
  useGetGeneInfoQuery,
} from "../../../../network/genes";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAppSelector } from "../../../../store/hooks";
import { SearchBarTerms } from "../../../../store/slices/searchSlice";
import { selectUrlTermIds } from "../../../../store/selectors/urlSelectors";

const Footnote = styled(Typography)(() => ({
  fontSize: "0.65rem",
}));

export const extractFootnotes = (acmgCriteria: AcmgCriteria) => {
  return Object.keys(acmgCriteria).flatMap((key) => {
    const entries = acmgCriteria[key];
    return entries.map((entry) => entry.citation).filter(Boolean);
  });
};

export function SummaryFootnotes() {
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const { data: geneData } = useGetGeneInfoQuery(
    urlTermIds[SearchBarTerms.gene][0]
      ? {
          gene: urlTermIds[SearchBarTerms.gene][0],
        }
      : skipToken
  );

  const footnotes = extractFootnotes(
    geneData?.acmg_criteria?.data ?? initialACMGCriteria
  );

  if (!footnotes.length) return;

  return (
    <Box data-testid={"gene-summaryFootnotes"} pb={4}>
      {footnotes.map((footnote, index) => {
        return (
          <Grid key={footnote} container spacing={0}>
            {index === 0 && (
              <Grid
                item
                xs={12}
                sx={{ paddingTop: "2rem", paddingBottom: "0.5rem" }}
              >
                <Divider />
              </Grid>
            )}
            <Grid item xs="auto">
              <Footnote>{`${index + 1}.`}</Footnote>
            </Grid>
            <Grid item xs sx={{ paddingLeft: "0.25rem" }}>
              <Footnote>{footnote}</Footnote>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
}
