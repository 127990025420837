import React from "react";
import VariantHistogram from "./VariantHistogram";
import VariantArticleCount from "./VariantArticleCount/VariantArticleCount";
import Skeleton from "@mui/material/Skeleton";
import { CIRCLE_GRAPH_BUTTON_HISTOGRAM_MINIMAP_HEIGHT } from "../../../pages/Gene/Gene";
import { useMediaQuery, useTheme } from "@mui/material";
import { useGetVariantsQuery } from "../../../network/variants/variants";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAppSelector } from "../../../store/hooks";
import { SearchBarTerms } from "../../../store/slices/searchSlice";
import { selectUrlTermIds } from "../../../store/selectors/urlSelectors";
export const STICK_HEIGHT: number = 20 as const;
export const MARGIN = { top: 25, right: 30, bottom: 30, left: 25 } as const;
export const GRAPH_HEIGHT: number = 200 as const;
export const XAXIS_HEIGHT: number = 20 as const;
export const PROTEINS_HEIGHT: number = 15 as const;
export const PROTEIN_MARGIN_TOP: number = 5 as const;

export const VariantHistogramContainer = () => {
  const { urlTermBooleans, urlCats, urlSigTerms } = useAppSelector(
    (state) => state.url
  );
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const searchedGene = urlTermIds[SearchBarTerms.gene][0];
  const { isLoading, isFetching, data, isError } = useGetVariantsQuery(
    searchedGene
      ? {
          urlTermIds: urlTermIds,
          urlBooleanParams: urlTermBooleans,
          urlCats: urlCats,
          urlSigTerms: urlSigTerms,
          urlString: urlTermIds.toString(),
        }
      : skipToken
  );
  const miniMapHeight =
    CIRCLE_GRAPH_BUTTON_HISTOGRAM_MINIMAP_HEIGHT.height +
    CIRCLE_GRAPH_BUTTON_HISTOGRAM_MINIMAP_HEIGHT.upDownPadding * 2;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  if (isLoading || isFetching) {
    return (
      <Skeleton
        data-testid={"variant-histogram-loader"}
        variant="rectangular"
        width={"100%"}
        height={
          XAXIS_HEIGHT +
          GRAPH_HEIGHT +
          MARGIN.top +
          MARGIN.bottom +
          miniMapHeight +
          STICK_HEIGHT +
          5
        }
      />
    );
  } else if (isError) {
    return <></>;
  }

  return (
    <div
      style={{
        height:
          XAXIS_HEIGHT +
          GRAPH_HEIGHT +
          MARGIN.top +
          MARGIN.bottom +
          miniMapHeight +
          STICK_HEIGHT +
          10,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          position: "absolute",
          marginTop: miniMapHeight / 2,
          marginLeft: isMobile ? "0px" : "16px",
        }}
      >
        <VariantArticleCount />
      </div>
      <VariantHistogram key={data?.variants[0]?.name} />
    </div>
  );
};
