import React from "react";
import { useAppSelector } from "../../../../store/hooks";
import { getProSignUpLink } from "../../../../utils/links";
import ComponentLevelPaywall from "../../../gene/common/ComponentLevelPaywall";

export default function AnonymousPaywall() {
  const { user } = useAppSelector((state) => state.user);

  return (
    <ComponentLevelPaywall
      titleText={"basic edition preview"}
      bodyText={"Take a look inside for full list of articles."}
      signUpLink={getProSignUpLink(user)}
      showLogIn={true}
    />
  );
}
