import React from "react";
import { Domain } from "../../../types/gene";
import { FlexColumnDiv } from "../../common/Flex";
import { Typography } from "@mui/material";
import GenomenonPopper from "../../common/popper/GenomenonPopper";

type TooltipProps = {
  interactionData: Domain | undefined;
  anchorEl: SVGRectElement | null;
};

export function ProteinDomainPopper({
  interactionData,
  anchorEl,
}: TooltipProps) {
  const open = Boolean(interactionData && anchorEl);

  return (
    <GenomenonPopper
      id={"protein-domain-popper"}
      open={open}
      anchorEl={anchorEl}
    >
      <FlexColumnDiv style={{ padding: 10 }}>
        <Typography data-testid="protein-domain-name" variant={"h5"}>
          {interactionData?.name}
        </Typography>
        <Typography data-testid="protein-domain-range" variant={"body1"}>
          Domain: {interactionData?.start}-{interactionData?.end}
        </Typography>
      </FlexColumnDiv>
    </GenomenonPopper>
  );
}
