import React from "react";
import { Divider, ListItemIcon, MenuItem, Typography } from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  daysPastDue,
  isIntro,
  isPastDue,
  isTrial,
  timeRemainingText,
} from "../../../../utils/user";
import { User } from "../../../../types/user";
import { userSignOut } from "../../../../network/user";
import { resetUser } from "../../../../store/slices/userSlice";
import { setNotifications } from "../../../../store/slices/notificationSlice";
import { useHandleError } from "../../../../hooks/errorHandlerHook";
import NavBarDropdownItem, {
  NavBarDropdownItemProps,
} from "../../NavBarDropdownItem";
import { splitFeatureFlags } from "../../../../utils/splitFeatures";

const accountMenuItems: NavBarDropdownItemProps[] = [
  {
    id: "alerts",
    label: "My Alerts",
    route: "/alerts/list",
  },
  {
    id: "reporter",
    label: "My Reports",
    route: "/reporter",
  },
  {
    id: "users-profile",
    label: "My Profile",
    route: "/users/profile",
  },
  {
    id: "users-edit",
    label: "Edit Account",
    route: "/users/edit",
  },
];

const listItemPadding = ".5rem 1rem 1rem";

const AccountDropdown = () => {
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const handleError = useHandleError();

  const onLogoutClick = () => {
    userSignOut()
      .then(async () => {
        dispatch(resetUser());
        dispatch(setNotifications([]));
        await splitFeatureFlags(dispatch, handleError);
      })
      .catch((err) => {
        handleError(err);
      });
  };

  return (
    <>
      <Typography component="li" padding={listItemPadding}>
        Welcome, {user.first_name + " " + user.last_name}!
      </Typography>

      <Divider />

      {accountMenuItems.map((item) => (
        <React.Fragment key={item.id}>
          <NavBarDropdownItem {...item} />
        </React.Fragment>
      ))}

      <Divider />

      <Typography component="li" padding={listItemPadding}>
        Subscription: {user.subscription_description}
      </Typography>

      {isPastDue(user as User) ? (
        <Typography
          data-testid="past-due-text"
          component="li"
          padding={listItemPadding}
        >
          {daysPastDue(user as User)} past due
        </Typography>
      ) : null}

      {isIntro(user as User) || isTrial(user as User) ? (
        <Typography
          data-testid="trial-time-remaining"
          component="li"
          padding={listItemPadding}
        >
          <i>{timeRemainingText(user as User)}</i>
        </Typography>
      ) : null}

      <Divider />

      <MenuItem onClick={() => onLogoutClick()}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        Logout
      </MenuItem>
    </>
  );
};

export default AccountDropdown;
