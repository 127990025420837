import React from "react";
import { Box } from "@mui/material";
import {
  ArticleViewerText,
  ArticleViewerTitle,
} from "../StyledViewerComponents";
import { primaryPalette } from "../../../../styles/theme";
import { styled } from "styled-components";
import DOMPurify from "dompurify";

const HighlightedSentence = styled.div`
  .abstract highlight {
    background-color: ${primaryPalette.dataVis.oldMM};
  }
`;

export default function ArticleParagraph(props: {
  label: string;
  paragraph: string;
}) {
  const { label, paragraph } = props;

  return (
    <Box>
      <Box>
        <ArticleViewerTitle
          sx={{ fontSize: "14px", fontWeight: "500", letterSpacing: "0.56px" }}
        >
          {label}
        </ArticleViewerTitle>
      </Box>

      <HighlightedSentence>
        <ArticleViewerText
          className="abstract"
          sx={{ fontSize: "14px", fontWeight: "300", lineHeight: "22px" }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(paragraph, {
              ALLOWED_TAGS: ["highlight"],
            }),
          }}
        />
      </HighlightedSentence>
    </Box>
  );
}
