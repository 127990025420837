import React, { useRef } from "react";
import { Category } from "../../store/slices/categoryLayoutSlice";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Categories } from "../../store/slices/searchSlice";
import { isProUser } from "../../utils/user";
import ProWarningHeader from "./ProWarningHeader";
import { useGetCategoryCounts } from "../../hooks/useGetCategoryCounts";
import { setUserSelectedCategories } from "../../store/slices/filterSlice";
import {
  keywordLabel,
  keywordCheckbox,
} from "../../styles/category-popup-modal";
import {
  hasDuplicates,
  selectDuplicates,
  deSelectDuplicates,
} from "../common/helpers/KeywordHelper";

export default function KeywordModal(props: {
  category: Category;
  handleSelection: () => void;
  handleCancel: () => void;
}) {
  const { duplicates } = useAppSelector((state) => state.categoryLayout);
  const { category, handleCancel, handleSelection } = props;
  const keywords = category.children;
  const categoryName = category.displayName;
  const selectedCategories = useAppSelector(
    (state) => state.filter.userSelectedCategories
  );
  const { user } = useAppSelector((state) => state.user);

  const categoryRef = useRef<Categories>({ ...selectedCategories }); // ref that stores selected category
  const inputRef = useRef<{ [key: string]: HTMLInputElement | null }>({}); // checkbox ref for changing state dynamically
  const categoryCountRef = useRef<{ [key: string]: HTMLElement | null }>({}); // Element ref for changing selected count

  const { keywordCounts } = useAppSelector((state) => state.keywordCount);
  const dispatch = useAppDispatch();

  useGetCategoryCounts(category.key);

  const keywordsMatch = (a: string, b: Category) => {
    return a === b.key;
  };

  const currentSelectedCategories = (kws: Category[]) => {
    return Object.keys(categoryRef.current).filter((a) =>
      kws.some((b) => keywordsMatch(a, b))
    );
  };

  const handleDone = () => {
    dispatch(setUserSelectedCategories(categoryRef.current));
    handleSelection();
  };

  const selectedKeywordCount = (kws: Category[]) => {
    const currentCategoriesKeys = Object.keys(categoryRef.current);
    const individualSelected = currentSelectedCategories(kws).length;
    let ancestorCount = 0;

    for (const kw of kws) {
      const ancestorSelected = currentCategoriesKeys.filter((a) =>
        kw.parentKeys.some((b) => a === b)
      );

      ancestorCount += ancestorSelected.length;
    }

    if (ancestorCount > 0) {
      return ancestorCount;
    }

    if (individualSelected > 0) {
      return individualSelected;
    }
    return 0;
  };

  const selectCount = (keywords: Category[], category: Category) => {
    const selectCountArray: Record<string, number> = {};

    if (keywords[0]?.children?.length > 0) {
      keywords.forEach((keyword) => {
        selectCountArray[keyword.key] = selectedKeywordCount(keyword.children);
      });
    } else {
      selectCountArray[category.key] = selectedKeywordCount(keywords);
    }

    return selectCountArray;
  };

  const selectCountRef = useRef<{ [key: string]: number }>({
    ...selectCount(keywords, category),
  }); // ref that stores selected count

  const getCategoryCountDescription = (
    category: Category,
    count: number
  ): string => {
    selectCountRef.current[category.key] = count;

    let categoryName = category.displayName;
    let immediateParentKey = "";

    if (category.parentKeys.length > 0) {
      immediateParentKey = category.parentKeys.slice(-1)[0]; // Access the last item in parentKeys array
    }

    const parentCount = keywordCounts[immediateParentKey]?.[category.key] ?? "";
    categoryName = `${parentCount} ${categoryName}`;

    const totalCount = category.children.length;
    return `${categoryName} - (${count}/${totalCount})`;
  };

  const handleMultiSelect = (groupCategory: Category) => {
    let cats = { ...categoryRef.current };
    cats[groupCategory.key] = {
      key: groupCategory.key,
    };
    for (const child of groupCategory.children) {
      if (inputRef.current[child.key]) {
        inputRef.current[child.key]!.checked = true;
      }
      if (categoryCountRef.current[child.key]) {
        categoryCountRef.current[child.key]!.innerText =
          getCategoryCountDescription(child, child.children.length);
      }
      // Add duplicates
      if (hasDuplicates(child.displayName, duplicates) && !child.children) {
        cats = selectDuplicates(
          child.displayName,
          child.key,
          cats,
          duplicates,
          selectedCategories
        );
      }
      delete cats[child.key];
      for (const gChild of child.children) {
        inputRef.current[gChild.key]!.checked = true;

        // Add duplicates
        if (hasDuplicates(gChild.displayName, duplicates)) {
          cats = selectDuplicates(
            gChild.displayName,
            gChild.key,
            cats,
            duplicates,
            selectedCategories
          );
        }
        delete cats[gChild.key];
      }
    }

    if (categoryCountRef.current[groupCategory.key]) {
      categoryCountRef.current[groupCategory.key]!.innerText =
        getCategoryCountDescription(
          groupCategory,
          groupCategory.children.length
        );
    }
    categoryRef.current = cats;
  };

  const handleClearSelection = (groupCategory: Category) => {
    let cats = { ...categoryRef.current };
    const selectedParentKeys = Object.keys(cats).filter((a) =>
      groupCategory.parentKeys.some((b) => a === b)
    );

    for (const selectedParentKey of selectedParentKeys) {
      if (inputRef.current[selectedParentKey]) {
        inputRef.current[selectedParentKey]!.checked = false;
      }
      delete cats[selectedParentKey];
    }
    if (inputRef.current[groupCategory.key]) {
      inputRef.current[groupCategory.key]!.checked = false;
    }
    if (categoryCountRef.current[groupCategory.key]) {
      categoryCountRef.current[groupCategory.key]!.innerText =
        getCategoryCountDescription(groupCategory, 0);
    }
    delete cats[groupCategory.key];

    for (const groupChild of groupCategory.children) {
      if (inputRef.current[groupChild.key]) {
        inputRef.current[groupChild.key]!.checked = false;
      }
      if (categoryCountRef.current[groupChild.key]) {
        categoryCountRef.current[groupChild.key]!.innerText =
          getCategoryCountDescription(groupChild, 0);
      }
      // Remove duplicates
      if (hasDuplicates(groupChild.displayName, duplicates)) {
        cats = deSelectDuplicates(
          groupChild.displayName,
          groupChild.key,
          cats,
          duplicates
        );
      }

      delete cats[groupChild.key];
      for (const gGroupChild of groupChild.children) {
        if (inputRef.current[gGroupChild.key]) {
          inputRef.current[gGroupChild.key]!.checked = false;
        }
        delete cats[gGroupChild.key];
        // Remove duplicates
        if (hasDuplicates(gGroupChild.displayName, duplicates)) {
          cats = deSelectDuplicates(
            gGroupChild.displayName,
            gGroupChild.key,
            cats,
            duplicates
          );
        }
      }
    }

    if (selectedParentKeys.length > 0) {
      for (const sibling of groupCategory.siblingKeys) {
        cats[sibling] = {
          key: sibling,
        };
      }
    }

    categoryRef.current = cats;
  };

  const selectedKeyword = (kw: Category) => {
    const kwSelected = categoryRef.current[kw.key] !== undefined;
    const currentCategoryKeys = Object.keys(categoryRef.current);
    const ancestorSelected = currentCategoryKeys.filter((a) =>
      kw.parentKeys.some((b) => a === b)
    );

    return kwSelected || ancestorSelected.length > 0;
  };

  const handleKeywordSelection = (
    kw: Category,
    target: HTMLInputElement,
    parent: Category
  ) => {
    let cats = { ...categoryRef.current };
    const shouldSelect = target.checked;
    let selectedCount = selectCountRef.current[parent.key];

    const ancestorSelected = Object.keys(categoryRef.current).filter((a) =>
      kw.parentKeys.some((b) => a === b)
    );

    if (ancestorSelected.length > 0 && !shouldSelect) {
      ancestorSelected.forEach((ancestor) => {
        delete cats[ancestor];
      });

      if (
        kw.parentKeys.length === 3 &&
        ancestorSelected[0] === kw.parentKeys[1] // Checks the clicked item is selected by super parent
      ) {
        for (const pibling of kw.piblingKeys) {
          cats[pibling] = {
            key: pibling,
          };
        }
      }

      for (const sibling of kw.siblingKeys) {
        cats[sibling] = {
          key: sibling,
        };
      }
      selectedCount = selectedCount - 1;
      // Remove duplicates
      if (hasDuplicates(kw.displayName, duplicates)) {
        cats = deSelectDuplicates(kw.displayName, kw.key, cats, duplicates);
      }
    } else {
      if (shouldSelect) {
        cats[kw.key] = {
          key: kw.key,
        };
        // Add duplicates
        if (hasDuplicates(kw.displayName, duplicates)) {
          cats = selectDuplicates(
            kw.displayName,
            kw.key,
            cats,
            duplicates,
            selectedCategories
          );
        }
        selectedCount = selectedCount + 1;
      } else {
        // Remove duplicates
        if (hasDuplicates(kw.displayName, duplicates)) {
          cats = deSelectDuplicates(kw.displayName, kw.key, cats, duplicates);
        }
        delete cats[kw.key];
        selectedCount = selectedCount - 1;
      }
    }

    categoryRef.current = cats;
    categoryCountRef.current[parent.key]!.innerText =
      getCategoryCountDescription(parent, selectedCount);
  };

  const doneCancelButtons = () => {
    return (
      <Grid container columns={2}>
        <Grid xs={1}>
          <Button
            color={"secondary"}
            variant="contained"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Grid>
        <Grid xs={1}>
          <Button
            sx={{ float: "right", marginRight: "10px" }}
            color={"primary"}
            variant="contained"
            onClick={handleDone}
            disabled={!isProUser(user)}
          >
            Done
          </Button>
        </Grid>
      </Grid>
    );
  };

  const checkboxText = (category: Category, parentCategory: Category) => {
    return `${keywordCounts[parentCategory.key]?.[category.key] ?? "-"} ${
      category.displayName
    }`;
  };

  const multiSelectButtons = (category: Category) => {
    return (
      <React.Fragment>
        <Button
          sx={{
            marginRight: "10px",
            float: "right",
          }}
          color={"secondary"}
          variant="contained"
          onClick={() => {
            handleClearSelection(category);
          }}
          disabled={!isProUser(user)}
          disableRipple={true}
        >
          Clear Selection
        </Button>
        <Button
          sx={{
            marginRight: "10px",
            float: "right",
          }}
          color={"primary"}
          variant="contained"
          onClick={() => {
            handleMultiSelect(category);
          }}
          disabled={!isProUser(user)}
          disableRipple={true}
        >
          Select All
        </Button>
      </React.Fragment>
    );
  };

  const getDisplay = () => {
    if (keywords[0].children.length > 0) {
      return (
        <React.Fragment>
          <ProWarningHeader />
          <Box
            sx={{
              paddingLeft: "10px",
              paddingRight: "10px",
              marginTop: "16px",
            }}
          >
            <Grid container columns={2}>
              <Grid xs={1}>
                <Typography variant={"h4"}>{categoryName}</Typography>
              </Grid>
              <Grid xs={1}>{multiSelectButtons(category)}</Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              background: "#FFFFFF",
              padding: "1px 20px 10px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            {keywords.map((keyword) => grandchildrenDisplay(keyword))}
          </Box>
          <Box
            sx={{
              padding: "10px",
              position: "sticky",
              bottom: "0px",
              bgcolor: "#DDDDDD",
            }}
          >
            {doneCancelButtons()}
          </Box>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <ProWarningHeader />
          <Box
            sx={{
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "15px",
            }}
          >
            <Grid container columns={2}>
              <Grid xs={1}>
                <Typography
                  variant="h4"
                  ref={(element) =>
                    (categoryCountRef.current[category.key] = element)
                  }
                >
                  {`${categoryName} - (${selectedKeywordCount(keywords)}/${
                    keywords.length
                  })`}
                </Typography>
              </Grid>
              <Grid xs={1}>{multiSelectButtons(category)}</Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              background: "#FFFFFF",
              padding: "10px 20px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Grid container columns={3}>
              {keywords.map((keyword) => keywordDisplay(keyword, category))}
            </Grid>
          </Box>
          <Box
            sx={{
              padding: "10px",
              position: "sticky",
              bottom: "0px",
              bgcolor: "#DDDDDD",
            }}
          >
            {doneCancelButtons()}
          </Box>
        </React.Fragment>
      );
    }
  };

  const grandchildrenDisplay = (keyword: Category) => {
    return (
      <Box
        key={`parent-keyword-${categoryName}-${keyword.displayName}`}
        sx={{
          background: "#FFFFFF",
          padding: "1px 20px 10px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Grid
          container
          columns={2}
          sx={{
            padding: "10px",
            border: "5px solid #DDDDDD",
            borderRadius: "20px 20px 0px 0px",
          }}
        >
          <Grid xs={1}>
            <Typography
              variant={"body1"}
              ref={(element) =>
                (categoryCountRef.current[keyword.key] = element)
              }
            >
              {`${checkboxText(keyword, category)} - (${selectedKeywordCount(
                keyword.children
              )}/${keyword.children.length})`}
            </Typography>
          </Grid>
          <Grid xs={1}>{multiSelectButtons(keyword)}</Grid>
        </Grid>

        <Grid
          container
          columns={3}
          sx={{
            border: "5px solid #DDDDDD",
            borderRadius: "0px 0px 20px 20px",
          }}
        >
          {keyword.children.map((kChild) => {
            return (
              <Grid key={`child-keyword-${kChild.displayName}`} xs={1}>
                <Box
                  sx={{
                    borderBottom: "1px solid #000000",
                    padding: "8px 0px",
                    marginLeft: "15px",
                    marginRight: "15px",
                    marginBottom: "5px",
                    lineHeight: "30px",
                  }}
                >
                  <label
                    htmlFor={kChild.key}
                    data-testid={`${kChild.displayName}-checkbox`}
                    style={keywordLabel}
                  >
                    <input
                      type="checkbox"
                      disabled={!isProUser(user)}
                      id={kChild.key}
                      onChange={(event: React.ChangeEvent) =>
                        handleKeywordSelection(
                          kChild,
                          event.target as HTMLInputElement,
                          keyword
                        )
                      }
                      defaultChecked={selectedKeyword(kChild)}
                      ref={(element) =>
                        (inputRef.current[kChild.key] = element)
                      }
                      style={keywordCheckbox}
                    />
                    {checkboxText(kChild, category)}
                  </label>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  const keywordDisplay = (keyword: Category, parent: Category) => {
    return (
      <Grid key={`${keyword.displayName}-wrapper`} xs={1}>
        <Box
          key={`child-keyword-${keyword.displayName}`}
          sx={{
            borderBottom: "1px solid #000000",
            padding: "8px 0px",
            marginLeft: "15px",
            lineHeight: "30px",
          }}
        >
          <label
            htmlFor={keyword.key}
            data-testid={`${keyword.displayName}-checkbox`}
            style={keywordLabel}
          >
            <input
              type="checkbox"
              id={keyword.key}
              disabled={!isProUser(user)}
              defaultChecked={selectedKeyword(keyword)}
              onChange={(event: React.ChangeEvent) =>
                handleKeywordSelection(
                  keyword,
                  event.target as HTMLInputElement,
                  parent
                )
              }
              ref={(element) => (inputRef.current[keyword.key] = element)}
              style={keywordCheckbox}
            />
            {checkboxText(keyword, category)}
          </label>
        </Box>
      </Grid>
    );
  };

  return getDisplay();
}
