import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { primaryPalette } from "../../../../styles/theme";
import ArticleCard from "../../../common/cards/article-card/ArticleCard";
import { ArticleCardBody } from "../../../common/cards/article-card/ArticleCardBody/ArticleCardBody";
import { ClinicalEvidenceIcon } from "../../../common/icons/ClinicalEvidenceIcon";
import { FunctionalEvidenceIcon } from "../../../common/icons/FunctionalEvidenceIcon";
import ArticleCardFooter from "./ArticleCardFooter/ArticleCardFooter";
import { acmgRecords } from "../../article-gene-variant-card/VariantCard/variant-card-data";

const mockArticle = {
  gene_summaries: {
    brca1: {
      count: 1,
      mutation_count: 1,
      mutation_mention_count: 1,
    },
  },
  title: "Mock Article Title",
  publication_date: "2022-01-01",
  pmid: "12345678",
  authors: ["John Doe"],
  journal: "Mock Journal",
  mutation_counts: {},
  diseases: [],
  abstract: [],
  gene_mention_count: 0,
  mutation_mention_count: 0,
};

const mockACMGRecords = acmgRecords;

export default function EvidenceArticleListBody() {
  return (
    <Box
      sx={{
        padding: "15px",
        backgroundColor: primaryPalette.brown.neutral_brown_b2,
        borderRadius: "0 0 16px 16px",
      }}
    >
      <Stack direction={"row"} justifyContent="space-evenly" spacing={2}>
        <Box sx={{ width: "100%" }}>
          <Stack alignItems="center" direction="row" gap={1}>
            <ClinicalEvidenceIcon width="14px" height="14px" />
            <Typography variant="h4">Clinical</Typography>
          </Stack>
          <Divider />
          <ArticleCard
            onCardClick={() => {
              return;
            }}
            selected={false}
            article={mockArticle}
          >
            <ArticleCard.Body>
              <ArticleCardBody article={mockArticle} showClinVarBadge={true} />
            </ArticleCard.Body>
          </ArticleCard>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Stack alignItems="center" direction="row" gap={1}>
            <FunctionalEvidenceIcon width="14px" height="14px" />
            <Typography variant="h4">Functional</Typography>
          </Stack>
          <Divider />
          <ArticleCard
            onCardClick={() => {
              return;
            }}
            selected={false}
            article={mockArticle}
          >
            <ArticleCard.Body>
              <ArticleCardBody article={mockArticle} showClinVarBadge={true} />
            </ArticleCard.Body>
          </ArticleCard>
          <ArticleCard
            onCardClick={() => {
              return;
            }}
            selected={false}
            article={mockArticle}
          >
            <ArticleCard.Body>
              <ArticleCardBody article={mockArticle} showClinVarBadge={true} />
            </ArticleCard.Body>
            <ArticleCard.Footer>
              <ArticleCardFooter acmgRecords={mockACMGRecords} />
            </ArticleCard.Footer>
          </ArticleCard>
        </Box>
      </Stack>
    </Box>
  );
}
