import { Box } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Article } from "../../../../network/articles";
import ArticleListCard from "../ArticleListCard/ArticleListCard";
import { useSearchParams } from "react-router-dom";
import { ArticleSkeletonLoader } from "../ArticleSkeletonLoader/ArticleSkeletonLoader";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import ScrollerRef from "../../../common/scrollbar/ScrollerRef";
import { useAppSelector } from "../../../../store/hooks";
import { elementHeights } from "../../../../styles/theme";
import { getArticleListItems } from "../../../../store/selectors/articleListSelectors";
import { UrlParams } from "../../../../types/url-params";

interface ArticleListProps {
  articleHeaderHeight: number;
  isScrolled: boolean;
  handleArticleChange: (pmid: string) => void;
  isFetchingArticleList: boolean;
  handleLoadMore: () => void;
}

export default function ArticleList({
  articleHeaderHeight,
  isScrolled,
  handleArticleChange,
  isFetchingArticleList,
  handleLoadMore,
}: ArticleListProps) {
  const [searchParams] = useSearchParams();
  const clinVarPmids = useAppSelector(
    (state) => state.articles.clinVarArticles?.pmids
  );
  const articles = useAppSelector((state) => getArticleListItems(state));
  const vArticles = useRef<VirtuosoHandle>(null);
  const [visibleRange, setVisibleRange] = useState({
    startIndex: 0,
    endIndex: 0,
  });
  const currPMID = searchParams.get(UrlParams.PMID) ?? "";
  const prevCurrPMID = useRef(currPMID);
  const prevArticleLength = useRef(articles.length);
  const listRef = useRef<HTMLElement | null>(null);
  const [currentItemIndex, setCurrentItemIndex] = useState(-1);

  const findArticleIndex = useCallback(() => {
    const pmid = (a: Article) => a.pmid === searchParams.get("pmid");
    const index = articles.findIndex(pmid);
    if (index >= 0) return index;
    return 0;
  }, [searchParams, articles]);

  useEffect(() => {
    setCurrentItemIndex(findArticleIndex());
  }, [searchParams, setCurrentItemIndex, findArticleIndex]);

  useEffect(() => {
    // If the previous article length was 0, we know we were loading the first iteration of articles
    // and thus should scroll to the index
    // If the previous currPMID is the same as currPMID, we know we are simply adding more articles
    // to our list of articles (we hit the bottom of our virtual scroll list)
    if (prevCurrPMID.current === currPMID && prevArticleLength.current !== 0) {
      prevArticleLength.current = articles.length;
      return;
    }

    prevCurrPMID.current = currPMID;
    prevArticleLength.current = articles.length;
    const pmid = (a: Article) => a.pmid == currPMID;
    const selectedArticle = articles.findIndex(pmid);
    if (
      vArticles.current !== null &&
      selectedArticle >= 0 &&
      (selectedArticle > visibleRange.endIndex ||
        selectedArticle < visibleRange.startIndex)
    ) {
      vArticles.current.scrollToIndex({
        index: selectedArticle,
        align: "start",
        behavior: "auto",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currPMID, articles]);

  const keyDownCallback = useCallback(
    (e: KeyboardEvent) => {
      let nextIndex = null;

      if (e.code !== "ArrowUp" && e.code !== "ArrowDown") return;

      if (e.code === "ArrowUp") {
        nextIndex = currentItemIndex - 1;
      } else if (e.code === "ArrowDown") {
        nextIndex = currentItemIndex + 1;
      }

      if (nextIndex !== null && !!articles[nextIndex]?.pmid) {
        vArticles.current?.scrollIntoView({
          index: nextIndex,
          behavior: "auto",
          done: () => {
            setCurrentItemIndex(nextIndex);
            handleArticleChange(articles[nextIndex].pmid);
          },
        });
        e.preventDefault();
      }
    },
    [
      currentItemIndex,
      vArticles,
      articles,
      setCurrentItemIndex,
      handleArticleChange,
    ]
  );

  const scrollerRef = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (ref: any) => {
      if (ref) {
        // eslint-disable-next-line
        ref.addEventListener("keydown", keyDownCallback);
        // eslint-disable-next-line
        listRef.current = ref;
      } else {
        listRef.current?.removeEventListener("keydown", keyDownCallback);
      }
    },
    [keyDownCallback]
  );

  const displayArticle = (article: Article) => {
    return (
      <ArticleListCard
        key={article.pmid}
        showClinVarBadge={!!clinVarPmids[article.pmid]}
        selected={currPMID === article.pmid}
        article={article}
        handleArticleChange={() => handleArticleChange(article.pmid)}
      />
    );
  };

  return (
    <Box id="article-list-scroll" data-testid="article-list-scroll">
      <Virtuoso
        ref={vArticles}
        style={{
          marginBottom: "8px",
          height: `calc(100vh - ${elementHeights.fixedHeaderContent} - ${articleHeaderHeight}px - 16px)`,
          overflowY: isScrolled ? "scroll" : "hidden",
          scrollbarGutter: "stable",
        }}
        data={articles}
        initialTopMostItemIndex={findArticleIndex()}
        scrollerRef={scrollerRef}
        itemContent={(_index, article) => {
          return article.title ? displayArticle(article) : null;
        }}
        rangeChanged={setVisibleRange}
        components={{
          Scroller: ScrollerRef,
          Footer: () => {
            if (isFetchingArticleList) {
              return [1, 2, 3, 4, 5].map((i) => (
                <ArticleSkeletonLoader key={i} animation={"wave"} />
              ));
            }
            return <></>;
          },
        }}
        endReached={handleLoadMore}
      />
    </Box>
  );
}
