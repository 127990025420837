import React from "react";
import ArticleViewerErrorState from "../ArticleViewerErrorState";
import ArticleViewerLoadingState from "../ArticleViewerLoadingState";
import NotFoundContent from "../../not-found-content/NotFoundContent";
import ArticleViewer from "../ArticleViewer";
import { Stack } from "@mui/material";
import { getProSignUpLink } from "../../../../utils/links";
import ComponentLevelPaywall from "../../../gene/common/ComponentLevelPaywall";
import { useAppSelector } from "../../../../store/hooks";
import { shallowEqual } from "react-redux";

type ArticleViewerWrapperProps = {
  errorState: boolean;
  isLoadingArticleViewer: boolean;
  isEmptyArticleViewer: boolean;
};

const ArticleViewerWrapper = ({
  errorState,
  isLoadingArticleViewer,
  isEmptyArticleViewer,
}: ArticleViewerWrapperProps) => {
  const { isLoggedIn } = useAppSelector((state) => state.user, shallowEqual);
  const { user } = useAppSelector((state) => state.user);

  if (!isLoggedIn) {
    return (
      <Stack spacing={2}>
        <ComponentLevelPaywall
          titleText={"basic edition preview"}
          bodyText={"Take a look inside for full article details."}
          signUpLink={getProSignUpLink(user)}
          showLogIn={true}
          id="article-viewer-anon-paywall"
          style={{
            borderRadius: "6px",
          }}
        />
        <ArticleViewerLoadingState
          animation={false}
          testId="article-anonymous-pane"
        />
      </Stack>
    );
  }

  if (errorState) {
    return <ArticleViewerErrorState />;
  }

  if (isLoadingArticleViewer) {
    return (
      <ArticleViewerLoadingState
        animation="pulse"
        testId="article-viewer-loader"
      />
    );
  }

  if (isEmptyArticleViewer) {
    return <NotFoundContent />;
  }

  return <ArticleViewer />;
};

export default ArticleViewerWrapper;
