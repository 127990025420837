"use client";

import * as React from "react";
import { styled } from "@mui/material/styles";

import { CategoriesProps } from "../../../../../types/card";
import { Grid } from "@mui/material";
import { useGetAcmgClassesQuery } from "../../../../../network/acmg-descriptions";
import { getBackgroundColor } from "../../../../../utils/acmg";
import ACMGChip from "../../../../common/chips/ACMGChip";

const CustomGrid = styled(Grid)({
  padding: "0 0.625rem",
});

const Categories: React.FC<CategoriesProps> = ({ acmgRecords }) => {
  const { data: acmgDescriptionData } = useGetAcmgClassesQuery(undefined);

  if (Object.keys(acmgRecords).length && acmgDescriptionData) {
    return (
      <CustomGrid container columnSpacing={0.5} rowSpacing={0}>
        {Object.keys(acmgRecords).map((text: string) => {
          const bgcolor = getBackgroundColor(
            acmgDescriptionData[text.toUpperCase()]
          );
          return (
            <Grid className="acmg-chip" item key={text}>
              <ACMGChip bgcolor={bgcolor} category={text} />
            </Grid>
          );
        })}
      </CustomGrid>
    );
  }
  return <></>;
};

export default Categories;
