import { httpClient } from "../network/http-client";
import { AxiosResponse } from "axios";

const logError = async (e: Error) => {
  const data = {
    frontend_error_body: {
      name: e.name,
      message: e.message,
      stack: e.stack,
    },
  };
  await httpClient.post<any, AxiosResponse, any>("frontend_error", data);
};

export default logError;
