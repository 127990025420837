import {
  TableRow,
  TableCell,
  Collapse,
  Table,
  TableHead,
  TableBody,
  Typography,
  TableContainer,
} from "@mui/material";
import React from "react";
import { VariantResult } from "../../../../models/variant-result";
import { TranscriptPosition } from "../../../../network/variants/types";
import { primaryPalette } from "../../../../styles/theme";
import { useAppSelector } from "../../../../store/hooks";
import ComponentLevelPaywall from "../../common/ComponentLevelPaywall";
import { getProSignUpLink } from "../../../../utils/links";
import { TableSkeletonLoader } from "../../common/skeleton-loaders/TableSkeletonLoader";

export type GenomicPositionProps = {
  open: boolean;
  genomicPosData: TranscriptPosition[] | undefined;
  variantResult: VariantResult;
};

export function GenomicPosition({
  open,
  genomicPosData,
  variantResult,
}: GenomicPositionProps) {
  const { isLoggedIn, user } = useAppSelector((state) => state.user);
  return (
    <>
      <TableRow data-testid={`genomic-position-${variantResult.getId()}`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{
              backgroundColor: primaryPalette.gray.variant_01,
            }}
            data-testid={`collapse-${variantResult.getId()}`}
          >
            <TableContainer sx={{ margin: 1, maxHeight: 350 }}>
              <Typography variant="body2" data-testid="aa-position">
                AA Position: {variantResult.position}
              </Typography>
              <Typography variant="body2" data-testid="cdna-position">
                cDNA Positions: {variantResult.cdnaPosition}
              </Typography>
              <Table
                size="small"
                aria-label="transcript position"
                data-testid="transcript-position-table"
                stickyHeader
                sx={{ width: "98%" }}
              >
                <TableHead
                  sx={{
                    background: primaryPalette.teal.neutral_teal_t1,
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.06)",
                  }}
                >
                  <TableRow>
                    <TableCell>Assembly</TableCell>
                    <TableCell>Transcripts</TableCell>
                    <TableCell>Chromosome</TableCell>
                    <TableCell>Genomic Positions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!genomicPosData && (
                    <TableSkeletonLoader rowsNum={3} cellNum={4} />
                  )}
                  {!isLoggedIn && (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <ComponentLevelPaywall
                          titleText={"basic edition preview"}
                          bodyText={
                            "Take a look inside for detailed transcript data"
                          }
                          signUpLink={getProSignUpLink(user)}
                          showLogIn={true}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  {isLoggedIn && genomicPosData ? (
                    genomicPosData.map((tp, index) => {
                      return (
                        <TableRow
                          key={`${tp.assembly}-${index}`}
                          sx={{
                            "&:nth-of-type(even)": {
                              backgroundColor: primaryPalette.brown.variant_01,
                            },
                          }}
                          data-testid={`genomic-position-transcript-row`}
                        >
                          <TableCell>{tp.assembly}</TableCell>
                          <TableCell>{tp.tx_id}</TableCell>
                          <TableCell>{`${tp.chrom} (${tp.chrom_seq})`}</TableCell>
                          <TableCell>{`${tp.g_start}${tp.strand}${tp.g_end}`}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
