import * as React from "react";
import { Chip } from "@mui/material";
import { primaryPalette } from "../../../../styles/theme";

interface ACMGChipProps {
  category: string;
  bgcolor: string;
}

const getChipLabel = (text: string) => {
  const splitText = text.split("_");
  const formattedText = splitText.map((t, i) => {
    if (i === 0) {
      return t.toUpperCase();
    }
    let capitalized = t.toLowerCase();
    capitalized = capitalized[0].toUpperCase() + capitalized.slice(1);
    return capitalized;
  });
  return formattedText.join(" ");
};

const ACMGChip = ({ category, bgcolor }: ACMGChipProps) => {
  const label = getChipLabel(category);

  return (
    <Chip
      data-testid={`acmg-chip-${category}`}
      label={label}
      sx={{
        height: "1.0625rem",
        borderRadius: "0.125rem",
        fontSize: "0.675rem",
        color: primaryPalette.gray.variant_01,
        backgroundColor: primaryPalette.gray.variant_06,
        "& .MuiChip-label": {
          padding: "0 0.375rem",
        },
        bgcolor: bgcolor,
      }}
    />
  );
};

export default ACMGChip;
