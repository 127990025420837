import { useHandleError } from "../hooks/errorHandlerHook";
import { getSplitIOFeatures } from "../network/features";
import { useAppDispatch } from "../store/hooks";
import { loadSplitIOFeatures } from "../store/slices/featureSlice";

export async function splitFeatureFlags(
  dispatch: ReturnType<typeof useAppDispatch>,
  handleError: ReturnType<typeof useHandleError>
) {
  try {
    const featuresResp = await getSplitIOFeatures();
    dispatch(loadSplitIOFeatures(featuresResp));
  } catch (error) {
    handleError(error);
  }
}
