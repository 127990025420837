import React from "react";
import { BooleanSearchOptions } from "../../../../network/suggestions";
import { primaryPalette } from "../../../../styles/theme";
import { Typography } from "@mui/material";
import { FlexColumnDiv, FlexRowDiv } from "../../../common/Flex";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import SearchBumpContents, { SearchBump } from "../SearchBump/SearchBump";
import {
  deleteCategory,
  deleteSignificantTerm,
  SearchBarKeywordTerms,
} from "../../../../store/slices/searchSlice";

import { shouldRenderSearchBump } from "../../../common/helpers/KeywordHelper";

const KeywordSearchBumps = () => {
  const dispatch = useAppDispatch();
  const { cats, sig_terms } = useAppSelector((state) => state.search);
  const { duplicates } = useAppSelector((state) => state.categoryLayout);
  const boolConnector = BooleanSearchOptions.OR;
  const catsLength = Object.keys(cats).length;
  const sigTermsLength = Object.keys(sig_terms).length;

  return (
    <FlexColumnDiv style={{ alignItems: "unset" }}>
      <Typography
        variant="text12"
        style={{
          color: primaryPalette.teal.neutral_teal_t2,
          paddingBottom: "5px",
          lineHeight: "12px",
          letterSpacing: "0.24px",
        }}
      >
        Keyword
      </Typography>
      <SearchBump>
        <FlexRowDiv style={{ gap: "5px", height: "100%" }}>
          {Object.values(cats).map((term, idx) => {
            const onClear = () => dispatch(deleteCategory(term));
            const isLast = catsLength + sigTermsLength !== idx + 1;
            return (
              shouldRenderSearchBump(term.key, cats, duplicates) && (
                <SearchBumpContents
                  key={`search-bump-${SearchBarKeywordTerms.cats}-${term.key}`}
                  type={SearchBarKeywordTerms.cats}
                  text={term.key}
                  id={term.key}
                  isLast={isLast}
                  boolConnector={boolConnector}
                  onClear={onClear}
                />
              )
            );
          })}
          {Object.values(sig_terms).map((term, idx) => {
            const onClear = () => dispatch(deleteSignificantTerm(term));
            const isLast = catsLength + sigTermsLength !== idx + catsLength + 1;
            return (
              <SearchBumpContents
                key={`search-bump-${SearchBarKeywordTerms.sig_terms}-${term.key}`}
                type={SearchBarKeywordTerms.sig_terms}
                text={term.key}
                id={term.key}
                isLast={isLast}
                boolConnector={boolConnector}
                onClear={onClear}
              />
            );
          })}
        </FlexRowDiv>
      </SearchBump>
    </FlexColumnDiv>
  );
};

export default KeywordSearchBumps;
