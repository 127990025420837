import {
  BooleanSearch,
  initialSearchState,
  SearchBarTerms,
  SearchedTermIds,
} from "../../store/slices/searchSlice";
import { VariantResult } from "../../models/variant-result";
import { api } from "../api";
import { CurationRecordVariant } from "../../types/articles";
import { isGenomicReference, isRSID } from "../../utils/strings/regex";
import { RootState } from "../../store/store";
import { getGeneFromVariantSuggestions } from "../../utils/variant";
import {
  MergedRelatedVariant,
  RelatedVariantArguments,
  VariantResponse,
  RelatedVariant,
  ReporterResponse,
  ClinVarVariantsResponse,
  CuratedVariantsResponse,
  URLSearchParamsReq,
  GenomicPositionResponse,
  GetCuratedClinVarVariantContent,
  GetCuratedClinVarVariantContentParams,
  GetRibbonDataResponse,
  GetReporterCuratedData,
  GetReporterClinvarData,
} from "./types";

const getReporterURL = (gene: string[], variants: string[]) => {
  const casedGenes = gene.map((g) => VariantResult.geneCasing(g));
  const variantString = `variant[]=${variants.join("&variant[]=")}`;
  const geneString = `gene[]=${casedGenes.join("&gene[]=")}`;
  return `/reporter/related?${[variantString, geneString].join("&")}`;
};

const generateMutationBody = (
  urlSearchParams: SearchedTermIds,
  urlBooleanParams: BooleanSearch,
  urlCats: string[],
  urlSigTerms: string[],
  addlOptions: { gene: string[]; mutation: string[] } = {
    gene: [],
    mutation: [],
  }
) => {
  const gene =
    addlOptions.gene.length !== 0
      ? addlOptions.gene
      : urlSearchParams[SearchBarTerms.gene];
  const mutation =
    addlOptions.mutation.length !== 0
      ? addlOptions.mutation
      : urlSearchParams[SearchBarTerms.variant];
  return JSON.stringify({
    boolean: true,
    article_sort: "relevance",
    article_sort_asc: false,
    mutation_source: "supplemental",
    cnv_match_op: "intersects",
    article_list_filter: "",
    offset: 0,
    sig_terms: urlSigTerms,
    cats: urlCats,
    gene: gene,
    mutation: mutation,
    disease: urlSearchParams[SearchBarTerms.disease],
    keyword: urlSearchParams[SearchBarTerms.keyword],
    hpo: urlSearchParams[SearchBarTerms.hpo],
    unii: urlSearchParams[SearchBarTerms.unii],
    cnv: urlSearchParams[SearchBarTerms.cnv],
    gene_op:
      urlBooleanParams.gene ?? initialSearchState.booleans[SearchBarTerms.gene],
    disease_op:
      urlBooleanParams.disease ??
      initialSearchState.booleans[SearchBarTerms.disease],
    mutation_op:
      urlBooleanParams.variant ??
      initialSearchState.booleans[SearchBarTerms.variant],
    keyword_op:
      urlBooleanParams.keyword ??
      initialSearchState.booleans[SearchBarTerms.keyword],
    hpo_op:
      urlBooleanParams.hpo ?? initialSearchState.booleans[SearchBarTerms.hpo],
    unii_op:
      urlBooleanParams.unii ?? initialSearchState.booleans[SearchBarTerms.unii],
    cnv_op:
      urlBooleanParams.cnv ?? initialSearchState.booleans[SearchBarTerms.cnv],
    origin: "bookmark",
  });
};

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRelatedVariants: builder.query<
      Array<MergedRelatedVariant>,
      RelatedVariantArguments
    >({
      // TODO Related Variants Follow Up: Duplicate endpoints for reporter/related; let's consolidate
      async queryFn(_args, _queryApi, _extraOptions, fetchWithBaseUrl) {
        // Fetch the variants via /mutations
        const mutationArgs = generateMutationBody(
          _args.urlTermIds,
          _args.urlBooleanParams,
          _args.urlCats,
          _args.urlSigTerms,
          {
            gene: _args.gene,
            mutation: _args.variant,
          }
        );
        const mutResponse = await fetchWithBaseUrl({
          url: "/mutations",
          method: "POST",
          body: mutationArgs,
        });

        if (mutResponse.error) {
          return { error: mutResponse.error };
        }

        const mutData = mutResponse.data as VariantResponse;
        const variants = mutData.variants;

        // Extract the relatedVariants from the /mutations response
        let mutRelatedVariants: RelatedVariant[] = [];
        if (
          mutData.relatedVariants &&
          Object.keys(mutData.relatedVariants).length > 0
        ) {
          Object.keys(mutData.relatedVariants).forEach((key) => {
            if (mutData.relatedVariants?.[key]) {
              mutRelatedVariants = mutRelatedVariants.concat(
                mutData.relatedVariants[key]
              );
            }
          });
        }

        // combine, sort, and filter out duplicate relatedVariants
        const combinedVariants = mutRelatedVariants
          .sort((a: RelatedVariant, b: RelatedVariant) => a.Score - b.Score)
          .reduce(
            (uniqueVariants: MergedRelatedVariant[], v: RelatedVariant) => {
              const existingVariant = uniqueVariants.find(
                (variant) => variant.variant === v.Variant
              );
              if (!existingVariant) {
                const otherVariantInfo = variants.find(
                  (variant) => variant.name === v.Variant
                );
                const gene = VariantResult.getGene(v.Variant);
                uniqueVariants.push({
                  variant: v.Variant,
                  gene: gene,
                  score: v.Score,
                  totalHits: otherVariantInfo?.total_hits ?? 0,
                  acmgCall: "",
                  type: otherVariantInfo?.type ?? "",
                });
              }
              return uniqueVariants;
            },
            []
          )
          .slice(0, 15);
        if (combinedVariants.length === 0) {
          return { data: [] };
        }
        // Fetch the reporter data
        const combinedVariantGenes = combinedVariants.map((v) => v.gene);
        const geneParam =
          _args.gene.length > 0 ? _args.gene : combinedVariantGenes;
        const repResponse = await fetchWithBaseUrl({
          url: getReporterURL(
            geneParam,
            combinedVariants.map((v) => v.variant)
          ),
          method: "GET",
        });

        if (repResponse.error) {
          return { error: repResponse.error };
        }

        const reporterData = repResponse.data as ReporterResponse;
        const reporterVariants = reporterData.curationRecords.variants;

        // Merge the reporter data with the combinedVariants
        const results: Array<MergedRelatedVariant> = [];
        combinedVariants.forEach((rv) => {
          const reporterVariant = reporterVariants.find(
            (v) => v.id === rv.variant
          );
          if (reporterVariant) {
            results.push({
              ...rv,
              acmgCall: reporterVariant.acmg_call,
            });
          } else {
            results.push({
              ...rv,
            });
          }
        });

        return { data: results };
      },
    }),
    getGeneVariantClinvarData: builder.query<ClinVarVariantsResponse, string>({
      query: (geneSymbol) => ({
        url: `/variants_with_clinvar/${geneSymbol}`,
        method: "GET",
      }),
    }),
    getGeneCuratedData: builder.query<CuratedVariantsResponse, string>({
      query: (geneSymbol) => ({
        url: `/curated_variants/${geneSymbol}`,
        method: "GET",
      }),
    }),
    getVariants: builder.query<VariantResponse, URLSearchParamsReq>({
      query: (args) => ({
        url: "/mutations",
        method: "POST",
        body: generateMutationBody(
          args.urlTermIds,
          args.urlBooleanParams,
          args.urlCats,
          args.urlSigTerms
        ),
      }),
      transformResponse: (response: VariantResponse) => {
        let relatedVariantResults = null;
        if (response.relatedVariants) {
          relatedVariantResults = response.relatedVariants;
        }
        if (!response.variants) {
          return {
            variants: [],
            relatedVariants: relatedVariantResults,
          };
        }
        const variantResults = response.variants.map((v) => {
          const nameParts = v.name.split(":");
          const geneName = nameParts[0];
          const mutation = nameParts[1];

          return {
            ...v,
            gene: geneName,
            mutation: mutation,
          };
        });
        return {
          variants: variantResults,
          relatedVariants: relatedVariantResults,
        };
      },
    }),
    getGenomicPosition: builder.query<GenomicPositionResponse, VariantResult>({
      query: (variant) => {
        const params = new URLSearchParams([["variant", variant.getName()]]);
        return {
          url: `/genomic_position?${params.toString()}`,
          method: "GET",
        };
      },
    }),
    getCuratedClinVarVariantContent: builder.query<
      GetCuratedClinVarVariantContent,
      GetCuratedClinVarVariantContentParams
    >({
      async queryFn(params, queryApi, _extraOptions, fetchWithBaseUrl) {
        const variants = [...params.urlTermIds[SearchBarTerms.variant]];
        const genes = [...params.urlTermIds[SearchBarTerms.gene]];
        if (isGenomicReference(variants) || isRSID(variants)) {
          const reduxVariants = (queryApi.getState() as RootState).search
            .variant;
          for (let i = 0; i < variants.length; i++) {
            const v = variants[i];
            if (isGenomicReference([v]) || isRSID([v])) {
              const suggGene = getGeneFromVariantSuggestions(v, reduxVariants);
              if (suggGene && !genes.includes(suggGene)) {
                genes.push(suggGene);
              }
            }
          }
        }
        const casedGenes = genes.map((g) => VariantResult.geneCasing(g));
        const encodedVariants = variants.map((v) => encodeURIComponent(v));
        const variantString = `variant[]=${encodedVariants.join(
          "&variant[]="
        )}`;
        const geneString = `gene[]=${casedGenes.join("&gene[]=")}`;
        const result = await fetchWithBaseUrl({
          url: `/reporter/related?${[variantString, geneString].join("&")}`,
          method: "GET",
        });
        if (result.error) {
          return { error: result.error };
        }
        const castedResult = result.data as GetCuratedClinVarVariantContent;
        return {
          data: {
            clinVarRecords: castedResult.clinVarRecords,
            curationRecords: castedResult.curationRecords,
          },
        };
      },
    }),
    getRibbonData: builder.query<GetRibbonDataResponse, CurationRecordVariant>({
      query: (variant) => {
        return {
          url: `/reporter/ribbon/${variant.gene}/${variant.id}`,
          method: "GET",
        };
      },
    }),
    getReporterCuratedData: builder.query<
      GetReporterCuratedData,
      GetCuratedClinVarVariantContentParams
    >({
      async queryFn(params, queryApi, _extraOptions, fetchWithBaseUrl) {
        const variants = [...params.urlTermIds[SearchBarTerms.variant]];
        const genes = [...params.urlTermIds[SearchBarTerms.gene]];
        if (isGenomicReference(variants) || isRSID(variants)) {
          const reduxVariants = (queryApi.getState() as RootState).search
            .variant;
          for (let i = 0; i < variants.length; i++) {
            const v = variants[i];
            if (isGenomicReference([v]) || isRSID([v])) {
              const suggGene = getGeneFromVariantSuggestions(v, reduxVariants);
              if (suggGene && !genes.includes(suggGene)) {
                genes.push(suggGene);
              }
            }
          }
        }
        const casedGenes = genes.map((g) => VariantResult.geneCasing(g));
        const encodedVariants = variants.map((v) => encodeURIComponent(v));
        const variantString = `variant[]=${encodedVariants.join(
          "&variant[]="
        )}`;
        const geneString = `gene[]=${casedGenes.join("&gene[]=")}`;
        const result = await fetchWithBaseUrl({
          url: `/reporter/curated?${[variantString, geneString].join("&")}`,
          method: "GET",
        });
        if (result.error) {
          return { error: result.error };
        }
        const castedResult = result.data as GetReporterCuratedData;
        return {
          data: {
            curationRecords: castedResult.curationRecords,
          },
        };
      },
    }),
    getReporterClinvarData: builder.query<
      GetReporterClinvarData,
      GetCuratedClinVarVariantContentParams
    >({
      async queryFn(params, queryApi, _extraOptions, fetchWithBaseUrl) {
        const variants = [...params.urlTermIds[SearchBarTerms.variant]];
        const genes = [...params.urlTermIds[SearchBarTerms.gene]];
        if (isGenomicReference(variants) || isRSID(variants)) {
          const reduxVariants = (queryApi.getState() as RootState).search
            .variant;
          for (let i = 0; i < variants.length; i++) {
            const v = variants[i];
            if (isGenomicReference([v]) || isRSID([v])) {
              const suggGene = getGeneFromVariantSuggestions(v, reduxVariants);
              if (suggGene && !genes.includes(suggGene)) {
                genes.push(suggGene);
              }
            }
          }
        }
        const casedGenes = genes.map((g) => VariantResult.geneCasing(g));
        const encodedVariants = variants.map((v) => encodeURIComponent(v));
        const variantString = `variant[]=${encodedVariants.join(
          "&variant[]="
        )}`;
        const geneString = `gene[]=${casedGenes.join("&gene[]=")}`;
        const result = await fetchWithBaseUrl({
          url: `/reporter/clinvar?${[variantString, geneString].join("&")}`,
          method: "GET",
        });
        if (result.error) {
          return { error: result.error };
        }
        const castedResult = result.data as GetReporterClinvarData;
        return {
          data: {
            clinVarRecords: castedResult.clinVarRecords,
          },
        };
      },
    }),
  }),
});

export const {
  useGetRelatedVariantsQuery,
  useGetGeneVariantClinvarDataQuery,
  useGetGeneCuratedDataQuery,
  useGetVariantsQuery,
  useLazyGetGenomicPositionQuery,
  useLazyGetCuratedClinVarVariantContentQuery,
  useLazyGetRibbonDataQuery,
} = extendedApi;
