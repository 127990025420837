import React, { useEffect, useState } from "react";
import { CondensedArticleSentence } from "../../../../network/articles";
import ArticleSentence from "../ArticleSentence/ArticleSentence";
import {
  KeywordFormControlLabel,
  KeywordSelector,
  KeywordUncheckedIcon,
} from "../StyledViewerComponents";
import {
  Box,
  Checkbox,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CheckIcon } from "../../../common/icons/CheckIcon";
import Grid from "@mui/material/Unstable_Grid2";
import AccordionButton from "../../../common/AccordionButton";
import { primaryPalette } from "../../../../styles/theme";
import SeeMoreButton from "../../../common/buttons/SeeMoreButton";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  setViewGene,
  setViewVariant,
  setViewCnv,
  setViewKeyword,
  setViewPhenotype,
  setViewTherapy,
} from "../../../../store/slices/textMatchFilterSlice";

export default function TextMatchViewer() {
  const dispatch = useAppDispatch();
  const {
    viewGene,
    viewVariant,
    viewCnv,
    viewKeyword,
    viewPhenotype,
    viewTherapy,
  } = useAppSelector((state) => state.textMatchFilter);
  const { data: articleData } = useAppSelector(
    (state) => state.featuredArticle
  );
  const theme = useTheme();
  const isXLDesktop = useMediaQuery(theme.breakpoints.up("xl"));
  const minVisibleCount = 6;
  const [visibleItemCount, setVisibleItemCount] = useState(
    articleData?.condensedSentences?.length ?? 0
  );
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    setVisibleItemCount(articleData?.condensedSentences?.length ?? 0);
  }, [articleData?.condensedSentences]);

  const hideHighlight = (sentenceText: string) => {
    let text = sentenceText;
    if (!viewGene) {
      text = text.replaceAll("class='gene'", "class='gene no-highlight'");
    }
    if (!viewVariant) {
      text = text.replaceAll("class='variant'", "class='variant no-highlight'");
    }
    if (!viewCnv) {
      text = text.replaceAll("class='cnv'", "class='cnv no-highlight'");
    }
    if (!viewKeyword) {
      text = text.replaceAll("class='keyword'", "class='keyword no-highlight'");
    }
    if (!viewPhenotype) {
      text = text.replaceAll("class='hpo'", "class='hpo no-highlight'");
    }
    if (!viewTherapy) {
      text = text.replaceAll("class='unii'", "class='unii no-highlight'");
    }
    return text;
  };

  const adjustedSentences: CondensedArticleSentence[] = [];

  (articleData?.condensedSentences ?? []).map((condensedSentence) => {
    const text = hideHighlight(condensedSentence.content);

    adjustedSentences.push({
      content: text,
      contentMatches: condensedSentence.contentMatches,
    });
  });

  const geneSentences = adjustedSentences.filter((sentence) =>
    sentence.content.includes("class='gene")
  );
  const variantSentences = adjustedSentences.filter((sentence) =>
    sentence.content.includes("class='variant")
  );

  const cnvSentences = adjustedSentences.filter((sentence) =>
    sentence.content.includes("class='cnv")
  );
  const keywordSentences = adjustedSentences.filter((sentence) =>
    sentence.content.includes("class='keyword")
  );
  const phenotypeSentences = adjustedSentences.filter((sentence) =>
    sentence.content.includes("class='hpo")
  );
  const therapySentences = adjustedSentences.filter((sentence) =>
    sentence.content.includes("class='unii")
  );

  const allSentences = [
    { sentences: geneSentences, selected: viewGene },
    { sentences: variantSentences, selected: viewVariant },
    { sentences: cnvSentences, selected: viewCnv },
    { sentences: keywordSentences, selected: viewKeyword },
    { sentences: phenotypeSentences, selected: viewPhenotype },
    { sentences: therapySentences, selected: viewTherapy },
  ];

  const allSelectedSentences = allSentences
    .filter((sentenceGroup) => sentenceGroup.selected)
    .map((selectedGroup) => selectedGroup.sentences)
    .flat();

  const allUniqueSentences = [...new Set(allSelectedSentences)];

  return (
    <>
      <Box
        display="flex"
        flexDirection={isXLDesktop ? "row" : "column"}
        alignItems={isXLDesktop ? "center" : "flex-start"}
        justifyContent="space-between"
        marginBottom={2}
      >
        <Typography
          variant="text12"
          fontWeight="fontWeightBold"
          textTransform="uppercase"
          color={primaryPalette.teal.neutral_teal_t4}
        >
          Filter Matches:
        </Typography>
        <Box display="flex" alignItems="center" flexWrap="wrap" gap="2px">
          <KeywordSelector mmSelectorType={"gene"}>
            <KeywordFormControlLabel
              control={
                <Checkbox
                  checkedIcon={<CheckIcon />}
                  icon={<KeywordUncheckedIcon />}
                  checked={geneSentences.length === 0 ? false : viewGene}
                  onChange={() => dispatch(setViewGene(!viewGene))}
                  data-testid="gene-filter-checkbox"
                />
              }
              disabled={geneSentences.length === 0}
              label={`Genes ${geneSentences.length}`}
            />
          </KeywordSelector>
          <KeywordSelector mmSelectorType={"variant"}>
            <KeywordFormControlLabel
              control={
                <Checkbox
                  checkedIcon={<CheckIcon />}
                  icon={<KeywordUncheckedIcon />}
                  checked={variantSentences.length === 0 ? false : viewVariant}
                  onChange={() => dispatch(setViewVariant(!viewVariant))}
                  data-testid="variant-filter-checkbox"
                />
              }
              disabled={variantSentences.length === 0}
              label={`Variants ${variantSentences.length}`}
            />
          </KeywordSelector>
          <KeywordSelector mmSelectorType={"cnv"}>
            <KeywordFormControlLabel
              control={
                <Checkbox
                  checkedIcon={<CheckIcon />}
                  icon={<KeywordUncheckedIcon />}
                  checked={cnvSentences.length === 0 ? false : viewCnv}
                  onChange={() => dispatch(setViewCnv(!viewCnv))}
                  data-testid="cnv-filter-checkbox"
                />
              }
              disabled={cnvSentences.length === 0}
              label={`CNVs ${cnvSentences.length}`}
            />
          </KeywordSelector>
          <KeywordSelector mmSelectorType={"keyword"}>
            <KeywordFormControlLabel
              control={
                <Checkbox
                  checkedIcon={<CheckIcon />}
                  icon={<KeywordUncheckedIcon />}
                  checked={keywordSentences.length === 0 ? false : viewKeyword}
                  onChange={() => dispatch(setViewKeyword(!viewKeyword))}
                />
              }
              disabled={keywordSentences.length === 0}
              label={`Keywords ${keywordSentences.length}`}
            />
          </KeywordSelector>
          <KeywordSelector mmSelectorType={"phenotype"}>
            <KeywordFormControlLabel
              control={
                <Checkbox
                  checkedIcon={<CheckIcon />}
                  icon={<KeywordUncheckedIcon />}
                  checked={
                    phenotypeSentences.length === 0 ? false : viewPhenotype
                  }
                  onChange={() => dispatch(setViewPhenotype(!viewPhenotype))}
                />
              }
              disabled={phenotypeSentences.length === 0}
              label={`Phenotypes ${phenotypeSentences.length}`}
            />
          </KeywordSelector>
          <KeywordSelector mmSelectorType={"therapy"}>
            <KeywordFormControlLabel
              control={
                <Checkbox
                  checkedIcon={<CheckIcon />}
                  icon={<KeywordUncheckedIcon />}
                  checked={therapySentences.length === 0 ? false : viewTherapy}
                  onChange={() => dispatch(setViewTherapy(!viewTherapy))}
                />
              }
              disabled={therapySentences.length === 0}
              label={`Therapies ${therapySentences.length}`}
            />
          </KeywordSelector>
        </Box>
      </Box>

      <Box marginBottom={2}>
        <AccordionButton
          handleClick={() => setExpanded((prev) => !prev)}
          expanded={expanded}
          label={`${allUniqueSentences.length || 0} Text Matches`}
        />
      </Box>
      {expanded && (
        <Box>
          <Grid
            container
            id={"sentence-matches"}
            spacing={3}
            columns={12}
            sx={{
              overflowX: "hidden",
              marginBottom: "5px",
              borderBottom: `1px solid ${primaryPalette.brown.neutral_brown_b3}`,
            }}
          >
            {allUniqueSentences
              .slice(0, visibleItemCount)
              .map((sentence, index) => {
                return (
                  <ArticleSentence
                    key={index}
                    sentence={sentence}
                  ></ArticleSentence>
                );
              })}
          </Grid>
          {allUniqueSentences.length > minVisibleCount && (
            <Box
              sx={{
                display: "flex",
                paddingTop: "10px",
                paddingBottom: "20px",
                justifyContent: "center",
                alignItems: "center",
                gap: "4px",
                alignSelf: "stretch",
              }}
            >
              <SeeMoreButton
                callback={() =>
                  setVisibleItemCount((prev) =>
                    prev === minVisibleCount
                      ? allUniqueSentences.length
                      : minVisibleCount
                  )
                }
                hasMoreItems={allUniqueSentences.length > visibleItemCount}
                id="article-see-more"
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
