import { useAppDispatch } from "../store/hooks";
import { useHandleError } from "./errorHandlerHook";
import { useEffect } from "react";
import { getFeatures } from "../network/features";
import { loadFeatures } from "../store/slices/featureSlice";

export const useGetFeatures = () => {
  const dispatch = useAppDispatch();
  const handleError = useHandleError();

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const featuresResp = await getFeatures();
        if (featuresResp) {
          dispatch(loadFeatures(featuresResp));
        } else {
          handleError("Error retrieving features: unknown response");
        }
      } catch (error) {
        handleError(error);
      }
    };

    void fetchFeatures();
  }, []);
};
