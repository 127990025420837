import React from "react";
import { Box } from "@mui/material";

import { getProSignUpLink } from "../../../../../utils/links";
import { primaryPalette } from "../../../../../styles/theme";
import TextMatchViewer from "../../TextMatchViewer/TextMatchViewer";
import { useAppSelector } from "../../../../../store/hooks";
import { isProUser } from "../../../../../utils/user";
import ComponentLevelPaywall from "../../../../gene/common/ComponentLevelPaywall";
import SupplementalMatches from "../SupplementalMatches";

const ArticleKeywordsSection = () => {
  const { user } = useAppSelector((state) => state.user);

  return (
    <Box
      data-testid="article-text-matches"
      sx={{
        padding: "20px 30px",
        borderBottom: `1px solid ${primaryPalette.brown.neutral_brown_b3}`,
        background: primaryPalette.brown.neutral_brown_b1,
      }}
    >
      <TextMatchViewer />

      {isProUser(user) ? (
        <SupplementalMatches />
      ) : (
        <React.Fragment>
          <Box
            data-testid="supplemental-matches-basic"
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              letterSpacing: "0.56px",
              color: primaryPalette.teal.neutral_teal_t4,
              marginTop: "16px",
            }}
          >
            Supplemental Data
          </Box>
          <ComponentLevelPaywall
            signUpLink={getProSignUpLink(user)}
            titleText={"PRO EDITION PREVIEW"}
            bodyText={`You are missing out! Upgrade to professional view to see if there are supplemental matches.`}
            showLogIn={false}
          />
        </React.Fragment>
      )}
    </Box>
  );
};

export default ArticleKeywordsSection;
