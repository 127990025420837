import { Box, Stack, useMediaQuery } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { ArticleSkeletonLoader } from "../ArticleSkeletonLoader/ArticleSkeletonLoader";
import ExactMatch from "../ExactMatch/ExactMatch";
import { isProUser } from "../../../../utils/user";
import { useAppSelector } from "../../../../store/hooks";
import { elementHeights, theme } from "../../../../styles/theme";
import GeneVariantCard from "../../article-gene-variant-card/GeneVariantCard";
import ArticleListHeader from "../ArticleListHeader";
import { ArticlePageViews } from "../../../../types/articles";
import { UrlParams } from "../../../../types/url-params";
import ArticleList from "../ArticleList";

interface ArticleListWrapperProps {
  handleArticleChange: (pmid: string) => void;
  isFetchingArticleList: boolean;
  isEmpty: boolean;
  handleLoadMore: () => void;
}

const ArticleListWrapper = ({
  handleArticleChange,
  isFetchingArticleList,
  isEmpty,
  handleLoadMore,
}: ArticleListWrapperProps) => {
  const [searchParams] = useSearchParams();
  const { user } = useAppSelector((state) => state.user);
  const { urlTermSuggestions } = useAppSelector((state) => state.url);
  const allowArticlesCSVExport = !isEmpty && isProUser(user);
  const [isScrolled, setIsScrolled] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const articleListContainerRef = useRef<HTMLDivElement | null>(null);
  const articleHeaderRef = useRef<HTMLDivElement | null>(null);
  const articleHeaderHeight =
    articleHeaderRef?.current?.getBoundingClientRect().height ?? 0;
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isFeaturedArticleView = useMemo(() => {
    return (
      searchParams.get(UrlParams.VIEW) === ArticlePageViews.FEATURED_ARTICLE
    );
  }, [searchParams]);

  const shouldShowExactMatch = useMemo(() => {
    let variantHasAlternateRepresentations = false;
    for (const [_, variantSuggestion] of Object.entries(
      urlTermSuggestions.variant
    )) {
      const altNotations = variantSuggestion.keys ?? [];
      if (altNotations.length > 1) variantHasAlternateRepresentations = true;
      if (altNotations.length === 1 && altNotations[0] !== variantSuggestion.id)
        variantHasAlternateRepresentations = true;
    }
    return variantHasAlternateRepresentations && isProUser(user);
  }, [urlTermSuggestions.variant, user]);

  // listen for position of article list container and prevent article list from
  // being scrollable until the article list header is at the top of the page
  const onScroll = useCallback(() => {
    const articleListContainerTop =
      articleListContainerRef?.current?.getBoundingClientRect().top ?? 0;

    if (articleListContainerTop < 150) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }, [articleListContainerRef]);

  useEffect(() => {
    scrollContainerRef.current?.addEventListener("scroll", onScroll);
    return () =>
      scrollContainerRef.current?.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <Stack
      ref={scrollContainerRef}
      spacing={2}
      padding="8px 0 16px 8px"
      sx={{
        maxHeight: `calc(100vh - ${elementHeights.fixedHeaderContent})`,
        overflowY: "scroll",
        overscrollBehavior: "contain",
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Box pr={isMobile ? 2 : 1}>
        <GeneVariantCard />
      </Box>

      {!isMobile || (isMobile && !isFeaturedArticleView) ? (
        <>
          {shouldShowExactMatch ? (
            <Box pr={isMobile ? 2 : 1}>
              <ExactMatch />
            </Box>
          ) : null}

          <Box ref={articleListContainerRef}>
            <ArticleListHeader
              allowArticlesCSVExport={allowArticlesCSVExport}
              shouldShowExactMatchLabel={shouldShowExactMatch}
              articleHeaderRef={articleHeaderRef}
            />

            {isEmpty && !isFetchingArticleList ? (
              [1, 2, 3, 4, 5].map((i) => (
                <ArticleSkeletonLoader key={i} animation={false} />
              ))
            ) : (
              <ArticleList
                articleHeaderHeight={articleHeaderHeight}
                isScrolled={isScrolled}
                handleArticleChange={handleArticleChange}
                isFetchingArticleList={isFetchingArticleList}
                handleLoadMore={handleLoadMore}
              />
            )}
          </Box>
        </>
      ) : null}
    </Stack>
  );
};

export default ArticleListWrapper;
