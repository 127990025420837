import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import pluralize from "pluralize";
import TruncateMarkup from "react-truncate-markup";

import { useAppDispatch } from "../../../../../../store/hooks";
import { updateArticleSearchFilter } from "../../../../../../store/slices/articleSlice";
import { primaryPalette } from "../../../../../../styles/theme";

interface AuthorListProps {
  authorList: string[];
}

interface AuthorListToggleProps {
  callback(): void;
  label: string;
  testId: string;
}

const AuthorListToggle = ({
  callback,
  label,
  testId,
}: AuthorListToggleProps): React.ReactElement => {
  return (
    <Button
      size="small"
      variant="text"
      sx={{
        textTransform: "none",
        lineHeight: 1,
        fontSize: "12px",
        padding: "2px",
        marginTop: "-2px",
        minWidth: "auto",
      }}
      onClick={callback}
      data-testid={testId}
    >
      {label}
    </Button>
  );
};

const AuthorList = ({ authorList }: AuthorListProps) => {
  const dispatch = useAppDispatch();
  const handleAuthorClick = (author: string) => {
    dispatch(updateArticleSearchFilter(author));
  };
  const authors = authorList.filter((a) => a !== " ") ?? [];
  const [showAll, setShowAll] = useState(false);

  return (
    <>
      <Typography
        variant="h6"
        color={primaryPalette.teal.neutral_teal_t4}
        paddingBottom="4px"
      >
        {pluralize("Authors", authors.length)}
      </Typography>

      <TruncateMarkup
        lines={showAll ? 9999 : 3}
        lineHeight={12}
        ellipsis={
          <AuthorListToggle
            callback={() => setShowAll((prev) => !prev)}
            label="... See all"
            testId="see-all-authors-toggle"
          />
        }
      >
        <Typography
          sx={{
            color: primaryPalette.teal.neutral_teal_t6,
            fontSize: "12px",
            lineHeight: "1.3",
          }}
        >
          {authors.map((author, i, arr) => {
            return (
              <TruncateMarkup.Atom key={`${author}-${i}`}>
                <span
                  style={{
                    color: `${primaryPalette.teal.neutral_teal_t6}`,
                  }}
                  key={`${author}-${i}`}
                >
                  <Typography
                    variant="text12"
                    sx={{ cursor: "pointer", margin: "0px !important" }}
                    onClick={() =>
                      handleAuthorClick(
                        author.split(" ").slice(0, -1).join(" ")
                      )
                    } //frustratingly, our API doesn't like it when the first name initial is present so we strip that out
                  >
                    {author}
                  </Typography>
                  {arr.length - 1 !== i ? "," : ""}&nbsp;
                </span>
              </TruncateMarkup.Atom>
            );
          })}
          {showAll ? (
            <TruncateMarkup.Atom>
              <AuthorListToggle
                callback={() => setShowAll((prev) => !prev)}
                label="See less"
                testId="see-less-authors-toggle"
              />
            </TruncateMarkup.Atom>
          ) : null}
        </Typography>
      </TruncateMarkup>
    </>
  );
};

export default AuthorList;
