import React from "react";
import { Article } from "../../../../../network/articles";
import { Grid, Link, Typography } from "@mui/material";
import DOMPurify from "dompurify";
import { primaryPalette } from "../../../../../styles/theme";
import { pubmedLink } from "../../../../../utils/links";
import { LightTooltip } from "../../../Tooltips";
import ClinVarIcon from "../../../icons/SmallClinVarIcon";

export const clinvarTooltipText =
  "Article included in ClinVar evidence for your variant";

const ClinVarIconTooltip = () => {
  return (
    <LightTooltip
      data-testid={"clinvar-tooltip"}
      arrow
      placement={"right"}
      title={clinvarTooltipText}
    >
      <ClinVarIcon width=".6em" height=".6em" />
    </LightTooltip>
  );
};

export const ArticleCardBody = ({
  article,
  showClinVarBadge,
}: {
  article: Article;
  showClinVarBadge: boolean;
}) => {
  return (
    <Grid container direction="column" gap={1}>
      <Grid item>
        <Typography
          variant={"text12"}
          color={primaryPalette.grey.neutral_grey_g9}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(article.title),
          }}
        />
      </Grid>
      <Grid container direction="row">
        <Grid container item direction="column" xs={8} pl={1}>
          <Grid item>
            <Typography
              variant="text11"
              color={primaryPalette.grey.neutral_grey_g7}
              style={{
                fontWeight: 700,
              }}
            >
              {article.publication_date}
            </Typography>
          </Grid>
          <Grid container item gap={1}>
            {(article.authors ?? []).length > 0 && (
              <Grid item>
                <Typography
                  variant="text11"
                  color={primaryPalette.gray.variant_07}
                >
                  {article.authors[0]}
                </Typography>
              </Grid>
            )}
            <Grid item>
              <Typography
                variant="text11"
                color={primaryPalette.gray.variant_07}
              >
                {article.journal}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={4} justifyContent="flex-end" alignItems="center" gap={.5}>
          {showClinVarBadge && (
            <Grid item alignItems="center">
              <ClinVarIconTooltip />
            </Grid>
          )}
          <Grid item>
            <Typography variant="text11" color={primaryPalette.gray.variant_07}>
              PMID{" "}
              <Link
                underline="always"
                target={"_blank"}
                rel={"noopener"}
                href={pubmedLink(article.pmid)}
              >
                {article.pmid}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
