import { CaretUpIcon } from "./icons/CaretUpIcon";
import { CaretDownIcon } from "./icons/CaretDownIcon";
import React from "react";
import { Button } from "@mui/material";
import { primaryPalette } from "../../styles/theme";

export default function AccordionButton(props: {
  expanded: boolean;
  handleClick: () => void;
  label: string;
}) {
  const { expanded, handleClick, label } = props;

  return (
    <Button
      disableRipple
      startIcon={expanded ? <CaretDownIcon /> : <CaretUpIcon />}
      onClick={() => handleClick()}
      sx={{
        fontSize: "14px",
        fontWeight: "600",
        letterSpacing: "0.56px",
        color: primaryPalette.teal.neutral_teal_t4,
      }}
    >
      {label}
    </Button>
  );
}
