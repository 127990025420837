import React from "react";
import { primaryPalette, theme } from "../../styles/theme";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useAnchorEl } from "../../hooks/useAnchorEl";
import { isProUser } from "../../utils/user";
import { useHandleFilterInteraction } from "../../hooks/useHandleFilterInteraction";
import Popper from "@mui/material/Popper";
import { useAppSelector } from "../../store/hooks";
import FilterContainer from "./FilterContainer";
import { useGetCategories } from "../../hooks/useGetCategories";
import { Button, ClickAwayListener } from "@mui/material";

export default function FilterButton() {
  const { user } = useAppSelector((state) => state.user);
  const { anchorEl, handleClick, handleClose } = useAnchorEl();
  const { onCancelSearch, onSearch } = useHandleFilterInteraction();

  const open = Boolean(anchorEl);
  const id = open ? "close-category-filter" : "open-category-filter";

  useGetCategories();

  return (
    <React.Fragment>
      <Button
        variant="searchBump"
        aria-describedby={id}
        onClick={handleClick}
        style={{
          border: `1px solid ${primaryPalette.blue.brand_main}`,
          height: "46px",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        {open ? (
          <KeyboardArrowUpIcon fontSize="small" />
        ) : (
          <KeyboardArrowDownIcon fontSize="small" />
        )}
      </Button>
      <Popper
        sx={{
          bgcolor: "white",
          border: "1px solid black",
          zIndex: theme.zIndex.appBar,
          width: "98%",
          maxHeight: "65%",
          margin: "0% 1% !important",
          overflowY: "auto",
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
      >
        <ClickAwayListener
          onClickAway={() => {
            onCancelSearch();
            handleClose();
          }}
        >
          <div>
            <Button
              variant="contained"
              disabled={!isProUser(user)}
              aria-label={"Add Filters to Search"}
              onClick={() => {
                onSearch();
                handleClose();
              }}
              color={"primary"}
              style={{
                border: `1px solid ${theme.palette.secondary.main}`,
                left: "40%",
                top: "10%",
                margin: "5px",
              }}
            >
              Add Filters to Search
            </Button>
            <Button
              variant="contained"
              aria-label={"Cancel Category Selection"}
              onClick={() => {
                onCancelSearch();
                handleClose();
              }}
              color={"secondary"}
              style={{
                border: `1px solid ${theme.palette.secondary.main}`,
                left: "40%",
                top: "10%",
                margin: "5px",
              }}
            >
              Cancel
            </Button>
            <hr />
            <FilterContainer />
          </div>
        </ClickAwayListener>
      </Popper>
    </React.Fragment>
  );
}
