import React, { useEffect, useMemo, useRef, useState } from "react";
import { elementHeights, theme } from "../../styles/theme";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, useMediaQuery } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {
  GetArticleResponse,
  GetArticlesResponse,
  GetClinVarArticlesResponse,
  useLazyGetArticlesListQuery,
  useLazyGetClinVarArticlesQuery,
  useLazyGetFeaturedArticleQuery,
} from "../../network/articles";
import { useHandleError } from "../../hooks/errorHandlerHook";
import RelatedVariantsComponent from "../../components/article/related-variants/RelatedVariants";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { checkUrlHasTag } from "../../utils/search";
import { UrlParams } from "../../types/url-params";
import AdditionalDetailsDrawer from "../../components/article/additional-details-drawer";
import { useLazyGetGeneEvidenceQuery } from "../../network/genes";
import { IconBack } from "../../components/common/icons/Generic";
import { updateFeaturedArticleData } from "../../store/slices/featuredArticleSlice";
import {
  displayCuratedContent,
  updateArticleListData,
  updateClinVarArticlesData,
} from "../../store/slices/articleSlice";
import { useSnackbar } from "notistack";
import { SearchBarTerms } from "../../store/slices/searchSlice";
import _isEqual from "lodash/isEqual";
import { selectUrlTermIds } from "../../store/selectors/urlSelectors";
import ArticleListWrapper from "../../components/article/article-list/ArticleListWrapper";
import { getFeatureFlagByName } from "../../store/selectors/featureFlagSelectors";
import { RootState } from "../../store/store";
import { useUrlSearchParamState } from "../../hooks/useSearchParamState";
import { ArticlePageViews } from "../../types/articles";
import ArticleViewerWrapper from "../../components/article/article-viewer/ArticleViewerWrapper";
import EvidenceViewerWrapper from "../../components/article/evidence-viewer/EvidenceViewerWrapper";

const notValidPmidError =
  "The displayed article has no data matching your search.";

export default function Articles() {
  const [searchParams] = useSearchParams();
  const handleError = useHandleError();
  const { enqueueSnackbar } = useSnackbar();
  const { updateSearchParams } = useUrlSearchParamState();

  const dispatch = useAppDispatch();
  const { isLoggedIn } = useAppSelector((state) => state.user, shallowEqual);
  const {
    articleSearchFilter,
    articleFilterItem,
    articleSortItem,
    showCuratedContent,
  } = useAppSelector((state) => state.articles, shallowEqual);
  const { urlTermBooleans, urlCats, urlSigTerms } = useAppSelector(
    (state) => state.url
  );
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const curatedContentVisible = useSelector((state: RootState) =>
    getFeatureFlagByName(state, "curated_content")
  );
  const hasRelatedVariants = urlTermIds[SearchBarTerms.variant].length > 0;

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLgDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const prevArticleSearchFilterRef = useRef(articleSearchFilter);
  const prevUrlTermIdsRef = useRef(urlTermIds);
  const prevUrlTermBooleansRef = useRef(urlTermBooleans);
  const prevUrlCatsRef = useRef(urlCats);
  const prevUrlSigTermsRef = useRef(urlSigTerms);

  const [offset, setOffset] = useState(0);
  const [isFetchingListAndArticleData, setIsFetchingListAndArticleData] =
    useState(true);

  const isFeaturedArticleView = useMemo(() => {
    return (
      searchParams.get(UrlParams.VIEW) === ArticlePageViews.FEATURED_ARTICLE
    );
  }, [searchParams]);

  const [
    triggerGetArticlesListQuery,
    {
      data: articlesListData,
      isError: isErrorArticlesList,
      isFetching: isFetchingArticlesList,
      isUninitialized: isUninitializedArticlesList,
    },
  ] = useLazyGetArticlesListQuery();

  const isLoadingOrInitArticleList =
    isFetchingArticlesList || isUninitializedArticlesList;

  const isEmptyArticlesList = useMemo(() => {
    return (articlesListData?.articles ?? []).length === 0;
  }, [articlesListData?.articles]);

  const checkHaveParamsUpdated = () => {
    const urlTermIdsUpdated = !_isEqual(prevUrlTermIdsRef.current, urlTermIds);
    const urlTermBooleansUpdated = !_isEqual(
      prevUrlTermBooleansRef.current,
      urlTermBooleans
    );
    const urlTermCatsUpdated = !_isEqual(prevUrlCatsRef.current, urlCats);
    const urlTermSigTermsUpdated = !_isEqual(
      prevUrlSigTermsRef.current,
      urlSigTerms
    );
    return (
      urlTermIdsUpdated ||
      urlTermBooleansUpdated ||
      urlTermCatsUpdated ||
      urlTermSigTermsUpdated
    );
  };

  const fetchArticlesList = async (
    offsetArg: number,
    fetchArticle: boolean
  ) => {
    if (!checkUrlHasTag(urlTermIds)) return;

    setIsFetchingListAndArticleData(true);
    await triggerGetArticlesListQuery(
      {
        urlTermIds: urlTermIds,
        urlBooleanParams: urlTermBooleans,
        urlCats: urlCats,
        urlSigTerms: urlSigTerms,
        options: {
          sortField: articleSortItem.id,
          sortAsc: articleSortItem.asc || false,
          searchText: articleSearchFilter,
          filterText: articleFilterItem.id,
          offset: offsetArg,
        },
      },
      true
    )
      .then(async ({ data }) => {
        dispatch(updateArticleListData(data as GetArticlesResponse));

        // update previous refs so we can compare
        // search params accurately in the useEffect
        prevUrlTermIdsRef.current = urlTermIds;
        prevUrlTermBooleansRef.current = urlTermBooleans;
        prevUrlCatsRef.current = urlCats;
        prevUrlSigTermsRef.current = urlSigTerms;

        const articleSearchFilterHasUpdated =
          articleSearchFilter !== prevArticleSearchFilterRef.current;

        prevArticleSearchFilterRef.current = articleSearchFilter;

        const firstArticlePMID = data?.articles?.[0]?.pmid;
        const pmidSearchParam = searchParams.get(UrlParams.PMID);

        // if articleSearchFilter has not updated and there is an existing PMID search param which is present in the article list, feature the existing PMID article
        // if articleSearchFilter has updated or no PMID search param exists, feature the first article in the results and sync url search params
        const useCurrentFeaturedArticle =
          !articleSearchFilterHasUpdated && pmidSearchParam;
        const pmidToLoad = useCurrentFeaturedArticle
          ? pmidSearchParam
          : firstArticlePMID
          ? firstArticlePMID
          : "";

        if (!pmidSearchParam && pmidToLoad) {
          // sync search params with current featured article pmid
          updateSearchParams({ [UrlParams.PMID]: pmidToLoad }, true);
        }

        const currOffset = Math.floor((data?.articles ?? []).length / 200);
        setOffset(currOffset);

        if ((data?.articles ?? []).length > 0 && pmidToLoad && fetchArticle) {
          await fetchFeaturedArticle(pmidToLoad);
        } else {
          setIsFetchingListAndArticleData(false);
        }
      })
      .catch((err) => handleError(err));
  };

  const handleLoadMore = () => {
    if (articlesListData?.hasNext) {
      void fetchArticlesList(offset, false);
    }
  };

  const [
    triggerGetFeaturedArticleQuery,
    {
      data: featuredArticleData,
      isFetching: isFetchingFeaturedArticle,
      isError: isErrorFeaturedArticle,
    },
  ] = useLazyGetFeaturedArticleQuery();

  const errorState = isErrorArticlesList || isErrorFeaturedArticle;

  const isLoadingArticleViewer =
    !errorState && (isFetchingFeaturedArticle || isFetchingListAndArticleData);

  const isEmptyArticleViewer = isEmptyArticlesList || !featuredArticleData;

  const fetchFeaturedArticle = async (pmid: string) => {
    if (!isLoggedIn || !checkUrlHasTag(urlTermIds)) return;
    await triggerGetFeaturedArticleQuery(
      {
        pmid: pmid,
        urlTermIds: urlTermIds,
        urlBooleanParams: urlTermBooleans,
        urlCats: urlCats,
        urlSigTerms: urlSigTerms,
        options: {
          sortField: articleSortItem.id,
          sortAsc: articleSortItem.asc || false,
          searchText: articleSearchFilter,
          highlight: true,
        },
      },
      true
    )
      .then((res) => {
        dispatch(updateFeaturedArticleData(res.data as GetArticleResponse));

        // update prevSearchParamsRef so we can compare
        // search params accurately in the useEffect
        prevUrlTermIdsRef.current = urlTermIds;
        prevUrlTermBooleansRef.current = urlTermBooleans;
        prevUrlCatsRef.current = urlCats;
        prevUrlSigTermsRef.current = urlSigTerms;
        if (
          (res.data?.matchSummaries ?? []).length === 0 &&
          (res.data?.sentences ?? []).length === 0 &&
          (res.data?.condensedSentences ?? []).length === 0 &&
          res.status !== "rejected"
        ) {
          enqueueSnackbar(notValidPmidError, {
            variant: "error",
            preventDuplicate: true,
          });
        }
      })
      .catch((err) => handleError(err))
      .finally(() => setIsFetchingListAndArticleData(false));
  };

  const [triggerClinVarArticlesQuery] = useLazyGetClinVarArticlesQuery();

  const fetchClinVarArticles = async (variants: string[]) => {
    await triggerClinVarArticlesQuery(variants, true)
      .then(({ data }) => {
        dispatch(updateClinVarArticlesData(data as GetClinVarArticlesResponse));
      })
      .catch((err) => handleError(err));
  };

  const [triggerGetGeneEvidenceQuery] = useLazyGetGeneEvidenceQuery();

  useEffect(() => {
    void fetchArticlesList(0, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleSortItem, articleFilterItem, articleSearchFilter]);

  useEffect(() => {
    const currentSearchParamsPMID = searchParams.get(UrlParams.PMID);
    const haveParamsUpdated = checkHaveParamsUpdated();

    // if _only_ the PMID param changes, fetch new featured article data
    if (
      !isLoadingArticleViewer &&
      currentSearchParamsPMID &&
      !haveParamsUpdated
    ) {
      void fetchFeaturedArticle(currentSearchParamsPMID);
    } else if (haveParamsUpdated) {
      // if search or filter criteria has changed, fetch new articles list and close evidence viewer
      dispatch(displayCuratedContent(false));
      void fetchArticlesList(0, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, urlTermIds, urlTermBooleans, urlCats, urlSigTerms]);

  useEffect(() => {
    const variants = urlTermIds[SearchBarTerms.variant];
    if (variants.length > 0) {
      void fetchClinVarArticles(variants);
    }

    const genes = urlTermIds[SearchBarTerms.gene];
    if (genes.length > 0) {
      // fetch this to cache results
      void triggerGetGeneEvidenceQuery(genes, true).catch((err) =>
        handleError(err)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlTermIds]);

  const handleArticleViewChange = () => {
    updateSearchParams(
      { [UrlParams.VIEW]: ArticlePageViews.ARTICLE_LIST },
      false,
      () => {
        prevUrlTermIdsRef.current = urlTermIds;
        prevUrlTermBooleansRef.current = urlTermBooleans;
        prevUrlCatsRef.current = urlCats;
        prevUrlSigTermsRef.current = urlSigTerms;
      }
    );
  };

  const handleArticleChange = async (pmid: string) => {
    updateSearchParams(
      {
        [UrlParams.PMID]: pmid,
        [UrlParams.VIEW]: ArticlePageViews.FEATURED_ARTICLE,
      },
      false,
      () => {
        prevUrlTermIdsRef.current = urlTermIds;
        prevUrlTermBooleansRef.current = urlTermBooleans;
        prevUrlCatsRef.current = urlCats;
        prevUrlSigTermsRef.current = urlSigTerms;
      }
    );

    await fetchFeaturedArticle(pmid);
  };

  return (
    <div className="page">
      <Grid container id="article-page" spacing={2}>
        <Grid xs={12} md={5} lg={4} xl={3} pt={0}>
          <ArticleListWrapper
            isFetchingArticleList={isLoadingOrInitArticleList}
            isEmpty={isEmptyArticlesList}
            handleArticleChange={handleArticleChange}
            handleLoadMore={handleLoadMore}
          />
        </Grid>

        <Grid
          container
          xs={12}
          md={7}
          lg={8}
          xl={9}
          sx={
            !isMobile
              ? {
                  maxHeight: `calc(100vh - ${elementHeights.fixedHeaderContent})`,
                  overflowY: "scroll",
                  overscrollBehavior: "contain",
                  "::-webkit-scrollbar": {
                    display: "none",
                  },
                }
              : null
          }
        >
          {!isMobile || (isMobile && isFeaturedArticleView) ? (
            <Grid xs={12} md={12} lg={9} xl={10} pr={!isLgDesktop ? 3 : 1}>
              {isMobile ? (
                <Button
                  variant="text"
                  onClick={() => handleArticleViewChange()}
                  sx={{
                    fontSize: "18px",
                    textTransform: "none",
                    marginBottom: 2,
                  }}
                >
                  <IconBack width="12px" />
                  &nbsp;Back to article list
                </Button>
              ) : null}

              {curatedContentVisible && showCuratedContent ? (
                <EvidenceViewerWrapper error={false} />
              ) : (
                <ArticleViewerWrapper
                  errorState={errorState}
                  isLoadingArticleViewer={isLoadingArticleViewer}
                  isEmptyArticleViewer={isEmptyArticleViewer}
                />
              )}
            </Grid>
          ) : null}

          {hasRelatedVariants ? (
            <>
              {isLgDesktop ? (
                <Grid lg={3} xl={2}>
                  <RelatedVariantsComponent />
                </Grid>
              ) : (
                <AdditionalDetailsDrawer />
              )}
            </>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}
