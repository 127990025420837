import { Link, Typography } from "@mui/material";
import React from "react";
import { FlexRowDiv } from "../../../common/Flex";
import { BookIcon } from "../../../common/icons/BookIcon";
import { useSearchParams } from "react-router-dom";
import { useGetArticleCountQuery } from "../../../../network/articles";
import { useGetVariantsQuery } from "../../../../network/variants/variants";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { getArticlesLink } from "../../../../utils/links";
import { useAppSelector } from "../../../../store/hooks";
import { SearchBarTerms } from "../../../../store/slices/searchSlice";
import { primaryPalette } from "../../../../styles/theme";
import { selectUrlTermIds } from "../../../../store/selectors/urlSelectors";

const VariantArticleCount = () => {
  const { urlTermBooleans, urlCats, urlSigTerms } = useAppSelector(
    (state) => state.url
  );
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const searchedGene = !!urlTermIds[SearchBarTerms.gene][0];
  const [searchParams] = useSearchParams();
  const { articles } = useAppSelector((state) => state.features);
  const { data } = useGetArticleCountQuery(
    searchedGene
      ? {
          urlTermIds: urlTermIds,
          urlBooleanParams: urlTermBooleans,
          urlCats: urlCats,
          urlSigTerms: urlSigTerms,
        }
      : skipToken
  );
  const { data: variantData } = useGetVariantsQuery(
    searchedGene
      ? {
          urlTermIds: urlTermIds,
          urlBooleanParams: urlTermBooleans,
          urlCats: urlCats,
          urlSigTerms: urlSigTerms,
          urlString: urlTermIds.toString(),
        }
      : skipToken
  );

  const label =
    (variantData?.variants ?? []).length === 1 ? "Article" : "Articles";
  return (
    <FlexRowDiv>
      <BookIcon />
      <Link
        target="_blank"
        // TODO, this should use search slice data rather than searchParams
        href={getArticlesLink(articles, searchParams.toString())}
        underline="none"
      >
        <Typography
          data-testid="article-count"
          variant="h2"
          component="span"
          color={primaryPalette.grey.neutral_grey_g8}
          sx={{
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
          }}
        >
          {data && data.total.toLocaleString()}&nbsp;{label}
        </Typography>
      </Link>
    </FlexRowDiv>
  );
};

export default VariantArticleCount;
