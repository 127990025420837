import React from "react";
import { Chip, Typography } from "@mui/material";
import { useGetGeneInfoQuery } from "../../../../network/genes";
import { FlexColumnDiv } from "../../../common/Flex";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { primaryPalette } from "../../../../styles/theme";
import { useAppSelector } from "../../../../store/hooks";
import { SearchBarTerms } from "../../../../store/slices/searchSlice";
import { selectUrlTermIds } from "../../../../store/selectors/urlSelectors";

const GeneOverview = () => {
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const { data: geneData, isLoading } = useGetGeneInfoQuery(
    urlTermIds[SearchBarTerms.gene][0]
      ? {
          gene: urlTermIds[SearchBarTerms.gene][0],
        }
      : skipToken
  );

  if (!isLoading) {
    return (
      <FlexColumnDiv data-testid={"gene-geneOverview"}>
        <Typography variant={"h1"}>{geneData?.gene_symbol}</Typography>
        {geneData?.gene_description && (
          <Typography
            variant="text12"
            color={primaryPalette.grey.neutral_grey_g7}
            sx={{
              textAlign: "center",
              wordWrap: "break-word",
            }}
          >
            {geneData?.gene_description}
          </Typography>
        )}
        {geneData?.gene_type && (
          <Chip
            label={geneData?.gene_type}
            sx={{
              height: "1.5rem",
              marginTop: "0.75rem",
              fontWeight: 400,
              fontSize: "11px",
            }}
          />
        )}
      </FlexColumnDiv>
    );
  }

  return <></>;
};

export default GeneOverview;
