import React, { useEffect } from "react";
import { Article } from "../../../../network/articles";
import { useAppSelector } from "../../../../store/hooks";
import { isProUser } from "../../../../utils/user";
import { useLazyGetGeneEvidenceQuery } from "../../../../network/genes";
import { SearchBarTerms } from "../../../../store/slices/searchSlice";
import { selectUrlTermIds } from "../../../../store/selectors/urlSelectors";
import ArticleCard from "../../../common/cards/article-card/ArticleCard";
import { ArticleCardBody } from "../../../common/cards/article-card/ArticleCardBody/ArticleCardBody";
import { ArticleCardHeader } from "./ArticleCardHeader/ArticleCardHeader";

interface ArticleCardProps {
  article: Article;
  showClinVarBadge: boolean;
  handleArticleChange: () => void;
  selected: boolean;
}

export default function ArticleListCard({
  article,
  showClinVarBadge,
  handleArticleChange,
  selected,
}: ArticleCardProps) {
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const { isLoggedIn, user } = useAppSelector((state) => state.user);
  const anonymous = !isLoggedIn || !user;
  const [triggerGetGeneEvidenceQuery, { data: geneEvidenceData }] =
    useLazyGetGeneEvidenceQuery();

  useEffect(() => {
    const genes = urlTermIds[SearchBarTerms.gene];
    if (genes.length > 0) {
      void triggerGetGeneEvidenceQuery(genes, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlTermIds]);

  const onCardClick = () => {
    if (!anonymous) {
      handleArticleChange();
    }
  };

  return (
    <ArticleCard
      onCardClick={onCardClick}
      selected={selected}
      article={article}
    >
      <ArticleCard.Header>
        {isProUser(user) ? (
          <ArticleCardHeader
            geneEvidenceData={geneEvidenceData}
            article={article}
          />
        ) : null}
      </ArticleCard.Header>
      <ArticleCard.Body>
        <ArticleCardBody
          article={article}
          showClinVarBadge={showClinVarBadge}
        />
      </ArticleCard.Body>
    </ArticleCard>
  );
}
