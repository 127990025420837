import { User } from "../types/user";
import { reorderQueryParams } from "./search";
import { UrlParams } from "../types/url-params";

export const getProSignUpLink = (user: User | Record<string, never>) => {
  const base = "https://genomenon.com/mastermind-pro-upgrade/";
  if (Object.keys(user).length === 0) {
    return base;
  }
  const firstName = `?first_name=${user.first_name}`;
  const lastName = `&last_name=${user.last_name}`;
  const email = `&email=${user.email}`;
  return base + firstName + lastName + email;
};

export const getDiscoveryPurchaseLink = () => {
  return "mailto:sales@genomenon.com";
};

export const getArticlesLink = (
  articleFeature: boolean,
  searchParams: string
) => {
  if (articleFeature) {
    return `/articles?${searchParams}`;
  } else {
    return `/focus?${searchParams}`;
  }
};

export const getVariantLink = (articleFeature: boolean, variant: string) => {
  const urlSearchParams = new URLSearchParams(
    `${location.search}&${UrlParams.VARIANT}=${variant}`
  );
  const searchParams = reorderQueryParams(urlSearchParams).toString();

  if (articleFeature) {
    return `/articles?${searchParams}`;
  } else {
    return `/focus?${searchParams}`;
  }
};

export const pubmedLink = (pmid: string) => {
  return `https://pubmed.ncbi.nlm.nih.gov/${pmid}/`;
};
