import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { VariantResult } from "../../../../models/variant-result";
import { VariantListHeader } from "../VariantListHeader/VariantListHeader";
import { Order, filterMutations, getComparator } from "./filterList";
import { VariantListRow } from "../VariantListRow/VariantListRow";
import { useAppSelector } from "../../../../store/hooks";
import { formatCompactNumber } from "../../../../utils/variant";
import { TableSkeletonLoader } from "../../common/skeleton-loaders/TableSkeletonLoader";
import { getVariantLink } from "../../../../utils/links";
import { VariantIcon } from "../../../common/icons/VariantIcon";
import { useGetGeneInfoQuery } from "../../../../network/genes";
import {
  useGetVariantsQuery,
  useGetGeneCuratedDataQuery,
  useGetGeneVariantClinvarDataQuery,
} from "../../../../network/variants/variants";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { VariantListActions } from "../VariantListActions/VariantListActions";
import { SearchBarTerms } from "../../../../store/slices/searchSlice";
import { selectUrlTermIds } from "../../../../store/selectors/urlSelectors";
import { Variant } from "../../../../network/variants/types";

const getTableData = (
  variants: Variant[],
  articlesEnabled: boolean,
  proteinLength: number
) => {
  return variants.map((v) => {
    return new VariantResult(
      v.gene,
      v.mutation,
      v.name,
      v.total_hits,
      v.without_mut_hits,
      v.with_mut_hits,
      v.type,
      articlesEnabled,
      proteinLength
    );
  });
};

// For the variant name, we sort by position. If we need to, we can add other
// "sort property" names for columns if they do not match.
export const sortPropertyForColumn = (property: keyof VariantResult) => {
  switch (property) {
    case "mutation":
    case "cdnaPosition":
      return "position";
    default:
      return property;
  }
};

export function VariantList() {
  const { urlTermBooleans, urlCats, urlSigTerms } = useAppSelector(
    (state) => state.url
  );
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const searchedGene = urlTermIds[SearchBarTerms.gene][0];
  const { data: geneData } = useGetGeneInfoQuery(
    searchedGene
      ? {
          gene: searchedGene,
        }
      : skipToken
  );
  const {
    isLoading: variantDataLoading,
    isFetching,
    data: variantData,
  } = useGetVariantsQuery(
    searchedGene
      ? {
          urlTermIds: urlTermIds,
          urlBooleanParams: urlTermBooleans,
          urlCats: urlCats,
          urlSigTerms: urlSigTerms,
          urlString: urlTermIds.toString(),
        }
      : skipToken
  );
  const { data: clinVarVariants } = useGetGeneVariantClinvarDataQuery(
    searchedGene ?? skipToken
  );
  const { data: curatedVariants } = useGetGeneCuratedDataQuery(
    searchedGene ?? skipToken
  );
  const { filter } = useAppSelector((state) => state.variant);
  const { articles } = useAppSelector((state) => state.features);
  const [tableData, setTableData] = React.useState<VariantResult[]>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState<Order>(Order.DESC);
  const [orderBy, setOrderBy] =
    React.useState<keyof VariantResult>("totalArticles");
  const [listFilter, setListFilter] = React.useState<string>("");

  let filteredRows = tableData;
  const clinVarVarSet = React.useMemo(() => {
    if (clinVarVariants !== undefined) {
      return new Set([...clinVarVariants.mmKeys]);
    }
    return new Set();
  }, [clinVarVariants]);

  const curatedVarSet = React.useMemo(() => {
    return new Set(curatedVariants?.mmKeys);
  }, [curatedVariants]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof VariantResult
  ) => {
    property = sortPropertyForColumn(property);
    const isAsc = orderBy === property && order === Order.ASC;
    setOrder(isAsc ? Order.DESC : Order.ASC);
    setOrderBy(property);
  };

  React.useEffect(() => {
    const maxAmino = (geneData?.protein_sequence ?? "").length + 2;
    if (variantData?.variants !== undefined) {
      const tableData = getTableData(variantData.variants, articles, maxAmino);
      setTableData(tableData);
    }
  }, [variantData]);

  const visibleRows = React.useMemo(() => {
    filteredRows = tableData.filter((row) => {
      return filterMutations(row, filter.toString());
    });

    if (listFilter === "Curated Evidence") {
      filteredRows = filteredRows.filter((row) => {
        return curatedVarSet.has(row.getName());
      });
    } else if (listFilter === "ClinVar Evidence") {
      filteredRows = filteredRows.filter((row) => {
        return clinVarVarSet.has(row.getName());
      });
    }

    return filteredRows
      .sort(getComparator(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [tableData, page, rowsPerPage, order, orderBy, filter, listFilter]);

  const searchCount = (rowData: VariantResult) => {
    if (urlCats.length > 0 || urlSigTerms.length > 0) {
      return rowData.withoutMutHits;
    } else if (
      // TODO: We expect when we move beyond gene page that if you include a variant you'll want withMutHits instead
      // TODO: of withoutMutHits here
      urlTermIds[SearchBarTerms.disease].length > 0 ||
      urlTermIds[SearchBarTerms.cnv].length > 0 ||
      urlTermIds[SearchBarTerms.unii].length > 0
    ) {
      return rowData.withoutMutHits;
    } else {
      return rowData.relevantArticles;
    }
  };

  const handleFilterChange = (filterStr: string) => {
    setListFilter(filterStr);
  };

  const tableBody = () => {
    if (variantDataLoading || isFetching) {
      return (
        <TableBody data-testid="skeleton-loader">
          <TableSkeletonLoader rowsNum={25} cellNum={4} />
        </TableBody>
      );
    }
    if (visibleRows.length > 0) {
      return (
        <TableBody>
          {visibleRows.map((row) => (
            <VariantListRow
              key={row.getId()}
              id={row.getId()}
              mutation={row.mutation}
              cdnaPosition={row.cdnaPosition}
              totalHits={searchCount(row)}
              type={row.type}
              articleUrl={getVariantLink(articles, row.variantName)}
              backgroundColor={row.bucketColor}
              variantResult={row}
              hasClinVar={clinVarVarSet.has(row.getName())}
              hasCurated={curatedVarSet.has(row.getName())}
            />
          ))}
        </TableBody>
      );
    }
    return (
      <TableBody>
        <TableRow>
          <TableCell>No variants found</TableCell>
        </TableRow>
      </TableBody>
    );
  };

  return (
    <Box sx={{ marginTop: ".5em" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "50px",
          marginBottom: "10px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <VariantIcon />
          <Typography
            data-testid="variant-count"
            variant="text16"
            component="span"
          >
            {formatCompactNumber(filteredRows.length)}{" "}
            {filteredRows.length === 1 ? "Variant" : "Variants"}
          </Typography>
        </Box>
        <VariantListActions handleFilter={handleFilterChange} />
      </Box>
      <TableContainer>
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="List of variants"
        >
          <VariantListHeader
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={tableData.length}
          />
          {tableBody()}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component={"div"}
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
