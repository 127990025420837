import App from "../pages/App";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../styles/theme";
import { store } from "../store/store";
import { Provider } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import logError from "../utils/log-error";
import { SnackbarProvider } from "notistack";
import SnackbarCloseButton from "../components/common/buttons/SnackBarCloseButton";
import { CookiesProvider } from "react-cookie";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <BrowserRouter>
    <CookiesProvider>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        action={(snackbarKey) => (
          <SnackbarCloseButton snackbarKey={snackbarKey} />
        )}
      >
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <ErrorBoundary onError={logError} fallback={<></>}>
              <App />
            </ErrorBoundary>
          </ThemeProvider>
        </Provider>
      </SnackbarProvider>
    </CookiesProvider>
  </BrowserRouter>
);
