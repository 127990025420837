import { useEffect } from "react";
import { useAppDispatch } from "../store/hooks";
import { getCommonCategories } from "../network/mm-categories";
import { loadCategoryLayout } from "../store/slices/categoryLayoutSlice";
import { useHandleError } from "./errorHandlerHook";

// Load the category display info and put it in store so we don't constantly,
// unnecessarily, reload it.
// NOTE: should only run once per page load if you're doing it right.
export const useGetCategories = () => {
  const dispatch = useAppDispatch();
  const handleError = useHandleError();

  useEffect(() => {
    const fetchCategoryLayout = async () => {
      try {
        const categoryResp = await getCommonCategories();
        if (categoryResp.data) {
          dispatch(loadCategoryLayout(categoryResp.data));
        } else {
          console.error("Error retrieving categories: unknown data");
        }
      } catch (error) {
        handleError(error);
      }
    };

    void fetchCategoryLayout();
  }, []);
};
