export type CurationRecord = {
  curatedFor: string;
  gene: string;
  isEnterprise: boolean;
  isSponsored: boolean;
  variants: CurationRecordVariant[];
};

export type CurationRecordVariant = {
  acmg_call: string;
  article_count: number;
  gene: string;
  id: string;
  type: string;
};

export type ClinVarRecord = {
  citations: number;
  description: string;
  displayName: string;
  fullName: string;
  id: string;
  interpretation: string;
  lastEvaluated: string;
  mostRecent: string;
  reviewStars: number;
  reviewStatus: string;
  submissions: number;
  submitterCalls: SubmitterCall[];
  url: string;
};

type SubmitterCall = {
  call: string;
  submitter: string;
};

export enum ArticlePageViews {
  ARTICLE_LIST = "articleList",
  FEATURED_ARTICLE = "featuredArticle",
}

export enum AssessmentBarType {
  SMALL,
  LARGE,
}
