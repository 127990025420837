import { Box, Divider, IconButton, Typography } from "@mui/material";
import React from "react";
import { styled } from "styled-components";
import { Close } from "@mui/icons-material";
import DOMPurify from "dompurify";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { dismissNotification } from "../../../../store/slices/notificationSlice";
import {
  MMNotification,
  dismissMessage,
} from "../../../../network/notifications";
import { useHandleError } from "../../../../hooks/errorHandlerHook";

const CardFlexBox = styled.div({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
});

const CardFlexItem = styled.div({
  display: "flex",
  alignItems: "center",
});

const ListItem = styled.li({
  padding: "6px 16px",
});

const NotificationsDropdown = () => {
  const handleError = useHandleError();
  const dispatch = useAppDispatch();
  const dismiss = (notification: MMNotification) => {
    dismissMessage(notification.id)
      .then(() => {
        dispatch(dismissNotification(notification));
      })
      .catch((err) => {
        handleError(err);
      });
  };
  const { userMessages } = useAppSelector((state) => state.notifications);

  return (
    <Box width={500}>
      {userMessages?.length > 0 ? (
        userMessages.map((notification: MMNotification) => {
          return (
            <Box key={notification.id}>
              <ListItem
                data-testid={`notification-dropdown-item-${notification.id}`}
                key={notification.id}
              >
                <CardFlexBox>
                  <CardFlexItem style={{ maxWidth: "90%" }}>
                    <Typography
                      variant="text16"
                      data-testid={`notification-message-${notification.id}`}
                    >
                      {notification.message}
                    </Typography>
                  </CardFlexItem>
                  <CardFlexItem>
                    <IconButton
                      data-testid={`notification-dismiss-${notification.id}`}
                      onClick={() => dismiss(notification)}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  </CardFlexItem>
                </CardFlexBox>
                <CardFlexBox>
                  <CardFlexItem>
                    <Typography
                      data-testid={`notification-body-${notification.id}`}
                      variant="text12"
                      sx={{ fontStyle: "italic" }}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(notification.body),
                      }}
                    ></Typography>
                  </CardFlexItem>
                </CardFlexBox>
              </ListItem>
              {userMessages.indexOf(notification) !==
                userMessages.length - 1 && <Divider />}
            </Box>
          );
        })
      ) : (
        <ListItem>No notifications</ListItem>
      )}
    </Box>
  );
};

export default NotificationsDropdown;
