import { Button, Typography } from "@mui/material";
import React from "react";
import { primaryPalette } from "../../../styles/theme";

interface NavBarItemProps {
  id: string;
  label: string;
  route?: string;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void | null;
  children?: React.ReactNode;
}

const NavBarItem = ({
  id,
  label,
  route,
  onClick = () => null,
  children = null,
}: NavBarItemProps) => {
  return (
    <>
      <Button
        key={id}
        data-testid={id}
        href={route}
        onClick={onClick}
        sx={{
          color: primaryPalette.teal.neutral_teal_t1,
          textTransform: "capitalize",
          fontSize: "1rem",
        }}
      >
        <Typography variant="text14" fontWeight="fontWeightBold">
          {label}
        </Typography>
      </Button>
      {children}
    </>
  );
};

export default NavBarItem;
