import React, { useEffect } from "react";
import GeneOverviewText from "../GeneOverviewText/GeneOverviewText";
import { FlexRowDiv } from "../../../common/Flex";
import { VariantTypes, variantWedgeColors } from "../../../../types/variant";
import drawDonutChart, {
  DonutData,
} from "../../../common/visualizations/DonutChart";
import { boxShadowStyles, primaryPalette } from "../../../../styles/theme";
import { VariantCounts } from "../../../../network/genes";

export default function CurationVisualization(props: {
  displayData: VariantCounts;
}) {
  const variantTypeCount: VariantCounts = props.displayData;

  const containerId = "variant-breakdown-donut";

  useEffect(() => {
    const prepareDonutData = () => {
      if (Object.values(variantTypeCount).length === 0) {
        const initialData = [
          {
            count: 1,
            color: primaryPalette.gray.variant_06,
          },
        ];
        return initialData;
      } else {
        const donutData: DonutData = [];

        Object.values(VariantTypes).forEach((variantType) => {
          donutData.push({
            count: variantTypeCount["types"][variantType]["total"],
            color: variantWedgeColors[variantType].backgroundColor,
          });
        });

        return donutData;
      }
    };

    const donutProps = {
      sideLength: 300,
      margin: 10,
      arcWidth: 15,
      containerId: containerId,
    };
    const donutData = prepareDonutData();

    drawDonutChart(donutProps, donutData);
  }, [variantTypeCount]);

  return (
    <React.Fragment>
      <div
        style={{
          minHeight: "306px",
          height: "fit-content",
          position: "relative",
          background: primaryPalette.gradients.teal.neutral_teal_t1,
          boxShadow: boxShadowStyles.genePage,
        }}
        data-testid={"variant-breakdown-graph"}
      >
        <GeneOverviewText />
        <div className={"container"}>
          <FlexRowDiv id={containerId} style={{ justifyContent: "center" }} />
        </div>
      </div>
    </React.Fragment>
  );
}
