import React from "react";
import { primaryPalette } from "../../../styles/theme";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { Link } from "@mui/material";
import GLogo from "../../common/icons/SmallGLogo";
import { useOpenSelectedModal } from "../../modals/modalHooks";
import { Modals } from "../../../store/slices/modalSlice";

const Paywall = styled.div`
  border-radius: 0 0 6px 6px;
  background-color: ${primaryPalette.blue.variant_01};
  display: flex;
  flex-direction: column;
  padding: 8px;
  font-size: 0.8rem;
`;

interface VariantPaywallProps extends React.HTMLAttributes<HTMLDivElement> {
  titleText: string;
  bodyText: string;
  signUpLink: string;
  showLogIn: boolean;
  id?: string;
  contactSales?: boolean;
}

const ComponentLevelPaywall = ({
  titleText,
  bodyText,
  signUpLink,
  showLogIn,
  id,
  contactSales,
  ...divProps
}: VariantPaywallProps) => {
  const openSelectedModal = useOpenSelectedModal();
  return (
    <Paywall id={id} data-testid={id ? id : "paywall-card"} {...divProps}>
      <div>
        <Typography
          variant="text11"
          fontWeight="fontWeightBold"
          style={{
            textTransform: "uppercase",
            color: primaryPalette.focus.variant_02,
            lineHeight: "13px",
          }}
        >
          {titleText}
        </Typography>
      </div>
      <div>
        <Typography
          variant="text14"
          style={{ color: `${primaryPalette.teal.neutral_teal_t2}` }}
        >
          {bodyText}
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "5px",
          alignItems: "baseline",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          {showLogIn && (
            <>
              <Typography
                variant="text11"
                onClick={() => openSelectedModal(Modals.login, undefined, true)}
                style={{
                  color: primaryPalette.focus.variant_02,
                  textDecoration: "underline",
                  cursor: "pointer",
                  lineHeight: "13px",
                }}
              >
                Log in
              </Typography>
              <Typography
                variant="text11"
                style={{
                  color: primaryPalette.focus.variant_02,
                  lineHeight: "13px",
                }}
              >
                or
              </Typography>
            </>
          )}
          <>
            <Typography variant="text11">
              <Link
                href={signUpLink}
                style={{
                  color: primaryPalette.focus.variant_02,
                  lineHeight: "13px",
                  textDecoration: "underline",
                }}
              >
                {contactSales
                  ? "Contact sales@genomenon.com to purchase license."
                  : "Sign up for full access"}
              </Link>
            </Typography>
          </>
        </div>
        <GLogo />
      </div>
    </Paywall>
  );
};

export default ComponentLevelPaywall;
