import { api } from "./api";

export type AcmgDescriptionResponse = {
  [key: string]: AcmgDescriptionType;
};

export type AcmgDescriptionType = {
  id: string;
  nickname: string;
  description: string;
  pathogenic: boolean;
  level: number;
  criteriaNum: number;
};

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAcmgClasses: builder.query<AcmgDescriptionResponse, undefined>({
      query: () => ({
        url: "/category/acmg_classes",
        method: "GET",
      }),
    }),
  }),
});

export const { useGetAcmgClassesQuery } = extendedApi;
