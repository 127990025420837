import React from "react";
import { Box, Typography } from "@mui/material";

export default function ACMGCriteriaSection() {
  return (
    <Box>
      <Typography>ACMG Criteria Section!</Typography>
    </Box>
  );
}
