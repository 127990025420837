import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { iconMap } from "../AssessmentBarWrapper";
import { primaryPalette } from "../../../../styles/theme";
import {
  AssessmentBarLargeProps,
  VariantSubTypesDisplay,
} from "../../../../types/card";
import { VariantSubTypes } from "../../../../types/variant";
import {
  getVariantColor,
  getVariantTypeString,
} from "../../../../utils/variant";
import { LogoGenomenonSmallNoBackground } from "../../../common/icons/SmallGLogo";

const AssessmentBarLarge: React.FC<AssessmentBarLargeProps> = ({
  selectedVariant,
  curatedVariantData,
  ribbonData,
}) => {
  const pathogenicity = curatedVariantData?.acmg_call as VariantSubTypes;
  return (
    <Box
      data-testid="assessment-bar-large"
      sx={{
        bgcolor: getVariantColor(pathogenicity),
        borderRadius: "16px 16px 0 0",
        padding: "15px",
      }}
    >
      <Stack direction="column">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <LogoGenomenonSmallNoBackground sx={{ fontSize: "1rem" }} />
            <Typography
              color={primaryPalette.gray.variant_01}
              variant="h4"
              fontWeight="fontWeightBold"
              data-testid="variant-type"
            >
              {selectedVariant
                ? getVariantTypeString(selectedVariant.type)
                : ""}
            </Typography>
          </Stack>
          {ribbonData?.record?.review_date && (
            <Typography
              variant="text14"
              color={primaryPalette.gray.variant_01}
              style={{
                display: "flex",
                alignItems: "center",
              }}
              fontWeight="fontWeightBold"
            >
              Last Updated{" "}
              {new Date(ribbonData?.record?.review_date).toLocaleDateString(
                undefined,
                {
                  year: "2-digit",
                  month: "numeric",
                  day: "numeric",
                }
              )}
            </Typography>
          )}
        </Stack>
        <Stack
          direction="row"
          sx={{ paddingBottom: "0.125rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography
              data-testid="variant-sub-type"
              variant="h2"
              color={primaryPalette.gray.variant_01}
            >
              {VariantSubTypesDisplay[pathogenicity] ?? pathogenicity}
            </Typography>
            {iconMap[pathogenicity]}
          </Stack>

          <Typography
            variant="text14"
            fontWeight="fontWeightLight"
            color={primaryPalette.gray.variant_01}
          >
            {ribbonData?.record?.disease?.display_name}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AssessmentBarLarge;
