import React from "react";
import { Article } from "../../../../../network/articles";
import { Stack, Tooltip, Typography, styled } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { GeneEvidenceResponse } from "../../../../../network/genes";
import { primaryPalette } from "../../../../../styles/theme";
import { LightTooltip } from "../../../../common/Tooltips";
import { LogoGenomenonSmallNoBackgroundBlue } from "../../../../common/icons/SmallGLogo";
import TargetIcon from "../../../../common/icons/Target";
import { SearchBarTerms } from "../../../../../store/slices/searchSlice";
import { GeneIcon } from "../../../../common/icons/GeneIcon";
import { useAppSelector } from "../../../../../store/hooks";
import { selectUrlTermIds } from "../../../../../store/selectors/urlSelectors";
import { VariantIcon } from "../../../../common/icons/VariantIcon";

const EvidenceTag = styled("div")({
  background: primaryPalette.dataVis.blue_light,
  gap: "4px",
  display: "flex",
  padding: "0px 4px 0px 2px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "2px",
  color: primaryPalette.grey.neutral_grey_g8,
});

const clinicalFunctionalTagLabel = (
  isClinical: boolean,
  isFunctional: boolean
) => {
  if (isClinical && !isFunctional) {
    return "Clinical";
  } else if (isFunctional && !isClinical) {
    return "Functional";
  } else if (isClinical && isFunctional) {
    return "Clinical/Functional";
  }
  return "";
};

export const exactMatchTooltipText =
  "Contains nucleotide-specific match for the searched variant";

const ExactMatchIconTooltip = () => {
  return (
    <LightTooltip
      data-testid={"exact-match-tooltip"}
      arrow
      placement={"right"}
      title={exactMatchTooltipText}
    >
      <TargetIcon width=".6em" height=".6em" />
    </LightTooltip>
  );
};

export const ArticleCardHeader = ({
  article,
  geneEvidenceData,
}: {
  article: Article;
  geneEvidenceData: GeneEvidenceResponse | undefined;
}) => {
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));

  let isFunctional = false;
  let isClinical = false;
  const hasVariant = urlTermIds[SearchBarTerms.variant].length > 0;
  const articleVariantCount =
    article?.mutation_counts?.[urlTermIds[SearchBarTerms.variant][0]] ?? "";

  if (geneEvidenceData) {
    isClinical = geneEvidenceData.clinical.has(article.pmid);
    isFunctional = geneEvidenceData.functional.has(article.pmid);
  }

  return (
    <Stack direction="row" justifyContent={"space-between"}>
      <Stack direction="row" gap={".2rem"}>
        {article.matched_supplemental && (
          <Tooltip title="Contains matches in supplemental materials" arrow>
            <AttachFileIcon
              sx={{ width: ".6em", height: ".6em", color: "black" }}
            />
          </Tooltip>
        )}
        {article.matched_dna && <ExactMatchIconTooltip />}
        {(isClinical || isFunctional) && (
          <EvidenceTag>
            <LogoGenomenonSmallNoBackgroundBlue
              width={".6em"}
              height={".6em"}
            />
            <Typography variant={"text11"}>{`${clinicalFunctionalTagLabel(
              isClinical,
              isFunctional
            )} Evidence`}</Typography>
          </EvidenceTag>
        )}
      </Stack>
      <Stack direction="row" gap={1}>
        <Stack direction="row" alignItems={"center"}>
          <GeneIcon height={".6em"} width={".6em"} />
          <Typography
            variant="text11"
            data-testid="article-gene-count"
            color={primaryPalette.teal.neutral_teal_t4}
          >
            {
              article.gene_summaries?.[urlTermIds[SearchBarTerms.gene][0]]
                ?.count
            }
          </Typography>
        </Stack>
        {hasVariant && articleVariantCount ? (
          <Stack direction="row" alignItems={"center"}>
            <VariantIcon height={".6em"} width={".6em"} />
            <Typography
              variant="text11"
              data-testid="article-variant-count"
              color={primaryPalette.teal.neutral_teal_t4}
            >
              {articleVariantCount}
            </Typography>
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
};
