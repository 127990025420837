import React from "react";
import { Stack } from "@mui/material";
import { primaryPalette } from "../../../../styles/theme";
import ArticleMetadataSection from "./ArticleMetadataSection";
import ArticleGeneVarSection from "./ArticleGeneVarSection";
import ArticleAbstractSection from "./ArticleBodySection";
import ArticleKeywordsSection from "./ArticleKeywordsSection";

export default function ArticleViewer() {
  return (
    <Stack
      data-testid="article-viewer-pane"
      spacing={2}
      sx={{
        backgroundColor: primaryPalette.gray.variant_01,
        borderRadius: "16px 16px 0px 0px",
        border: `2px solid ${primaryPalette.teal.neutral_teal_t3}`,
      }}
    >
      {/* journal, published date, title, authors, and pub med link */}
      <ArticleMetadataSection />

      {/* gene and variant counts/dropdowns */}
      <ArticleGeneVarSection />

      {/* text matches and supplemental data */}
      <ArticleKeywordsSection />

      {/* abstract, medical subject headings, keywords, substances */}
      <ArticleAbstractSection />
    </Stack>
  );
}
