import React from "react";
import LegendAccordionItem from "./LegendAccordionItem/LegendAccordionItem";
import { FlexColumnDiv } from "../../../common/Flex";
import { VariantTypes } from "../../../../types/variant";
import { VariantSumResult } from "../../../../utils/variant";
import { Typography } from "@mui/material";
import { primaryPalette } from "../../../../styles/theme";

type LegendProps = {
  variantClassificationSource: string;
  variantSummedData: VariantSumResult;
};

const Legend = ({
  variantClassificationSource,
  variantSummedData,
}: LegendProps) => {
  return (
    <FlexColumnDiv
      style={{
        padding: "15px",
        gap: "10px",
      }}
    >
      <Typography
        variant={"text11"}
        color={primaryPalette.grey.neutral_grey_g7}
        style={{ width: "100%" }}
      >
        {`Variants classified by ${variantClassificationSource}`}
      </Typography>
      <div
        style={{
          width: "100%",
          boxShadow:
            "2px 4px 4px 0px rgba(5, 46, 43, 0.08), -2px -4px 4px 0px rgba(255, 255, 255, 0.8)",
          borderRadius: "8px",
        }}
      >
        {(Object.values(VariantTypes) as Array<VariantTypes>).map((type) => (
          <LegendAccordionItem
            key={type}
            count={variantSummedData[type].count}
            subCount={variantSummedData[type].subCount}
            type={type}
            subTypeSummary={variantSummedData[type].subVariants}
            variantClassificationSource={variantClassificationSource}
          />
        ))}
      </div>
    </FlexColumnDiv>
  );
};

export default Legend;
