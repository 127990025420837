import React from "react";
import { Button } from "@mui/material";
import { primaryPalette } from "../../../../styles/theme";

interface SeeMoreButtonProps {
  callback(): void;
  hasMoreItems: boolean;
  id?: string;
}

const SeeMoreButton = ({ callback, hasMoreItems, id }: SeeMoreButtonProps) => {
  return (
    <Button
      sx={{
        color: primaryPalette.teal.neutral_teal_t4,
        fontFamily: "Rubik Regular",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
        letterSpacing: "0.48px",
        textTransform: "uppercase",
      }}
      onClick={callback}
      id={id}
      data-testid={id}
    >
      {hasMoreItems ? "See More" : "See Less"}
    </Button>
  );
};

export default SeeMoreButton;
