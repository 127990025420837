import React from "react";
import { styled } from "@mui/material/styles";
import { Divider } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import { AcmgCriteriaEntry } from "../../../../types/gene";
import { defaultValue } from "../Summary/Summary";
import { extractFootnotes } from "../SummaryFootnotes/SummaryFootnotes";
import { primaryPalette } from "../../../../styles/theme";
import { SummaryCriteriaHeader } from "../SummaryCriteriaHeader/SummaryCriteriaHeader";
import {
  initialACMGCriteria,
  useGetGeneInfoQuery,
} from "../../../../network/genes";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAppSelector } from "../../../../store/hooks";
import { SearchBarTerms } from "../../../../store/slices/searchSlice";
import { selectUrlTermIds } from "../../../../store/selectors/urlSelectors";

export const AcmgHeader =
  "Intrinsic Metrics for Application of ACMG gene wide criteria";
export const domainsSubheader = "Domains";
export const startSubheader = "Start";
export const endSubheader = "End";

export const clingenVcepDesc =
  "Presence of ClinGen variant curation expert panel and curated variants?";

const Header = styled(Typography)(() => ({
  fontSize: "1rem",
  borderBottom: `1px solid ${primaryPalette.gray.variant_11}`,
  textAlign: "left",
}));

const Text = styled(Typography)(() => ({
  fontSize: "0.65rem",
  wordBreak: "break-word",
}));

const Column = styled(Grid)(() => ({
  inlineSize: "min-content",
  paddingTop: "0.25rem",
  textAlign: "center",
  "& .blur": {
    filter: "blur(4px)",
  },
}));

interface SummaryCriteriaProps {
  isModal?: boolean;
}

export function SummaryCriteria({ isModal = false }: SummaryCriteriaProps) {
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const { data: geneData, isLoading } = useGetGeneInfoQuery(
    urlTermIds[SearchBarTerms.gene][0]
      ? {
          gene: urlTermIds[SearchBarTerms.gene][0],
        }
      : skipToken
  );
  const formatValue = (entry: AcmgCriteriaEntry) => {
    const { value } = entry;
    if (value === null) return defaultValue;
    if (typeof value === "number") return parseFloat(value.toFixed(2));
    return String(value).toUpperCase();
  };

  const determineBackgroundStyle = (index: number) => {
    if (!isModal) return {};
    const bgcolor = index % 2 === 0 ? primaryPalette.gray.variant_12 : "white";
    return { bgcolor };
  };

  const containerPaddingTop = isModal ? "0" : "24px";
  const footnotes = extractFootnotes(
    geneData?.acmg_criteria?.data ?? initialACMGCriteria
  );

  if (isLoading) return <></>;

  return (
    <Grid
      container
      spacing={0}
      sx={{ paddingTop: containerPaddingTop }}
      data-testid={"gene-summaryCriteria"}
    >
      <Column item xs={12}>
        <Header>{AcmgHeader}</Header>
      </Column>
      <Column
        item
        xs={12}
        sx={{
          ...determineBackgroundStyle(1),
          textAlign: "left",
          paddingBottom: "0.75rem",
        }}
      >
        <Text>
          {`${clingenVcepDesc} ${
            geneData?.acmg_criteria?.clingen_vcep ? "Yes" : "No"
          }`}
        </Text>
      </Column>
      {Object.keys(geneData?.acmg_criteria?.data ?? initialACMGCriteria).map(
        (criteria) => {
          const casedCriteria = criteria.toUpperCase();
          const entries =
            geneData?.acmg_criteria?.data[criteria] ??
            initialACMGCriteria[criteria];
          const paddingLeft = isModal ? "0.75rem" : "inherit";

          return (
            <React.Fragment key={criteria}>
              <Column item xs={12}>
                <Header sx={{ paddingTop: "1.5rem" }}>
                  {casedCriteria} Specific Metrics
                </Header>
              </Column>

              <SummaryCriteriaHeader isModal={isModal} />

              {entries.map((entry, index) => {
                const isLastEntry = index === entries.length - 1;
                const style = determineBackgroundStyle(index);
                const footnote = entry.citation;
                const shouldBlur = entry.call === "insufficient_permissions";

                return (
                  <React.Fragment key={entry.description}>
                    <Column
                      item
                      xs={4}
                      sx={{
                        ...style,
                        textAlign: "left",
                        paddingBottom: "0.75rem",
                        paddingLeft,
                      }}
                    >
                      <Text>
                        {entry.description}
                        {!!footnote && (
                          <sup
                            data-testid={`footnote-${
                              footnotes.indexOf(footnote) + 1
                            }`}
                          >
                            {footnotes.indexOf(footnote) + 1}
                          </sup>
                        )}
                      </Text>
                    </Column>
                    <Column
                      item
                      xs={3}
                      sx={style}
                      data-testid={`${criteria}-${entry.description}-value`}
                    >
                      <Text className={shouldBlur ? "blur" : ""}>
                        {formatValue(entry)}
                      </Text>
                    </Column>
                    <Column
                      item
                      xs={5}
                      sx={{ ...style }}
                      data-testid={`${criteria}-${entry.description}-call`}
                    >
                      <Text className={shouldBlur ? "blur" : ""}>
                        {entry.call || defaultValue}
                      </Text>
                    </Column>
                    {(isModal || !isLastEntry) && (
                      <Grid item xs={12}>
                        <Divider
                          sx={isModal ? { borderStyle: "dashed" } : {}}
                        />
                      </Grid>
                    )}
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          );
        }
      )}
    </Grid>
  );
}
