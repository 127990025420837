import { Close, KeyboardArrowDown, Search } from "@mui/icons-material";
import {
  ButtonGroup,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  ClickAwayListener,
  Box,
} from "@mui/material";
import React from "react";
import { primaryPalette } from "../../../../styles/theme";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  resetVariantFilter,
  updateVariantFilter,
} from "../../../../store/slices/variantSlice";
import { VariantListFilterMenu } from "../VariantListFilterMenu/VariantListFilterMenu";

export function VariantListActions(props: {
  handleFilter: (filterStr: string) => void;
}) {
  const dispatch = useAppDispatch();
  const { filter } = useAppSelector((state) => state.variant);
  const { handleFilter } = props;

  const [filterAnchorEl, setFilterAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [filterOpen, setFilterOpen] = React.useState<boolean>(false);
  const [filterButtonText, setFilterButtonText] =
    React.useState<string>("All Records");
  const [isSearchFocused, setIsSearchFocused] = React.useState<boolean>(false);

  const handleClose = () => {
    setFilterAnchorEl(null);
    setFilterOpen(false);
  };

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterAnchorEl(event.currentTarget);
    setFilterOpen(true);
  };

  const handleFilterChange = (filterStr: string) => {
    setFilterButtonText(filterStr);
    handleFilter(filterStr);
    handleClose();
  };

  const closeFilter = () => {
    setIsSearchFocused(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={() => closeFilter()}>
        <Box>
          <ButtonGroup size="small">
            {isSearchFocused && (
              <TextField
                id="variant-list-search-input"
                data-testid="variant-list-search-input"
                label="Filter by..."
                placeholder="V600E, 600, c.1799T>A, fs, etc."
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    paddingRight: "6px!important",
                    borderRadius: "0!important",
                  },
                }}
                style={{
                  minWidth: "300px",
                  background: "white",
                  borderRadius: "6px 0 0 6px",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-testid="variant-list-search-clear"
                        onClick={() => {
                          dispatch(resetVariantFilter());
                        }}
                      >
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => dispatch(updateVariantFilter(e.target.value))}
                value={filter}
              />
            )}
            {!isSearchFocused && (
              <Button
                data-testid="variant-list-search-btn"
                variant="contained"
                onClick={() => {
                  setIsSearchFocused(!isSearchFocused);
                }}
                style={{
                  background: primaryPalette.teal.neutral_teal_t2,
                  color: primaryPalette.teal.neutral_teal_t6,
                }}
              >
                <Search fontSize="small" />
              </Button>
            )}
            <Button
              data-testid="variant-list-filter-btn"
              variant="contained"
              endIcon={<KeyboardArrowDown />}
              style={{
                background: primaryPalette.teal.neutral_teal_t2,
                color: primaryPalette.teal.neutral_teal_t6,
                textTransform: "unset",
              }}
              onClick={handleFilterClick}
            >
              <Typography variant="text12">{filterButtonText}</Typography>
            </Button>
          </ButtonGroup>

          <VariantListFilterMenu
            filterAnchorEl={filterAnchorEl}
            filterOpen={filterOpen}
            handleFilterChange={handleFilterChange}
            handleClose={handleClose}
          />
        </Box>
      </ClickAwayListener>
    </>
  );
}
