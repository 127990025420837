import React, { useState } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";

import { useAppSelector } from "../../../../../store/hooks";
import { primaryPalette } from "../../../../../styles/theme";
import AccordionButton from "../../../../common/AccordionButton";
import ArticleParagraph from "../../ArticleParagraph/ArticleParagraph";

const ArticleAbstractSection = () => {
  const { data: articleData } = useAppSelector(
    (state) => state.featuredArticle
  );
  const [abstractExpanded, setAbstractExpanded] = useState(true);

  const hasMeshHeadings = () => {
    return (
      articleData?.article?.mesh_headings &&
      articleData.article.mesh_headings.length > 0
    );
  };

  const hasKeywords = () => {
    return (
      articleData?.article?.keywords && articleData.article.keywords.length > 0
    );
  };

  const hasSubstances = () => {
    return (
      articleData?.article?.substances &&
      articleData.article.substances.length > 0
    );
  };

  if (!articleData) {
    return null;
  }

  return (
    <>
      <Box sx={{ padding: "30px 30px 0 30px" }}>
        <AccordionButton
          handleClick={() => setAbstractExpanded((prev) => !prev)}
          expanded={abstractExpanded}
          label={"Abstract"}
        />
      </Box>

      <Box
        data-testid={"article-text"}
        sx={{
          padding: "0px 30px 30px 30px",
          marginRight: "-30px",
          height: "auto",
        }}
      >
        {abstractExpanded && (
          <Stack spacing={3}>
            {articleData.article.abstract?.map((paragraph, index) => {
              return (
                <ArticleParagraph
                  key={`article-text-${index}`}
                  label={paragraph.label}
                  paragraph={paragraph.text}
                />
              );
            })}
            {(hasMeshHeadings() || hasKeywords() || hasSubstances()) && (
              <Divider variant="middle" sx={{ my: 2 }} />
            )}
            {hasMeshHeadings() && (
              <Box>
                <Typography
                  variant="h4"
                  color={primaryPalette.teal.neutral_teal_t4}
                >
                  Medical Subject Headings
                </Typography>
                <Typography
                  variant="text14"
                  color={primaryPalette.teal.neutral_teal_t4}
                >
                  {(articleData.article.mesh_headings ?? []).join("; ")}
                </Typography>
              </Box>
            )}
            {hasKeywords() && (
              <Box>
                <Typography
                  variant="h4"
                  color={primaryPalette.teal.neutral_teal_t4}
                >
                  Keywords
                </Typography>
                <Typography
                  variant="text14"
                  color={primaryPalette.teal.neutral_teal_t4}
                >
                  {(articleData.article.keywords ?? []).join("; ")}
                </Typography>
              </Box>
            )}
            {hasSubstances() && (
              <Box>
                <Typography
                  variant="h4"
                  color={primaryPalette.teal.neutral_teal_t4}
                >
                  Substances
                </Typography>
                <Typography
                  variant="text14"
                  color={primaryPalette.teal.neutral_teal_t4}
                >
                  {(articleData.article.substances ?? []).join("; ")}
                </Typography>
              </Box>
            )}
          </Stack>
        )}
      </Box>
    </>
  );
};

export default ArticleAbstractSection;
