import { httpClient } from "./http-client";
import { AxiosResponse } from "axios/index";
import { User } from "../types/user";

type ContactUsData = {
  message: string;
  email: string;
};

export const contactUs = (data: ContactUsData) =>
  httpClient.post<any, AxiosResponse<User>, any>("/contact", data);

export const requestTrial = () =>
  httpClient.post<any, AxiosResponse<User>, any>("/request_trial");
