import React, { useEffect, useState } from "react";
import { primaryPalette } from "../../../../styles/theme";
import Typography from "@mui/material/Typography";
import { Button, ButtonGroup, Skeleton } from "@mui/material";
import Legend from "../Legend/Legend";
import { useAppSelector } from "../../../../store/hooks";
import {
  formatCompactNumber,
  prepareSubVariantsForAccordion,
} from "../../../../utils/variant";
import styled from "styled-components";
import CurationVisualization from "../CurationVisualization/CurationVisualization";
import {
  VariantCounts,
  initialVariantTypeCount,
  useGetGeneInfoQuery,
} from "../../../../network/genes";
import ComponentLevelPaywall from "../../common/ComponentLevelPaywall";
import { isProUser } from "../../../../utils/user";
import { getProSignUpLink } from "../../../../utils/links";
import { CIRCLE_GRAPH_BUTTON_HISTOGRAM_MINIMAP_HEIGHT } from "../../../../pages/Gene/Gene";
import {
  XAXIS_HEIGHT,
  GRAPH_HEIGHT,
  MARGIN,
  STICK_HEIGHT,
} from "../../histogram/VariantHistogramContainer";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FlexColumnDiv } from "../../../common/Flex";
import { SearchBarTerms } from "../../../../store/slices/searchSlice";
import { selectUrlTermIds } from "../../../../store/selectors/urlSelectors";

const VariantBreakdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${primaryPalette.teal.neutral_teal_t1};
  border-radius: 8px;
  box-shadow: -1px -1px 0px 0px rgba(1, 11, 10, 0.1),
    1px 1px 0px 0px rgba(255, 252, 240, 0.1);
`;

type ButtonTextContentProps = {
  type: string;
  count: number;
};

const ButtonTextContent = ({ type, count }: ButtonTextContentProps) => (
  <FlexColumnDiv>
    <Typography variant="text14" style={{ width: "120px", lineHeight: "16px" }}>
      {type}
    </Typography>
    <Typography variant="h2" component="span">
      {formatCompactNumber(count)}
    </Typography>
  </FlexColumnDiv>
);

function VariantBreakdown() {
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const {
    data: geneData,
    isLoading,
    isError,
  } = useGetGeneInfoQuery(
    urlTermIds[SearchBarTerms.gene].length > 0
      ? {
          gene: urlTermIds[SearchBarTerms.gene][0],
        }
      : skipToken
  );

  const genomenonVariantTypeCount: VariantCounts =
    geneData?.genomenon_variant_type_count ?? initialVariantTypeCount;
  const clinvarVariantTypeCount: VariantCounts =
    geneData?.clinvar_variant_type_count ?? initialVariantTypeCount;
  const [depressedButton, setDepressedButton] = useState<string>("Genomenon");
  const [variantCount, setVariantCount] = useState<{ [key: string]: number }>({
    Genomenon: 0,
    ClinVar: 0,
  });
  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    const variantCountTotals = {
      Genomenon: genomenonVariantTypeCount["total"],
      ClinVar: clinvarVariantTypeCount["total"],
    };
    setVariantCount(variantCountTotals);
  }, [genomenonVariantTypeCount, clinvarVariantTypeCount]);

  const onButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setDepressedButton(e.currentTarget.value);
  };

  const getVisualizationData = () => {
    if (depressedButton === "Genomenon") {
      return genomenonVariantTypeCount;
    } else {
      return clinvarVariantTypeCount;
    }
  };

  const getSubVariants = () => {
    if (depressedButton === "Genomenon") {
      return prepareSubVariantsForAccordion(genomenonVariantTypeCount);
    } else {
      return prepareSubVariantsForAccordion(clinvarVariantTypeCount);
    }
  };

  const variantBreakdownSectionHeight =
    XAXIS_HEIGHT +
    GRAPH_HEIGHT +
    MARGIN.top +
    MARGIN.bottom +
    CIRCLE_GRAPH_BUTTON_HISTOGRAM_MINIMAP_HEIGHT.height +
    STICK_HEIGHT +
    10;

  if (isLoading)
    return (
      <Skeleton variant="rectangular" height={variantBreakdownSectionHeight} />
    );
  if (isError)
    return (
      <VariantBreakdownContainer
        style={{ height: variantBreakdownSectionHeight }}
      />
    );

  return (
    <VariantBreakdownContainer data-testid={"gene-variantBreakdown"}>
      <div
        style={{
          padding: `${CIRCLE_GRAPH_BUTTON_HISTOGRAM_MINIMAP_HEIGHT.upDownPadding}px ${CIRCLE_GRAPH_BUTTON_HISTOGRAM_MINIMAP_HEIGHT.sidePadding}px`,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ButtonGroup
          sx={{ height: CIRCLE_GRAPH_BUTTON_HISTOGRAM_MINIMAP_HEIGHT.height }}
        >
          {["Genomenon", "ClinVar"].map((org) => (
            <Button
              variant={depressedButton === org ? "bumpDepressed" : "bump"}
              color="secondary"
              value={org}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                onButtonClick(e)
              }
              key={org}
              style={{ textTransform: "unset" }}
            >
              <ButtonTextContent count={variantCount[org]} type={org} />
            </Button>
          ))}
        </ButtonGroup>
      </div>
      <CurationVisualization displayData={getVisualizationData()} />
      <Legend
        variantClassificationSource={depressedButton}
        variantSummedData={getSubVariants()}
      />
      {isProUser(user) ? (
        <></>
      ) : (
        <ComponentLevelPaywall
          titleText={"pro edition preview"}
          bodyText={
            "Take a look inside for a breakdown of pathogenicity by variant type"
          }
          signUpLink={getProSignUpLink(user)}
          showLogIn={false}
        />
      )}
    </VariantBreakdownContainer>
  );
}

export default VariantBreakdown;
