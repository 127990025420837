import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import VariantTypeBox from "../../VariantTypeBox";
import { SubVariantDataType } from "../../../../../types/variant";
import { VariantSubTypes } from "../../../../../types/variant";
import { FlexRowDiv } from "../../../../common/Flex";
import { primaryPalette } from "../../../../../styles/theme";
import { Typography } from "@mui/material";

type LegendListProps = {
  subTypeSummary: Array<SubVariantDataType>;
};

// TODO: add in all labels once we have them
const ListItemLabels = {
  [VariantSubTypes.BENIGN]: "Benign",
  [VariantSubTypes.VUS]: "VUS",
  [VariantSubTypes.UNCURATED]: "Unclassified",
  [VariantSubTypes.PATHOGENIC]: "Pathogenic",
};

const LegendList = ({ subTypeSummary }: LegendListProps) => {
  const getColor = (type: VariantSubTypes) => {
    return primaryPalette[type].variant_01;
  };

  return (
    <List dense sx={{ padding: 0 }}>
      {subTypeSummary.map((el) => (
        <ListItem
          id={el.variant_type}
          key={`${el.variant_type}-${el.pathogenicity}`}
          sx={{ paddingTop: "0px 16px", justifyContent: "space-between" }}
          data-testid={`accordion-sub-item-${el.variant_type}`}
        >
          <FlexRowDiv style={{ gap: "10px" }}>
            <ListItemIcon sx={{ minWidth: "unset" }}>
              <VariantTypeBox
                style={{ backgroundColor: getColor(el.pathogenicity) }}
              />
            </ListItemIcon>
            <ListItemText primary={ListItemLabels[el.pathogenicity]} />
          </FlexRowDiv>
          <Typography>{el.unique_match_count}</Typography>
        </ListItem>
      ))}
    </List>
  );
};

export default LegendList;
