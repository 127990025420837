import {
  ACMGCategories,
  SubVariantDataType,
  VariantSubTypes,
  VariantTypeStrings,
  VariantTypes,
} from "../types/variant";
import { VariantCounts } from "../network/genes";
import { primaryPalette } from "../styles/theme";
import { Suggestion } from "../network/suggestions";
import { VariantResult } from "../models/variant-result";
import { Variant } from "../network/variants/types";

export type VariantSumResult = {
  [key in VariantTypes]: {
    count: number;
    subCount: number;
    subVariants: Array<SubVariantDataType>;
  };
};

export const prepareSubVariantsForAccordion = (
  variantData: VariantCounts
): VariantSumResult => {
  const summary: VariantSumResult = {
    [VariantTypes.INFRAME]: {
      count: 0,
      subCount: 0,
      subVariants: [],
    },
    [VariantTypes.NONCODING]: {
      count: 0,
      subCount: 0,
      subVariants: [],
    },
    [VariantTypes.MISSENSE]: {
      count: 0,
      subCount: 0,
      subVariants: [],
    },
    [VariantTypes.TRUNCATING]: {
      count: 0,
      subCount: 0,
      subVariants: [],
    },
    [VariantTypes.SPLICE]: {
      count: 0,
      subCount: 0,
      subVariants: [],
    },
    [VariantTypes.SYNONYMOUS]: {
      count: 0,
      subCount: 0,
      subVariants: [],
    },
  };

  Object.values(VariantTypes).forEach((variantType) => {
    Object.values(VariantSubTypes).forEach((patho) => {
      summary[variantType].count = variantData["types"][variantType]["total"];
      summary[variantType].subCount +=
        variantData["types"][variantType]["calls"][patho];
      const variant_type_data = {
        variant_type: variantType,
        unique_match_count: variantData["types"][variantType]["calls"][patho],
        pathogenicity: patho,
      };
      summary[variantType].subVariants.push(variant_type_data);
    });
  });

  return summary;
};

export const getVariantPositionLabel = (
  variant: Variant
): string | undefined => {
  if (variant.mutation.includes("5UTR")) {
    return "5UTR";
  } else if (variant.mutation.includes("3UTR")) {
    return "3UTR";
  } else if (variant.position) {
    return variant.position.toString();
  }
  return undefined;
};

export const getVariantTypeString = (type: string) => {
  switch (type) {
    case VariantTypeStrings.SUBSTITUTION:
      return "Missense";
    case VariantTypeStrings.SYNONYMOUS:
    case VariantTypeStrings.INSERTION:
    case VariantTypeStrings.DUPLICATION:
    case VariantTypeStrings.EXTENSION:
    case VariantTypeStrings.FRAMESHIFT:
    case VariantTypeStrings.SPLICE_REGION:
    case VariantTypeStrings.INTRONIC:
    case VariantTypeStrings.DELETION:
    case VariantTypeStrings.NONSENSE:
      return formatType(type);
    case VariantTypeStrings.UNTRANSLATED_REGION:
      return "UTR";
    case VariantTypeStrings.SPLICE_ACCEPTOR:
    case VariantTypeStrings.SPLICE_DONOR:
      return "Splice";
    case VariantTypeStrings.DELETION_INSERTION:
      return "Indel";
    default:
      return "";
  }
};

export const formatType = (type: string): string => {
  return type
    .split("_")
    .map((str) => str[0].toUpperCase() + str.substring(1))
    .join(" ");
};

export function formatCompactNumber(count: number): string {
  if (count < 1000) {
    return count.toString();
  } else if (count < 1000000) {
    return (count / 1000).toFixed(1) + "K";
  } else {
    return count.toString();
  }
}

export const getVariantColor = (type: VariantSubTypes): string | undefined => {
  if (primaryPalette[type]) return primaryPalette[type].variant_01;
  else return primaryPalette[VariantSubTypes.UNCURATED].variant_01;
};

export const AcmgCategoryMapping = {
  [ACMGCategories.POPULATION]: ["ba1", "bs1", "pm2"],
  [ACMGCategories.COMPUTATIONAL]: ["pp3", "bp4"],
  [ACMGCategories.INTRINSIC]: [
    "bp7",
    "bp3",
    "pm4",
    "pvs1",
    "ps1",
    "pm1",
    "pm5",
    "pp2",
    "bp1",
  ],
  [ACMGCategories.CLINICAL]: [
    "bs2",
    "bs4",
    "bp2",
    "bp5",
    "ps2",
    "ps4",
    "pp1",
    "pp1m",
    "pp4",
    "pm3",
    "pm6",
    "ps4m",
    "ppc",
    "ppc_homo",
    "ppc_het",
    "ppc_compound",
  ],
  [ACMGCategories.FUNCTIONAL]: ["ps3", "bs3"],
};

// getGeneFromVariantSuggestions accepts a variant id
// and a list of variant suggestions
// It finds the suggestion related to the given variant id
// and returns the suggestion's gene.
export const getGeneFromVariantSuggestions = (
  variantId: string,
  suggestions: Suggestion[]
) => {
  const variantSugg = suggestions.find((v) => v.id === variantId);
  let suggGene = variantSugg?.gene ?? "";
  if (!suggGene) {
    const firstKey = variantSugg?.keys?.[0];
    if (firstKey) {
      suggGene = VariantResult.getGene(firstKey);
    }
  }
  return suggGene;
};
