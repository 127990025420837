import { useEffect, useState } from "react";
import {
  emptySigCounts,
  getSignificantCounts,
} from "../network/mm-significant-counts";
import { useHandleError } from "./errorHandlerHook";
import { useAppSelector } from "../store/hooks";
import { selectUrlTermIds } from "../store/selectors/urlSelectors";

export const useGetSignificantTerms = () => {
  const { urlTermBooleans } = useAppSelector((state) => state.url);
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const [sigTerms, setSigTerms] = useState(emptySigCounts);
  const handleError = useHandleError();

  useEffect(() => {
    const fetchSigTerms = async () => {
      try {
        const sigTermRes = await getSignificantCounts(
          urlTermIds,
          urlTermBooleans
        );
        setSigTerms(sigTermRes);
      } catch (error) {
        handleError(error);
      }
    };
    void fetchSigTerms();
  }, [urlTermIds, urlTermBooleans]);

  return sigTerms;
};
