import { Menu } from "@mui/material";
import React from "react";
import {
  MenuItem,
  filterItems,
} from "../../../../../store/slices/articleSlice";
import ListMenuItem from "../ListMenuItem/ListMenuItem";

interface FilterProps {
  filterAnchorEl: null | HTMLElement;
  filterOpen: boolean;
  handleFilterItemClick: (item: MenuItem) => void;
  handleClose: () => void;
}

const FilterMenu = ({
  filterAnchorEl,
  filterOpen,
  handleFilterItemClick,
  handleClose,
}: FilterProps) => {
  return (
    <Menu
      data-testid="article-list-filter-menu"
      anchorEl={filterAnchorEl}
      open={filterOpen}
      onClose={handleClose}
    >
      {filterItems.map((item) => (
        <ListMenuItem
          key={item.id}
          type="filter"
          item={item}
          onClick={handleFilterItemClick}
        />
      ))}
    </Menu>
  );
};

export default FilterMenu;
