import {
  SearchBarTerms,
  SearchBarTermsType,
  SearchState,
  SearchedTermIds,
} from "../store/slices/searchSlice";
import { store } from "../store/store";
import { UrlParams } from "../types/url-params";
import { checkForProteinCoding } from "../network/protein-coding";
import { Location } from "react-router-dom";
import { Suggestion } from "../network/suggestions";

const SEARCH_PARAM_ORDER = [
  "gene",
  "mutation",
  "cnv",
  "disease",
  "hpo",
  "unii",
  "category_paths",
  "cats",
  "keywords",
  "sig_terms",
  "keyword",
  "mutation_source",
  "cnv_match_op",
  "article_filter",
  "boolean",
  "gene_op",
  "mutation_op",
  "cnv_op",
  "disease_op",
  "hpo_op",
  "unii_op",
  "keyword_op",
  "selected_curated_variant",
  "article_list_search",
  "article_sort",
  "article_sort_asc",
  "article_list_filter",
  "pmid",
  "search_source",
  "share",
  "view",
] as const;

// determineBestSuggestion takes a user searched term and term type (ex: c.123 and mutation)
// and a list of suggestions retrieved from the /suggest endpoint
// It then returns the best match, if found, that matches the searched type, and whether
// that was an exact match or not
export const determineBestSuggestion = (
  searchedTerm: string,
  searchedType: string,
  suggestions: Suggestion[]
): { bestSuggestion: Suggestion | undefined; exactMatch: boolean } => {
  let exactSuggestion: Suggestion | undefined;
  let firstTypeMatchSuggestion: Suggestion | undefined;
  suggestions.forEach((sugg) => {
    if (
      sugg.type === searchedType &&
      searchedTerm.toLowerCase() === sugg.id.toLowerCase()
    ) {
      exactSuggestion = sugg;
    }
    if (sugg.type === searchedType && !firstTypeMatchSuggestion) {
      firstTypeMatchSuggestion = sugg;
    }
  });
  if (exactSuggestion) {
    return { bestSuggestion: exactSuggestion, exactMatch: true };
  }
  return { bestSuggestion: firstTypeMatchSuggestion, exactMatch: false };
};

// For redirect links (when a user hits a paywall), we need to use the error message to determine what to strip
// out of the existing url so it can be used by all users
export const determineBasicURL = (searchParams: URLSearchParams) => {
  let redirectText = "";
  let redirectUrl = "";
  const geneParam = searchParams.get("gene");
  if (window.location.pathname === "/gene" && geneParam) {
    redirectUrl = `${location.pathname}?gene=${geneParam}`;
    redirectText = "Or use this similar gene-only search";
  }
  return {
    redirectUrl: redirectUrl,
    redirectText: redirectText,
  };
};

// returns back reordered URLSearchParams with the prescribed url param
// order outlined in our SEARCH_PARAM_ORDER const
// update query param to prefer the suggestion returned back from /suggest
// endpoint for search bar terms (ie, would replace BRAF with braf as this is the
// id returned from /suggest for BRAF)
// Will delete the pmid param if we are not on the articles page
export const reorderQueryParams = (
  searchQueryParams: URLSearchParams,
  searchBarTermSuggestions?: SearchBarTermsType
): URLSearchParams => {
  const reorderedSearchParams = new URLSearchParams();
  if (window.location.pathname !== "/articles") {
    const paramsToRemove = [
      UrlParams.PMID,
      UrlParams.SELECTED_CURATED_VARIANT,
      UrlParams.VIEW,
      UrlParams.ARTICLE_LIST_SEARCH,
      UrlParams.ARTICLE_SORT,
      UrlParams.ARTICLE_SORT_ASC,
      UrlParams.ARTICLE_LIST_FILTER,
    ];

    paramsToRemove.forEach((param) => {
      searchQueryParams.delete(param);
    });
  }
  SEARCH_PARAM_ORDER.map((k) => {
    const key = k === "mutation" ? "variant" : k;
    if (searchBarTermSuggestions && key in searchBarTermSuggestions) {
      const params =
        searchBarTermSuggestions[key as keyof typeof searchBarTermSuggestions];
      params.map((par) => {
        reorderedSearchParams.append(k, par.id);
      });
    } else {
      const params = searchQueryParams.getAll(k);
      params.map((par) => {
        reorderedSearchParams.append(k, par);
      });
    }
  });
  return reorderedSearchParams;
};

// constructs the url query param using the prescribed order in searchParamsOrder object
export const constructURLQueryParams = (): URLSearchParams => {
  const { search } = store.getState();
  const { articleFilterItem, articleSearchFilter, articleSortItem } =
    store.getState().articles;
  const urlSearchParams = new URLSearchParams();

  Object.values(search[UrlParams.CATEGORY]).map((category) => {
    urlSearchParams.append(UrlParams.CATEGORY, category.key);
  });

  // add filter Sig Terms
  Object.values(search[UrlParams.SIGTERM]).map((term) => {
    urlSearchParams.append(UrlParams.SIGTERM, term.key);
  });

  // add terms
  (Object.keys(SearchBarTerms) as Array<keyof SearchBarTermsType>).map((v) => {
    // get and set suggestions from queryParams
    if (search[v].length > 0) {
      const urlQueryTerm = v === SearchBarTerms.variant ? "mutation" : v;

      search[v].map((sugg) => {
        urlSearchParams.append(urlQueryTerm, sugg.id);
      });

      // add term operator
      urlSearchParams.append(`${urlQueryTerm}_op`, search.booleans[v]);
    }
  });

  // add article list filter and sort items
  urlSearchParams.append(UrlParams.ARTICLE_SORT, articleSortItem.id);
  urlSearchParams.append(
    UrlParams.ARTICLE_SORT_ASC,
    articleSortItem.asc.toString()
  );
  urlSearchParams.append(UrlParams.ARTICLE_LIST_FILTER, articleFilterItem.id);

  // add article search filter
  urlSearchParams.append(UrlParams.ARTICLE_LIST_SEARCH, articleSearchFilter);

  return reorderQueryParams(urlSearchParams);
};

export const isProteinCoding = (gene: string) => {
  return checkForProteinCoding(gene).then((response) => {
    return response.coding_gene;
  });
};

// any search with CNV will go to detail page
// any search with a single gene only (no CNV and no variant) will go to gene page
// any search with more than one gene (no variant) will go to detail page
// any search with variant + gene and NO CNV OR more than 1 gene will go to focus page or articles page
//     depending on if the articles page is active
export const determineRoute = async (
  urlSearchState: SearchState
): Promise<string> => {
  if (urlSearchState[SearchBarTerms.cnv].length > 0) {
    return "/detail";
  } else if (
    urlSearchState[SearchBarTerms.variant].length > 0 ||
    urlSearchState[SearchBarTerms.gene].length > 1
  ) {
    return "/articles";
  } else if (
    urlSearchState[SearchBarTerms.gene].length === 1 &&
    urlSearchState[SearchBarTerms.variant].length === 0
  ) {
    const proteinCoding = await isProteinCoding(
      urlSearchState[SearchBarTerms.gene][0].text
    );
    // if we are on the articles page, do not redirect to /gene
    if (proteinCoding && window.location.pathname !== "/articles") {
      return "/gene";
    } else {
      return "/articles";
    }
  } else if (
    urlSearchState[SearchBarTerms.disease].length > 0 ||
    urlSearchState[SearchBarTerms.unii].length > 0 ||
    urlSearchState[SearchBarTerms.hpo].length > 0 ||
    urlSearchState[SearchBarTerms.keyword].length > 0
  ) {
    return "/diseases";
  } else return "";
};

// Function to remove multiple parameters from the URL and return the updated URL for expanding search.
export const modifyURLForSearchExpansion = (
  paramsToRemove: string[],
  location: Location
): string => {
  const searchParams = new URLSearchParams(location.search);

  // Remove the specified parameters
  paramsToRemove.forEach((param) => {
    searchParams.delete(param);
  });

  return `${location.pathname}?${searchParams.toString()}`;
};

// Function to check if any of the given parameters exist in the URL
export const hasAnyQueryParam = (
  paramsToCheck: string[],
  location: Location
): boolean => {
  const searchParams = new URLSearchParams(location.search);

  // Check if any of the given parameters exist in the URL
  return paramsToCheck.some((param) => searchParams.has(param));
};

export const checkSearchHasTag = (search: SearchState): boolean => {
  const hasTermTag =
    [
      ...search.gene,
      ...search.cnv,
      ...search.hpo,
      ...search.variant,
      ...search.keyword,
      ...search.disease,
      ...search.unii,
    ].length > 0;

  const hasSigCatTag =
    [...Object.keys(search.sig_terms), ...Object.keys(search.cats)].length > 0;

  return hasTermTag || hasSigCatTag;
};

export const checkUrlHasTag = (urlTermIds: SearchedTermIds): boolean => {
  const hasTermTag =
    [
      ...urlTermIds.gene,
      ...urlTermIds.cnv,
      ...urlTermIds.hpo,
      ...urlTermIds.variant,
      ...urlTermIds.keyword,
      ...urlTermIds.disease,
      ...urlTermIds.unii,
    ].length > 0;

  return hasTermTag;
};
