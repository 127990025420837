import axios from "axios";
import { useOpenSelectedModal } from "../components/modals/modalHooks";
import { Modals } from "../store/slices/modalSlice";
import { ErrorData } from "../store/slices/modalSlice";
import logError from "../utils/log-error";
import { determineBasicURL } from "../utils/search";

export const ERROR_TYPES = {
  limitsReached: "limits reached",
  feature: "feature",
  userRole: "authentication error",
};

export const useHandleError = () => {
  const openSelectedModal = useOpenSelectedModal();

  return (error: unknown) => {
    if (error instanceof Error) {
      void logError(error);
    }

    let message;

    if (axios.isAxiosError(error)) {
      const errResponse = error.response;
      if (errResponse === undefined) {
        message = String(error);
      } else {
        const resData = <ErrorData>errResponse.data;
        if (
          resData.error !== undefined &&
          Object.values(ERROR_TYPES).includes(resData.error)
        ) {
          const searchParams = new URLSearchParams(window.location.search);
          let redirect = {
            redirectUrl: "",
            redirectText: "",
          };
          if (resData.error === ERROR_TYPES.feature) {
            redirect = determineBasicURL(searchParams);
          }
          const modalError = {
            message: resData.message,
            redirect: redirect.redirectUrl,
            redirectText: redirect.redirectText,
          };
          openSelectedModal(Modals.alertWithLogin, modalError, false);
          message = resData.message;
        }
      }
    } else {
      message = String(error);
    }

    if (message === undefined) {
      message = String(error);
    }

    console.error(`There was an error: ${message}`);
  };
};
