import { CardActionArea, Stack } from "@mui/material";
import React, { useRef } from "react";
import { StyledCommonCard } from "../../StyledCommonCard";
import { Article } from "../../../../network/articles";
import { KeyboardArrowRight } from "@mui/icons-material";
import { primaryPalette } from "../../../../styles/theme";

type HeaderProps = {
  children: React.ReactNode;
};

type BodyProps = {
  children: React.ReactNode;
};

type FooterProps = {
  children: React.ReactNode;
};

type ArticleCardChildren = {
  Header: React.FC<HeaderProps>;
  Body: React.FC<BodyProps>;
  Footer: React.FC<FooterProps>;
};

type ArticleCardProps = {
  children: React.ReactNode;
  onCardClick: () => void;
  article: Article;
  selected: boolean;
};

const ArticleCard: React.FC<ArticleCardProps> & ArticleCardChildren = ({
  children,
  onCardClick,
  article,
  selected,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (selected) {
      ref.current?.focus();
    }
  }, [selected]);

  return (
    <StyledCommonCard
      data-testid={`article-card-${article.pmid}`}
      className={`${selected ? "selected" : ""}`}
      tabIndex={0}
      ref={ref}
    >
      <CardActionArea onClick={onCardClick}>
        <KeyboardArrowRight
          style={{
            height: ".6em",
            width: ".6em",
            position: "absolute",
            right: 0,
            top: ".3em",
            color: primaryPalette.teal.neutral_teal_t4,
          }}
        />
        <Stack direction="column" pt={1} pl={1} pb={1} pr={1.5} gap={0.5}>
          {children}
        </Stack>
      </CardActionArea>
    </StyledCommonCard>
  );
};

ArticleCard.Header = ({ children }: HeaderProps) => <div>{children}</div>;

ArticleCard.Body = ({ children }: BodyProps) => <div>{children}</div>;

ArticleCard.Footer = ({ children }: FooterProps) => <div>{children}</div>;

export default ArticleCard;
