import React from "react";
import { BasicWhiteCard } from "./BasicStyledComponents";
import { Button, Typography } from "@mui/material";
import { styled } from "styled-components";
import { primaryPalette } from "../../styles/theme";

const header = "Oops, something went wrong";

const HeaderText = styled(Typography)(() => ({
  color: "#009AA6",
  fontFamily: "Rubik Regular",
  fontSize: "11px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "13px",
  textTransform: "uppercase",
}));

const BodyText = styled(Typography)(() => ({
  maxWidth: "480px",
  textAlign: "center",
  color: primaryPalette.gray.variant_08,
  fontFamily: "Rubik Regular",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "300",
  lineHeight: "30px",
}));

const CTAButton = styled(Button)(() => ({
  display: "flex",
  padding: "4px 30px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  background: primaryPalette.teal.neutral_teal_t1,
  boxShadow:
    "2px 4px 4px 0px rgba(5, 46, 43, 0.08), -2px -4px 4px 0px rgba(255, 252, 240, 0.50)",
  textTransform: "unset",
  fontFamily: "Rubik Regular",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",
}));

type ErrorCardProps = {
  bodyText: string;
  buttonText: string;
  buttonFunction: () => void;
};

export const ErrorCard = ({
  bodyText,
  buttonText,
  buttonFunction,
}: ErrorCardProps) => {
  return (
    <BasicWhiteCard
      data-testid={"error-card"}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        alignSelf: "stretch",
      }}
    >
      <HeaderText>{header}</HeaderText>
      <BodyText>{bodyText}</BodyText>
      <CTAButton onClick={buttonFunction}>{buttonText}</CTAButton>
    </BasicWhiteCard>
  );
};
