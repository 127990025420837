import React, { useState } from "react";
import { SignificantCountResponse } from "../../../network/mm-significant-counts";
import { Box, Modal, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import SigTermModal from "./SigTermModal";
import { useAppSelector } from "../../../store/hooks";
import AddIcon from "@mui/icons-material/Add";
import {
  borderColor,
  hoverColor,
  modalStyle,
} from "../../../styles/category-popup-modal";

enum AllowedClosing {
  CANCEL = "Canceled",
  SELECTKEYWORDS = "Select Keywords",
}

export default function SigTermContainer(props: {
  sigTerms: SignificantCountResponse;
}) {
  const { sigTerms } = props;
  const selectedTerms = useAppSelector(
    (state) => state.filter.userSelectedSigTerms
  );

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = (_: object, reason: string) => {
    if (
      reason === AllowedClosing.CANCEL ||
      reason === AllowedClosing.SELECTKEYWORDS
    ) {
      setOpen(false);
    }
  };

  const handleCancel = () => {
    handleClose({}, AllowedClosing.CANCEL);
  };

  const handleSelect = () => {
    handleClose({}, AllowedClosing.SELECTKEYWORDS);
  };

  const selectedTermCount = () => {
    const selected = sigTerms.filter((a) =>
      Object.keys(selectedTerms).some((b) => a.term === b)
    );

    return selected;
  };

  const selectionText = () => {
    const selectedCount = selectedTermCount().length;
    if (selectedCount > 0) {
      return `${selectedCount}/${sigTerms.length} filters selected`;
    } else {
      return `${sigTerms.length} filters available`;
    }
  };

  return (
    <div>
      <Box
        onClick={handleOpen}
        sx={{
          padding: ".5rem",
          margin: ".5rem",
          border: `1px solid ${borderColor}`,
          ":hover": {
            bgcolor: hoverColor,
          },
        }}
      >
        <Grid container columns={5}>
          <Grid xs={3}>
            <Tooltip
              disableFocusListener
              title={
                "Articles containing terms that frequently co-occur in articles citing this gene and disease compared to articles for other genes and diseases."
              }
              placement={"top"}
            >
              <Typography align={"left"} variant={"body1"}>
                Terms
              </Typography>
            </Tooltip>
          </Grid>
          <Grid xs={2}>
            <Typography align={"right"} variant={"body1"}>
              {selectionText()} <AddIcon fontSize={"small"} />
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <SigTermModal
            terms={sigTerms}
            handleSelection={handleSelect}
            handleCancel={handleCancel}
          />
        </Box>
      </Modal>
    </div>
  );
}
