import React from "react";
import { getValueLabel } from "../../utils/value-label-utils";

interface Props {
  value: string;
}

const DisplayLabel: React.FC<Props> = ({ value }) => {
  const label = getValueLabel(value);

  return <>{label}</>;
};

export default DisplayLabel;
