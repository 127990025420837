"use client";

import * as React from "react";
import { styled } from "@mui/material/styles";
import { iconMap } from "../AssessmentBarWrapper";
import { Box, Stack, Typography, CardActionArea } from "@mui/material";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getFeatureFlagByName } from "../../../../store/selectors/featureFlagSelectors";
import { displayCuratedContent } from "../../../../store/slices/articleSlice";
import { primaryPalette } from "../../../../styles/theme";
import {
  AssessmentBarProps,
  VariantSubTypesDisplay,
} from "../../../../types/card";
import { VariantSubTypes } from "../../../../types/variant";
import { getProSignUpLink } from "../../../../utils/links";
import { isProUser } from "../../../../utils/user";
import { getVariantColor } from "../../../../utils/variant";
import { IconNext } from "../../../common/icons/Generic";
import { LogoGenomenonSmallNoBackground } from "../../../common/icons/SmallGLogo";
import ComponentLevelPaywall from "../../../gene/common/ComponentLevelPaywall";
import { RootState } from "../../../../store/store";

const CustomBox = styled(Box)(() => ({
  fontSize: "0.75rem",
  padding: "6px 4px 6px 16px",
  color: primaryPalette.gray.variant_01,
  position: "relative",
  "& .MuiBox-root": {
    position: "relative",
    minWidth: "24px",
  },
  "& .info": {
    right: "0",
    fontSize: "0.75rem",
    "& a, & a:hover, & a:focus": {
      color: primaryPalette.gray.variant_01,
      textDecorationColor: "unset",
    },
  },
  "& .request": {
    right: "0.25rem",
  },
  "& svg": {
    maxWidth: "0.875rem",
    height: "auto",
  },
  "& .LogoGenomenonSmallNoBackground": {
    position: "absolute",
    top: "0.125rem",
    left: "0.3125rem",
  },
  "& .IconNext": {
    margin: "0 0.25rem 0.063rem",
  },
  "& .IconVariantAssessment": {
    marginLeft: "0.375rem",
  },
}));

// TODO: this should rely on redux search slice urlTermIds, urlTermBooleans, urlCats, and urlSigTerms
const assessmentPageLink = (searchParams: URLSearchParams): string => {
  searchParams.delete("pmid");
  searchParams.delete("search_source");
  return `/interpretation?${searchParams.toString()}`;
};

const AssessmentBarSmall: React.FC<AssessmentBarProps> = ({
  curatedVariantData,
  ribbonData,
}) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const curatedContentVisible = useSelector((state: RootState) =>
    getFeatureFlagByName(state, "curated_content")
  );
  const [searchParams] = useSearchParams();

  const handleAssessmentClick = () => {
    if (curatedContentVisible) {
      dispatch(displayCuratedContent(true));
    } else {
      window.open(assessmentPageLink(searchParams), "_blank");
    }
  };

  if (curatedVariantData === undefined) {
    if (user.id === undefined) {
      return (
        <ComponentLevelPaywall
          titleText={"Basic Edition Preview"}
          bodyText={"Take a look inside for Genomenon and ClinVar evidence."}
          signUpLink={getProSignUpLink(user)}
          showLogIn={true}
        />
      );
    } else if (!isProUser(user)) {
      return (
        <ComponentLevelPaywall
          titleText={"pro edition feature"}
          bodyText={"Take a look inside for gene or variant curated evidence"}
          signUpLink={getProSignUpLink(user)}
          showLogIn={true}
        />
      );
    } else {
      return (
        <CustomBox
          sx={{ bgcolor: getVariantColor(VariantSubTypes.UNCURATED) }}
          data-testid="assessment-bar-small"
        >
          <Stack direction="row" gap={1}>
            <LogoGenomenonSmallNoBackground
              style={{ verticalAlign: "center" }}
            />
            <Typography
              color={primaryPalette.grey.neutral_grey_g1}
              variant="text12"
            >
              {VariantSubTypesDisplay[VariantSubTypes.UNCURATED]}
            </Typography>
          </Stack>
        </CustomBox>
      );
    }
  } else {
    const pathogenicity = curatedVariantData.acmg_call as VariantSubTypes;
    return (
      <CardActionArea
        data-testid="last-assessed-link"
        onClick={() => handleAssessmentClick()}
      >
        <CustomBox
          sx={{ bgcolor: getVariantColor(pathogenicity) }}
          data-testid="assessment-bar-small"
        >
          <Stack direction="row">
            <Box>
              <LogoGenomenonSmallNoBackground />
            </Box>
            <Stack>
              <Box style={{ paddingBottom: "0.125rem" }} className="info">
                {ribbonData?.record?.review_date && (
                  <Typography
                    variant="text12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "underline",
                    }}
                  >
                    View Evidence Last Assessed:{" "}
                    {new Date(
                      ribbonData?.record?.review_date
                    ).toLocaleDateString(undefined, {
                      year: "2-digit",
                      month: "numeric",
                      day: "numeric",
                    })}
                    <IconNext reversed />
                  </Typography>
                )}
              </Box>
              <Stack direction="row" sx={{ paddingBottom: "0.125rem" }}>
                <Typography variant="h3">
                  {VariantSubTypesDisplay[pathogenicity] ?? pathogenicity}
                </Typography>
                {iconMap[pathogenicity]}
              </Stack>
              <Typography variant="text12" fontWeight="fontWeightLight">
                {ribbonData?.record?.disease?.display_name}
              </Typography>
            </Stack>
          </Stack>
        </CustomBox>
      </CardActionArea>
    );
  }
  return <></>;
};

export default AssessmentBarSmall;
