import { Card, CardContent, IconButton, Typography } from "@mui/material";
import React from "react";
import { styled } from "styled-components";
import { primaryPalette } from "../../../../styles/theme";
import { InheritancePattern } from "../../../common/icons/InheritancePattern";
import { AssociationStrength } from "../../../common/icons/AssociationStrength";
import {
  DiseaseResponse,
  InheritanceNames,
} from "../../../../network/disease-relationships";
import { SmallGLogoBlue } from "../../../common/icons/SmallGLogo";
import { ClinicalEvidenceIcon } from "../../../common/icons/ClinicalEvidenceIcon";
import { FunctionalEvidenceIcon } from "../../../common/icons/FunctionalEvidenceIcon";
import DiseaseCardDropdown from "../DiseaseCardDropdown/DiseaseCardDropdown";

const CardFlexBox = styled.div({
  display: "flex",
  justifyContent: "space-between",
});

const CardFlexItem = styled.div({
  display: "flex",
  alignItems: "center",
});

const CardFlexItemGap = styled.div({
  display: "flex",
  gap: "16px",
});

const CardFlexColumn = styled.div({
  display: "flex",
  flexDirection: "column",
});

const displayAssociationStrength = (classification: string) => {
  return (
    <CardFlexItem>
      <AssociationStrength />
      &nbsp;
      <Typography
        align="center"
        variant="text14"
        color={primaryPalette.gray.variant_07}
      >
        {classification}
      </Typography>
    </CardFlexItem>
  );
};

const displayInheritancePattern = (inheritance: string) => {
  const inheritancePattern = inheritance
    .split(",")
    .map((pattern) => InheritanceNames[pattern.trim()]);
  if (inheritance === "") {
    return <></>;
  } else {
    return (
      <CardFlexItem>
        <InheritancePattern />
        &nbsp;
        <Typography
          align="center"
          variant="text14"
          color={primaryPalette.gray.variant_07}
        >
          {inheritancePattern.join(", ")}
        </Typography>
      </CardFlexItem>
    );
  }
};

function DiseaseCard({ props, id }: { props: DiseaseResponse; id: number }) {
  const { classification, inheritance, clinical_pmids, functional_pmids } =
    props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [clinicalOpen, setClinicalOpen] = React.useState<boolean>(false);
  const [functionalOpen, setFunctionalOpen] = React.useState<boolean>(false);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: "functional" | "clinical"
  ) => {
    setAnchorEl(event.currentTarget);
    if (item === "functional") {
      setFunctionalOpen(true);
      setClinicalOpen(false);
    } else {
      setClinicalOpen(true);
      setFunctionalOpen(false);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    setClinicalOpen(false);
    setFunctionalOpen(false);
  };

  return (
    <Card data-testid={`disease-card-${id}`}>
      <CardContent>
        <CardFlexBox>
          <Typography
            variant="text16"
            color={primaryPalette.grey.neutral_grey_g9}
          >
            {props.disease}
          </Typography>
        </CardFlexBox>
        <CardFlexItemGap>
          {(clinical_pmids.length > 0 || functional_pmids.length > 0) && (
            <CardFlexItem>
              <SmallGLogoBlue height="12" width="12" />
              &nbsp;
              <Typography
                variant="text14"
                style={{ textTransform: "uppercase" }}
                color={primaryPalette.blue.brand_main}
              >
                Evidence
              </Typography>
            </CardFlexItem>
          )}
          {clinical_pmids.length > 0 && (
            <CardFlexItem>
              <IconButton onClick={(e) => handleClick(e, "clinical")}>
                <ClinicalEvidenceIcon height="15" width="15" />
              </IconButton>
              <Typography variant="text14">{clinical_pmids.length}</Typography>
              <DiseaseCardDropdown
                open={clinicalOpen}
                pmids={clinical_pmids}
                anchorEl={anchorEl}
                handleClose={handleClose}
                type="clinical"
              />
            </CardFlexItem>
          )}

          {functional_pmids.length > 0 && (
            <CardFlexItem>
              <IconButton onClick={(e) => handleClick(e, "functional")}>
                <FunctionalEvidenceIcon height="15" width="15" />
              </IconButton>
              <Typography variant="text14">
                {functional_pmids.length}
              </Typography>
              <DiseaseCardDropdown
                open={functionalOpen}
                pmids={functional_pmids}
                anchorEl={anchorEl}
                handleClose={handleClose}
                type="functional"
              />
            </CardFlexItem>
          )}
        </CardFlexItemGap>
        <CardFlexColumn>
          {displayAssociationStrength(classification)}
          {displayInheritancePattern(inheritance)}
        </CardFlexColumn>
      </CardContent>
    </Card>
  );
}

export default DiseaseCard;
