import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React, { useMemo } from "react";
import { primaryPalette, theme } from "../../../../styles/theme";
import pluralize from "pluralize";
import { useAppSelector } from "../../../../store/hooks";
import { ExportIcon } from "../../../common/icons/ExportIcon";
import { GeneIcon } from "../../../common/icons/GeneIcon";
import { VariantIcon } from "../../../common/icons/VariantIcon";
import {
  SearchBarTerms,
  SearchedTermIds,
} from "../../../../store/slices/searchSlice";
import Paywall from "../paywalls/Paywall";
import { TargetSvg } from "../../../common/icons/Target";
import { StyledCommonCard } from "../../../common/StyledCommonCard";
import { isProUser } from "../../../../utils/user";
import ArticleListActions from "../ArticleListActions/ArticleListActions";
import { selectUrlTermIds } from "../../../../store/selectors/urlSelectors";
import { getRequestPayload } from "../../../../network/articles";
import { useSearchParams } from "react-router-dom";

interface ArticleListHeaderProps {
  allowArticlesCSVExport: boolean;
  shouldShowExactMatchLabel: boolean;
  articleHeaderRef: React.MutableRefObject<HTMLDivElement | null>;
}

const geneIcon = () => {
  return <GeneIcon width="10px" height="10px" />;
};

const variantIcon = () => {
  return <VariantIcon width="10px" height="10px" />;
};

function getDisplayIcon(urlTerms: SearchedTermIds) {
  const hasGene = urlTerms[SearchBarTerms.gene].length > 0;
  const hasMutation = urlTerms[SearchBarTerms.variant].length > 0;

  if (hasGene && hasMutation) {
    return (
      <>
        {geneIcon()}
        {variantIcon()}
      </>
    );
  } else if (hasGene) {
    return geneIcon();
  } else if (hasMutation) {
    return variantIcon();
  }

  return <></>;
}

const getDisplayText = (urlTerms: SearchedTermIds) => {
  const hasGene = urlTerms[SearchBarTerms.gene].length > 0;
  const hasMutation = urlTerms[SearchBarTerms.variant].length > 0;

  if (hasGene && hasMutation) {
    return "Gene & Variant Matches";
  } else if (hasGene) {
    return "Gene Matches";
  } else if (hasMutation) {
    return "Variant Matches";
  }
  return "";
};

function displayMatches(urlTerms: SearchedTermIds) {
  return (
    <Box data-testid="match-type-container" display="flex" alignItems="center">
      <Typography
        variant="h5"
        style={{ color: primaryPalette.teal.neutral_teal_t5 }}
      >
        {getDisplayText(urlTerms)}
      </Typography>
      &nbsp;
      {getDisplayIcon(urlTerms)}
    </Box>
  );
}

const ArticleListHeader = ({
  allowArticlesCSVExport,
  shouldShowExactMatchLabel,
  articleHeaderRef,
}: ArticleListHeaderProps) => {
  const [searchParams] = useSearchParams();
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const { user } = useAppSelector((state) => state.user);
  const {
    articleListData,
    articleSearchFilter,
    articleFilterItem,
    articleSortItem,
  } = useAppSelector((state) => state.articles);
  const { urlTermBooleans, urlCats, urlSigTerms } = useAppSelector(
    (state) => state.url
  );
  const articleCount = articleListData?.total ?? 0;
  const suppCount = articleListData?.suppCount ?? 0;
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const shouldShowPaywall = !isProUser(user);

  const getExportAllArticlesToCSVUrl = useMemo(() => {
    const requestPayload = getRequestPayload(
      urlTermIds,
      urlTermBooleans,
      urlCats,
      urlSigTerms,
      {
        sortField: articleSortItem.id,
        sortAsc: articleSortItem.asc || false,
        searchText: articleSearchFilter,
        filterText: articleFilterItem.id,
        offset: 0,
        all: true,
      }
    );

    delete requestPayload.highlight;

    const queryString = Object.entries(requestPayload)
      .flatMap(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map(
            (item: string | number | boolean) =>
              `${encodeURIComponent(key)}[]=${encodeURIComponent(String(item))}`
          );
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(
            String(value)
          )}`;
        }
      })
      .join("&");

    return `/association/articles?${queryString}`;
  }, [
    searchParams,
    articleSortItem.id,
    articleSortItem.asc,
    articleSearchFilter,
    articleFilterItem,
  ]);

  return (
    <Box
      ref={articleHeaderRef}
      display="flex"
      flexDirection="column"
      pr={isMobile ? 2 : 1}
      sx={{
        position: "sticky",
        top: "-8px",
        paddingTop: 3,
        zIndex: 5,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        width="100%"
      >
        <Typography
          variant="h3"
          data-testid="article-list-count"
          style={{
            fontWeight: 400,
            color: primaryPalette.grey.neutral_grey_g9,
          }}
        >
          {`${articleCount.toLocaleString()} ${pluralize(
            "Articles",
            articleCount
          )}`}
        </Typography>
        <ArticleListActions />
      </Box>

      <Box
        display="flex"
        alignItems="center"
        width="100%"
        justifyContent={allowArticlesCSVExport ? "space-between" : "flex-end"}
      >
        {allowArticlesCSVExport ? (
          <Button
            component="a"
            startIcon={<ExportIcon />}
            variant="text"
            target="_self"
            rel="noopener noreferrer"
            href={getExportAllArticlesToCSVUrl}
            sx={{
              textTransform: "none",
              fontSize: "14px",
              alignItems: "flex-start",
            }}
          >
            Export
          </Button>
        ) : null}

        {displayMatches(urlTermIds)}
      </Box>

      {shouldShowPaywall ? <Paywall suppCount={suppCount} /> : null}

      {shouldShowExactMatchLabel ? (
        <StyledCommonCard
          style={{ padding: "5px" }}
          data-testid="exact-match-counts"
        >
          <Typography variant="text11" display="inline">
            Exact match articles display &nbsp;
            <TargetSvg style={{ display: "inline-block" }} fontSize="inherit" />
          </Typography>
        </StyledCommonCard>
      ) : null}
    </Box>
  );
};

export default ArticleListHeader;
