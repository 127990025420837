import React, { useState } from "react";
import { Box, Button, Drawer, styled } from "@mui/material";
import {
  boxShadowStyles,
  elementHeights,
  primaryPalette,
  theme,
} from "../../../styles/theme";
import RelatedVariantsComponent from "../related-variants/RelatedVariants";
import { IconBack } from "../../common/icons/Generic";

const drawerWidth = 300;

const DrawerButton = styled(Button)({
  backgroundColor: primaryPalette.teal.neutral_teal_t1,
  color: primaryPalette.teal.neutral_teal_t4,
  fontSize: 12,
  minWidth: "auto",
  "&:hover": {
    backgroundColor: `${primaryPalette.teal.neutral_teal_t1}`,
  },
});

const AdditionalDetailsDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box>
      <DrawerButton
        onClick={() => setIsOpen(true)}
        sx={{
          position: "fixed",
          right: -63,
          top: 216,
          transform: "rotate(90deg)",
          padding: "2px 12px",
          borderRadius: "0 0 2px 2px",
          boxShadow: boxShadowStyles.default,
          zIndex: theme.zIndex.fab,
        }}
      >
        <IconBack
          color={primaryPalette.teal.neutral_teal_t4}
          width="12px"
          transform="rotate(-90deg)"
        />
        &nbsp;&nbsp;Related Variants
      </DrawerButton>

      <Drawer
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{
          "& .MuiDrawer-paper": {
            top: elementHeights.fixedHeaderContent,
            backgroundColor: "transparent",
            boxShadow: "unset",
          },
        }}
      >
        <Box width={320}>
          <DrawerButton
            onClick={() => setIsOpen(false)}
            sx={{
              position: "absolute",
              right: drawerWidth,
              top: 23,
              padding: "6px 2px 6px 4px",
              borderRadius: "2px 0 0 2px",
              zIndex: theme.zIndex.drawer,
            }}
          >
            <IconBack
              color={primaryPalette.teal.neutral_teal_t4}
              width="12px"
              transform="rotate(180deg)"
            />
          </DrawerButton>
          <Box
            padding="32px 8px 16px 16px"
            width={drawerWidth}
            height={elementHeights.pageContent}
            maxHeight={elementHeights.pageContent}
            overflow="auto"
            sx={{
              backgroundColor: primaryPalette.teal.neutral_teal_t1,
              float: "right",
            }}
          >
            <RelatedVariantsComponent />
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default AdditionalDetailsDrawer;
