import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Card, CardContent, Skeleton } from "@mui/material";

import HeaderVariant from "./HeaderVariant/HeaderVariant";
import Categories from "./Categories/Categories";
import AssessmentClinvar from "./AssessmentClinvar/AssessmentClinvar";
import {
  useLazyGetRibbonDataQuery,
  useLazyGetCuratedClinVarVariantContentQuery,
} from "../../../../network/variants/variants";
import ACMGRecords from "./ACMGRecords/ACMGRecords";
import { ErrorCard } from "../../../common/ErrorCard";
import { Modals } from "../../../../store/slices/modalSlice";
import { useOpenSelectedModal } from "../../../modals/modalHooks";
import { useAppSelector } from "../../../../store/hooks";
import {
  getDiscoveryPurchaseLink,
  getProSignUpLink,
} from "../../../../utils/links";
import ComponentLevelPaywall from "../../../gene/common/ComponentLevelPaywall";
import { boxShadowStyles, theme } from "../../../../styles/theme";
import { VariantResult } from "../../../../models/variant-result";
import { isGenomicReference, isRSID } from "../../../../utils/strings/regex";
import { SearchBarTerms } from "../../../../store/slices/searchSlice";
import { selectUrlTermIds } from "../../../../store/selectors/urlSelectors";
import { getGeneFromVariantSuggestions } from "../../../../utils/variant";
import { UrlParams } from "../../../../types/url-params";
import { reorderQueryParams } from "../../../../utils/search";
import { updateSelectedVariant } from "../../../../store/slices/articleSlice";
import { useDispatch } from "react-redux";
import {
  AssessmentBarType,
  CurationRecordVariant,
} from "../../../../types/articles";
import AssessmentBarWrapper from "../../common/AssessmentBarWrapper";

const GeneHeader = styled("div")({
  padding: "0.625rem 0.625rem 0.9375rem",
  ":last-child": {
    paddingBottom: "0.625rem",
  },
});

export const variantCardErrorText =
  "We had trouble retrieving the curated content for this search.";
export const variantCardErrorButtonText = "Contact Mastermind Support";

const VariantCard: React.FC = () => {
  const dispatch = useDispatch();
  const openSelectedModal = useOpenSelectedModal();
  const [searchParams, setSearchParams] = useSearchParams();

  const { user } = useAppSelector((state) => state.user);
  const { variant } = useAppSelector((state) => state.search);
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const { selectedVariant } = useAppSelector((state) => state.articles);

  const [searchedVariants, setSearchedVariants] = useState<
    CurationRecordVariant[]
  >([]);

  const [
    triggerCuratedData,
    {
      isLoading: isCuratedClinVarDataLoading,
      isFetching: isCuratedClinVarDataFetching,
      isUninitialized: isCuratedClinvarDataUninitialized,
      data: curatedClinVarData,
      isError: isCuratedClinVarError,
    },
  ] = useLazyGetCuratedClinVarVariantContentQuery();
  const [
    triggerRibbonData,
    {
      data: ribbonData,
      isLoading: isRibbonDataLoading,
      isError: isRibbonError,
    },
  ] = useLazyGetRibbonDataQuery();

  const urlVariants = useMemo(() => {
    return urlTermIds[SearchBarTerms.variant];
  }, [urlTermIds]);

  useEffect(() => {
    if (selectedVariant) {
      const currentSearchParamSelectedCuratedVariant = searchParams.get(
        UrlParams.SELECTED_CURATED_VARIANT
      );

      if (
        decodeURIComponent(currentSearchParamSelectedCuratedVariant || "") !==
        selectedVariant.id
      ) {
        setSearchParams(
          (searchParams) => {
            searchParams.set(
              UrlParams.SELECTED_CURATED_VARIANT,
              encodeURIComponent(selectedVariant.id)
            );
            const reorderedParams = reorderQueryParams(searchParams);

            return reorderedParams;
          },
          // update history if `selected_curated_variant` param exists, otherwise replace
          { replace: !currentSearchParamSelectedCuratedVariant }
        );
      }
    }
  }, [selectedVariant]);

  useEffect(() => {
    void triggerCuratedData(
      {
        urlTermIds: urlTermIds,
        urlString: urlTermIds.toString(),
      },
      true
    );
  }, [urlTermIds]);

  const setSuggAsSearchedVariants = () => {
    const curationRecordVariants: CurationRecordVariant[] = [];
    const currentSearchParamsSelectedVariant = searchParams.get(
      UrlParams.SELECTED_CURATED_VARIANT
    );

    for (let i = 0; i < urlVariants.length; i++) {
      let suggGene = VariantResult.getGene(urlVariants[i]);
      if (
        !suggGene ||
        isRSID([urlVariants[i]]) ||
        isGenomicReference([urlVariants[i]])
      ) {
        suggGene = getGeneFromVariantSuggestions(urlVariants[i], variant);
      }
      curationRecordVariants.push({
        id: urlVariants[i],
        acmg_call: "",
        article_count: 0,
        gene: suggGene,
        type: "",
      });
    }

    const currentSelectedVariant = curationRecordVariants.find(
      (variant) =>
        variant.id ===
        decodeURIComponent(currentSearchParamsSelectedVariant || "")
    );

    setSearchedVariants(curationRecordVariants);
    dispatch(
      updateSelectedVariant(currentSelectedVariant || curationRecordVariants[0])
    );
  };

  useEffect(() => {
    if (
      !isCuratedClinVarDataFetching &&
      curatedClinVarData?.curationRecords?.variants &&
      curatedClinVarData?.curationRecords?.variants.length > 0
    ) {
      // Sort the dropdown variants to show the searched curated variants first followed
      // by the non-searched curated variants
      const sortHash: Record<string, number> = {};
      urlTermIds.variant.forEach(
        (variantId, idx) => (sortHash[variantId] = idx)
      );
      const sortedArray = [...curatedClinVarData.curationRecords.variants].sort(
        (a, b) => (sortHash[a.id] ?? 1000) - (sortHash[b.id] ?? 1000)
      );

      setSearchedVariants(sortedArray);
      dispatch(updateSelectedVariant(sortedArray[0]));
    } else if (
      !isCuratedClinVarDataFetching &&
      !isCuratedClinvarDataUninitialized
    ) {
      // in the event we don't have curated content for the searched variant(s), we
      setSuggAsSearchedVariants();
    }
  }, [curatedClinVarData]);

  useEffect(() => {
    if (selectedVariant && selectedVariant.gene) {
      void triggerRibbonData(selectedVariant, true);
    }
  }, [selectedVariant]);

  const renderAssessmentClinvar = () => {
    if (
      curatedClinVarData?.clinVarRecords &&
      curatedClinVarData?.clinVarRecords?.length > 0
    ) {
      return (
        <AssessmentClinvar
          clinvarRecords={curatedClinVarData?.clinVarRecords}
        />
      );
    }
    return <></>;
  };

  const isStillLoading = isRibbonDataLoading || isCuratedClinVarDataLoading;

  const getCardBody = () => {
    if (isStillLoading || isCuratedClinVarError) {
      return <></>;
    } else if (isRibbonError) {
      return <>{renderAssessmentClinvar()}</>;
    } else if (user.id === undefined) {
      if (curatedClinVarData?.curationRecords?.isSponsored) {
        return (
          <>
            <AssessmentBarWrapper type={AssessmentBarType.SMALL} />
            <ComponentLevelPaywall
              titleText={"Basic Edition Preview"}
              bodyText={"Take a look inside for ClinVar evidence."}
              signUpLink={getProSignUpLink(user)}
              showLogIn={true}
            />
          </>
        );
      } else {
        return (
          <>
            <AssessmentBarWrapper type={AssessmentBarType.SMALL} />
          </>
        );
      }
    } else if (curatedClinVarData?.curationRecords?.curatedFor !== "") {
      const curatedDisease =
        curatedClinVarData?.curationRecords?.curatedFor ?? "";
      const msg = `This gene and variants have been curated in the context of ${curatedDisease}.  You must purchase a data license to see this content.`;
      return (
        <>
          <ComponentLevelPaywall
            titleText={"You have not purchased this content"}
            bodyText={msg}
            signUpLink={getDiscoveryPurchaseLink()}
            showLogIn={false}
            contactSales={true}
          />
        </>
      );
    } else {
      return (
        <>
          <AssessmentBarWrapper type={AssessmentBarType.SMALL} />
          {renderAssessmentClinvar()}
        </>
      );
    }
  };

  const getCardHeader = () => {
    if (isStillLoading) {
      return (
        <Skeleton variant={"rectangular"} height={"200px"} width={"100%"} />
      );
    }
    if (isCuratedClinVarError || isRibbonError) {
      return (
        <>
          <ErrorCard
            bodyText={variantCardErrorText}
            buttonText={variantCardErrorButtonText}
            buttonFunction={() => openSelectedModal(Modals.contactUs)}
          />
        </>
      );
    } else {
      return (
        <>
          {ribbonData?.record && (
            <>
              <ACMGRecords acmgRecords={ribbonData.record.json_record} />
              <Categories acmgRecords={ribbonData.record.json_record} />
            </>
          )}
        </>
      );
    }
  };

  return (
    <Card
      sx={{
        padding: 0,
        overflow: "unset",
        boxShadow: "none",
      }}
    >
      <CardContent
        sx={{
          fontFamily: theme.typography.fontFamily,
          padding: 0,
          outline: "1px solid transparent",
          borderRadius: "0.5rem",
          overflow: "hidden",
          boxShadow: boxShadowStyles.geneCard,
          transitionProperty: "box-shadow, outline-color",
          "&:last-child": {
            padding: "0",
          },
        }}
      >
        <GeneHeader>
          <HeaderVariant
            variant={selectedVariant}
            variants={searchedVariants}
          />
          {getCardHeader()}
        </GeneHeader>
        {getCardBody()}
      </CardContent>
    </Card>
  );
};

export default VariantCard;
