import React from "react";
import {
  BooleanSearchOptions,
  Suggestion,
} from "../../../../network/suggestions";
import { primaryPalette } from "../../../../styles/theme";
import { Typography } from "@mui/material";
import { FlexColumnDiv, FlexRowDiv } from "../../../common/Flex";
import { useAppDispatch } from "../../../../store/hooks";
import {
  removeTag,
  SearchBarTerms,
  SearchBarTermsType,
  swapTermBool,
  updateEditTagValue,
} from "../../../../store/slices/searchSlice";
import SearchBumpContents, { SearchBump } from "../SearchBump/SearchBump";

export const TermTypeLabel = {
  [SearchBarTerms.gene]: "Gene",
  [SearchBarTerms.variant]: "Variant",
  [SearchBarTerms.hpo]: "Phenotype",
  [SearchBarTerms.unii]: "Therapy",
  [SearchBarTerms.cnv]: "CNV",
  [SearchBarTerms.disease]: "Disease",
  [SearchBarTerms.keyword]: "Text",
};

type SearchBumpProps = {
  type: keyof SearchBarTermsType;
  terms: Suggestion[];
  boolConnector: BooleanSearchOptions;
};

const SearchTermSearchBumps = ({
  type,
  terms,
  boolConnector,
}: SearchBumpProps) => {
  const dispatch = useAppDispatch();

  return (
    <FlexColumnDiv style={{ alignItems: "unset" }}>
      <Typography
        variant="text12"
        style={{
          color: primaryPalette.teal.neutral_teal_t2,
          paddingBottom: "5px",
          lineHeight: "12px",
          letterSpacing: "0.24px",
        }}
      >
        {TermTypeLabel[type]}
      </Typography>
      <SearchBump>
        <FlexRowDiv style={{ gap: "5px", height: "100%" }}>
          {terms.map((sug, idx, terms) => {
            const onClear = () => dispatch(removeTag(sug));
            const onSwapBool = () => dispatch(swapTermBool(type));
            const onEdit = () => dispatch(updateEditTagValue(sug));
            const isLast = terms.length !== idx + 1;
            return (
              <SearchBumpContents
                key={`search-bump-${sug.type}-${sug.id}`}
                type={sug.type as string}
                text={sug.text}
                id={sug.id}
                isLast={isLast}
                boolConnector={boolConnector}
                onClear={onClear}
                onEdit={onEdit}
                onSwapBool={onSwapBool}
              />
            );
          })}
        </FlexRowDiv>
      </SearchBump>
    </FlexColumnDiv>
  );
};

export default SearchTermSearchBumps;
