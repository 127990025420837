import React from "react";
import { Box, Typography } from "@mui/material";
import { useGetSignificantTerms } from "../../../../hooks/useGetSignificantTerms";
import SigTermContainer from "../SigTermContainer";

export default function SignificantTerm() {
  const sigTerms = useGetSignificantTerms();

  return (
    <Box sx={{ padding: "2rem" }}>
      <Typography variant={"h4"}>Significant Terms in Abstract</Typography>
      <SigTermContainer sigTerms={sigTerms} />
    </Box>
  );
}
