import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";
import { primaryPalette } from "../../../styles/theme";

const GLogo = () => {
  return (
    <SvgIcon fontSize="inherit" data-testid={"genomenon-small-icon"}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="9"
        viewBox="0 0 12 11"
        fill="none"
      >
        <g clipPath="url(#clip0_810_18611)">
          <path
            d="M5.63293 10.8987C8.61447 10.8987 11.0315 8.48166 11.0315 5.50012C11.0315 2.51858 8.61447 0.101562 5.63293 0.101562C2.65139 0.101562 0.234375 2.51858 0.234375 5.50012C0.234375 8.48166 2.65139 10.8987 5.63293 10.8987Z"
            fill="white"
          />
          <path
            d="M5.63281 0.202889C8.55392 0.202889 10.9299 2.57889 10.9299 5.5C10.9299 8.42111 8.55392 10.7971 5.63281 10.7971C2.7117 10.7971 0.335701 8.42111 0.335701 5.5C0.335701 2.57889 2.7117 0.202889 5.63281 0.202889ZM5.63281 0C2.59559 0 0.132812 2.46278 0.132812 5.5C0.132812 8.53722 2.59559 11 5.63281 11C8.67003 11 11.1328 8.53722 11.1328 5.5C11.1328 2.46278 8.67003 0 5.63281 0Z"
            fill="#1F3781"
          />
          <path
            d="M5.63281 10.6064C8.45302 10.6064 10.7393 8.32021 10.7393 5.5C10.7393 2.67979 8.45302 0.393555 5.63281 0.393555C2.8126 0.393555 0.526367 2.67979 0.526367 5.5C0.526367 8.32021 2.8126 10.6064 5.63281 10.6064Z"
            fill="#1F3781"
          />
          <path
            d="M9.31796 5.06836H5.32129V5.93125H9.31796V5.06836Z"
            fill="white"
          />
          <path
            d="M5.63861 8.12552C4.19028 8.12552 3.01328 6.9473 3.01328 5.50019C3.01328 4.05308 4.1915 2.87363 5.63861 2.87363C6.73983 2.87363 7.68461 3.55563 8.0745 4.51996H8.9875C8.56339 3.07163 7.22261 2.01074 5.63984 2.01074C3.71606 2.01074 2.15039 3.57641 2.15039 5.50019C2.15039 7.42396 3.71606 8.98963 5.63984 8.98963C7.21895 8.98963 8.55606 7.93485 8.98506 6.49141H8.07083C7.6785 7.44963 6.73739 8.12552 5.63984 8.12552H5.63861Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_810_18611">
            <rect
              width="11"
              height="11"
              fill="white"
              transform="translate(0.132812)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export const LogoGenomenonSmallNoBackground = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      className="LogoGenomenonSmall"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4153_14675)">
        <path
          d="M7 0.258222C10.7178 0.258222 13.7418 3.28222 13.7418 7C13.7418 10.7178 10.7178 13.7418 7 13.7418C3.28222 13.7418 0.258222 10.7178 0.258222 7C0.258222 3.28222 3.28222 0.258222 7 0.258222ZM7 0C3.13444 0 0 3.13444 0 7C0 10.8656 3.13444 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13444 10.8656 0 7 0Z"
          fill={primaryPalette.gray.variant_01}
        />
        <path
          d="M11.6902 6.45093H6.60352V7.54915H11.6902V6.45093Z"
          fill={primaryPalette.gray.variant_01}
        />
        <path
          d="M7.00791 10.3413C5.16458 10.3413 3.66658 8.84173 3.66658 6.99995C3.66658 5.15817 5.16614 3.65706 7.00791 3.65706C8.40947 3.65706 9.61191 4.52506 10.1081 5.75239H11.2701C10.7304 3.90906 9.02391 2.55884 7.00947 2.55884C4.56103 2.55884 2.56836 4.5515 2.56836 6.99995C2.56836 9.44839 4.56103 11.4411 7.00947 11.4411C9.01925 11.4411 10.721 10.0986 11.267 8.2615H10.1035C9.60414 9.48106 8.40636 10.3413 7.00947 10.3413H7.00791Z"
          fill={primaryPalette.gray.variant_01}
        />
      </g>
    </svg>
  </SvgIcon>
);

export const LogoGenomenonSmallNoBackgroundBlue = ({
  width,
  height,
}: {
  width: string;
  height: string;
}) => (
  <SvgIcon sx={{ width: width, height: height }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
    >
      <g clipPath="url(#clip0_5255_70613)">
        <path
          d="M5 0.684444C7.65556 0.684444 9.81556 2.84444 9.81556 5.5C9.81556 8.15556 7.65556 10.3156 5 10.3156C2.34444 10.3156 0.184444 8.15556 0.184444 5.5C0.184444 2.84444 2.34444 0.684444 5 0.684444ZM5 0.5C2.23889 0.5 0 2.73889 0 5.5C0 8.26111 2.23889 10.5 5 10.5C7.76111 10.5 10 8.26111 10 5.5C10 2.73889 7.76111 0.5 5 0.5Z"
          fill="#142864"
        />
        <path
          d="M8.35013 5.10779H4.7168V5.89223H8.35013V5.10779Z"
          fill="#142864"
        />
        <path
          d="M5.0051 7.88668C3.68843 7.88668 2.61843 6.81557 2.61843 5.50001C2.61843 4.18446 3.68954 3.11223 5.0051 3.11223C6.00621 3.11223 6.8651 3.73223 7.21954 4.6089H8.04954C7.66398 3.29223 6.4451 2.32779 5.00621 2.32779C3.25732 2.32779 1.83398 3.75112 1.83398 5.50001C1.83398 7.2489 3.25732 8.67223 5.00621 8.67223C6.44176 8.67223 7.65732 7.71335 8.04732 6.40112H7.21621C6.85954 7.27223 6.00398 7.88668 5.00621 7.88668H5.0051Z"
          fill="#142864"
        />
      </g>
      <defs>
        <clipPath id="clip0_5255_70613">
          <rect
            width="10"
            height="10"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export const SmallGLogoBlue = ({
  width,
  height,
}: {
  width: string;
  height: string;
}) => (
  <SvgIcon sx={{ width: width, height: height }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
    >
      <g clipPath="url(#clip0_5963_11918)">
        <path
          d="M6.5 0.221333C9.68667 0.221333 12.2787 2.81333 12.2787 6C12.2787 9.18667 9.68667 11.7787 6.5 11.7787C3.31333 11.7787 0.721333 9.18667 0.721333 6C0.721333 2.81333 3.31333 0.221333 6.5 0.221333ZM6.5 0C3.18667 0 0.5 2.68667 0.5 6C0.5 9.31333 3.18667 12 6.5 12C9.81333 12 12.5 9.31333 12.5 6C12.5 2.68667 9.81333 0 6.5 0Z"
          fill="white"
        />
        <path
          d="M6.50035 11.571C9.57695 11.571 12.071 9.07695 12.071 6.00035C12.071 2.92376 9.57695 0.429688 6.50035 0.429688C3.42376 0.429688 0.929688 2.92376 0.929688 6.00035C0.929688 9.07695 3.42376 11.571 6.50035 11.571Z"
          fill="#142864"
        />
        <path
          d="M10.5202 5.5293H6.16016V6.47063H10.5202V5.5293Z"
          fill="white"
        />
        <path
          d="M6.5065 8.86403C4.9265 8.86403 3.64251 7.57869 3.64251 6.00003C3.64251 4.42136 4.92784 3.13469 6.5065 3.13469C7.70784 3.13469 8.7385 3.87869 9.16384 4.93069H10.1598C9.69717 3.35069 8.23451 2.19336 6.50784 2.19336C4.40917 2.19336 2.70117 3.90136 2.70117 6.00003C2.70117 8.09869 4.40917 9.80669 6.50784 9.80669C8.2305 9.80669 9.68917 8.65603 10.1572 7.08136H9.15984C8.73184 8.12669 7.70517 8.86403 6.50784 8.86403H6.5065Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5963_11918">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default GLogo;
