import React from "react";
import { InteractionDataType } from "./VariantHistogram";
import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { FlexColumnDiv } from "../../common/Flex";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { getVariantPositionLabel } from "../../../utils/variant";
import GenomenonPopper from "../../common/popper/GenomenonPopper";

type TooltipProps = {
  interactionData: InteractionDataType | undefined;
  anchorEl: SVGRectElement | null;
};

const TOP_VARIANT_TO_SHOW_COUNT = 10;

export const VariantDiagramAAPopper = ({
  interactionData,
  anchorEl,
}: TooltipProps) => {
  const open = Boolean(interactionData?.data && anchorEl);

  const children: React.JSX.Element[] = [];
  for (let i = 0; i < TOP_VARIANT_TO_SHOW_COUNT; i++) {
    if (interactionData?.data[i]?.mutation) {
      children.push(
        <TableRow key={`popover-table-${interactionData?.data[i]?.mutation}`}>
          <TableCell>{interactionData?.data[i]?.mutation}</TableCell>
          <TableCell align="right">
            {interactionData?.data[i]?.without_mut_hits}
          </TableCell>
        </TableRow>
      );
    }
  }

  return (
    <>
      {interactionData?.data && anchorEl && (
        <GenomenonPopper
          id={"variant-graph-popper"}
          open={open}
          anchorEl={anchorEl}
          placement={"right"}
        >
          <FlexColumnDiv style={{ padding: 10 }}>
            <Typography variant={"h5"}>
              Amino Acid Position{" "}
              {getVariantPositionLabel(interactionData?.data[0])}
            </Typography>
            <Typography variant={"body1"}>
              Variant Count: {interactionData?.data.length}
            </Typography>
            <Table
              size="small"
              key={`popover-table-${interactionData?.data[0]?.position}`}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Variant</TableCell>
                  <TableCell align="right">Total Articles</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{children}</TableBody>
            </Table>
          </FlexColumnDiv>
        </GenomenonPopper>
      )}
    </>
  );
};
