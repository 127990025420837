import * as React from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { Suggestion } from "../../../../../network/suggestions";
import { primaryPalette } from "../../../../../styles/theme";
import { TermTypeLabel } from "../../../SearchTagContainer/SearchTermSearchBumps/SearchTermSearchBumps";

type SearchOptionProps = {
  props?: React.HTMLAttributes<HTMLLIElement>;
  option: Suggestion;
  isDisabled: boolean;
};

const TermTypeDiv = styled.div((props) => ({
  display: "flex",
  padding: "2px 6px",
  gap: "8px",
  borderRadius: "2px",
  background: props.color,
  width: "75px",
  minWidth: "75px",
  color: "white",
  alignItems: "center",
}));

export const SearchOption = ({
  props,
  option,
  isDisabled,
}: SearchOptionProps) => {
  return (
    <li
      data-testid={"dropdown-search-option"}
      {...props}
      style={{
        padding: "4px 10px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div
        data-testid={`option-text-${option.type}-${option.id}`}
        style={{ display: "flex", gap: "8px" }}
      >
        <TermTypeDiv color={primaryPalette[option.type].variant_01}>
          <Typography variant="text12">{TermTypeLabel[option.type]}</Typography>
        </TermTypeDiv>
        <Typography
          variant="text14"
          color={primaryPalette.grey.neutral_grey_g9}
          style={{ overflowWrap: "anywhere" }}
        >
          {option.text}
        </Typography>
      </div>
      {!isDisabled && (
        <AddIcon
          data-testid={`add-icon-${option.type}-${option.id}`}
          fontSize={"small"}
          onClick={(e) => {
            e.shiftKey = true;
          }}
        />
      )}
    </li>
  );
};
