import { Box } from "@mui/material";
import React from "react";
import { isIntro, isProUser, isTrial } from "../../../utils/user";
import { useAppSelector } from "../../../store/hooks";
import { User, UserTypes } from "../../../types/user";

const navbarImages: Partial<Record<UserTypes, string>> = {
  [UserTypes.TRIAL]: "../../assets/mastermind_logo_navbar_trial_edition.svg",
  [UserTypes.BASIC]: "../../assets/mastermind_logo_navbar_basic_edition.svg",
  [UserTypes.PROFESSIONAL]:
    "../../assets/mastermind_logo_navbar_professional_edition.svg",
  [UserTypes.UNKNOWN]: "../../assets/mastermind_logo_simple_white.svg",
};

const homePageImages: Partial<Record<UserTypes, string>> = {
  [UserTypes.TRIAL]: "../../assets/mastermind_logo_trial_edition.svg",
  [UserTypes.BASIC]: "../../assets/mastermind_logo_basic_edition.svg",
  [UserTypes.PROFESSIONAL]:
    "../../assets/mastermind_logo_professional_edition.svg",
  [UserTypes.UNKNOWN]: "../../assets/mastermind_logo_simple_white.svg",
};

const getUserType = (isLoggedIn: boolean, user: User): Partial<UserTypes> => {
  if (!isLoggedIn || !user) return UserTypes.UNKNOWN;
  if (isIntro(user) || isTrial(user)) return UserTypes.TRIAL;
  if (isProUser(user)) return UserTypes.PROFESSIONAL;

  return UserTypes.BASIC;
};

export function MastermindNavbarLogo() {
  const { isLoggedIn, user } = useAppSelector((state) => state.user);
  const userType = getUserType(isLoggedIn, user as User);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <img
        data-testid={`logo-${userType}`}
        src={navbarImages[userType]}
        height="40px"
      />
    </Box>
  );
}

export function MastermindHomeLogo() {
  const { user, isLoggedIn } = useAppSelector((state) => state.user);
  const userType = getUserType(isLoggedIn, user as User);

  return (
    <Box width="100%" height="100%" display="flex">
      <img
        height="100%"
        width="100%"
        data-testid={`home-logo-${userType}`}
        src={homePageImages[userType]}
      />
    </Box>
  );
}
