import React from "react";
import { Divider, Link, Menu, MenuItem, Typography } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { pubmedLink } from "../../../../utils/links";
import { FunctionalEvidenceIcon } from "../../../common/icons/FunctionalEvidenceIcon";
import { FlexRowDiv } from "../../../common/Flex";
import { primaryPalette } from "../../../../styles/theme";
import { ClinicalEvidenceIcon } from "../../../common/icons/ClinicalEvidenceIcon";

interface DiseaseCardDropdownProps {
  pmids: string[];
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  type: "functional" | "clinical";
  open: boolean;
}

const listItemPadding = ".5rem 1rem 1rem";

const DiseaseCardDropdown: React.FC<DiseaseCardDropdownProps> = ({
  pmids,
  anchorEl,
  handleClose,
  type,
  open,
}) => {
  let title: string,
    icon: JSX.Element | null = null;

  switch (type) {
    case "functional":
      title = "Functional Evidence";
      icon = <FunctionalEvidenceIcon height="16" width="16" />;
      break;
    case "clinical":
      title = "Clinical Evidence";
      icon = <ClinicalEvidenceIcon height="16" width="16" />;
      break;
    default:
      title = "Evidence";
      icon = null;
  }

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      <FlexRowDiv
        data-testid={`${type}-icon`}
        style={{ padding: listItemPadding, gap: "5px" }}
      >
        {icon}
        <Typography variant="text16" color={primaryPalette.teal.variant_07}>
          {title}
        </Typography>
      </FlexRowDiv>
      <Divider />
      {pmids.map((item) => (
        <Link
          key={`link-${item}`}
          href={pubmedLink(item)}
          target={"_blank"}
          underline="none"
        >
          <MenuItem key={`menu-${item}`}>
            PMID {item}
            <OpenInNewIcon fontSize="inherit" style={{ marginLeft: 4 }} />
          </MenuItem>
        </Link>
      ))}
    </Menu>
  );
};

export default DiseaseCardDropdown;
