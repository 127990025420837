import React from "react";
import { useAppSelector } from "../../store/hooks";
import Grid from "@mui/material/Unstable_Grid2";
import ParentCategory from "./ParentCategory";
import { primaryPalette } from "../../styles/theme";
import SignificantTerm from "./SignificantTerms/SignificantTerm/SignificantTerm";
export default function FilterContainer() {
  const { categories } = useAppSelector((state) => state.categoryLayout);

  const acmgCategories = categories.slice(0, 1);
  const remainingCategories = categories.slice(1);
  const dividerColor = primaryPalette.teal.variant_05;

  return (
    <Grid container columnSpacing={8} columns={2}>
      <Grid xs={1}>
        <ParentCategory category={acmgCategories[0]} />
      </Grid>
      <Grid xs={1} sx={{ borderRight: `1px dotted ${dividerColor}` }}>
        {remainingCategories.map((category) => (
          <ParentCategory key={category.displayName} category={category} />
        ))}
        <SignificantTerm />
      </Grid>
    </Grid>
  );
}
