import { Grid, Stack } from "@mui/material";
import React from "react";
import { useGetAcmgClassesQuery } from "../../../../../network/acmg-descriptions";
import { RibbonJsonRecord } from "../../../../../network/variants/types";
import { getBackgroundColor } from "../../../../../utils/acmg";
import ACMGChip from "../../../../common/chips/ACMGChip";

export default function ArticleCardFooter({
  acmgRecords,
}: {
  acmgRecords: RibbonJsonRecord;
}) {
  const { data: acmgDescriptionData } = useGetAcmgClassesQuery(undefined);

  // TODO add loading state

  // TODO add error state?

  if (!acmgDescriptionData) return null;

  return (
    <Stack direction="row" gap={0.25}>
      {Object.keys(acmgRecords).map((text: string) => {
        const bgcolor = getBackgroundColor(
          acmgDescriptionData[text.toUpperCase()]
        );
        return (
          <Grid className="acmg-chip" item key={text}>
            <ACMGChip bgcolor={bgcolor} category={text} />
          </Grid>
        );
      })}
    </Stack>
  );
}
