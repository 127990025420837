import React from "react";
import { Typography, Grid, List, ListItem, Link } from "@mui/material";
import { BasicWhiteCard } from "../../common/BasicStyledComponents";
import {
  modifyURLForSearchExpansion,
  hasAnyQueryParam,
} from "../../../utils/search";
import { useLocation } from "react-router-dom";
import { primaryPalette } from "../../../styles/theme";

const NotFoundContent = () => {
  const categoryParams = ["cats", "sig_terms", "keyword"];
  const mutationParams = ["mutation"];
  const associationParams = ["disease", "therapy", "phenotype"];
  const location = useLocation();

  const renderExpansionLink = (params: string[], label: string) =>
    hasAnyQueryParam(params, location) && (
      <ListItem>
        <Typography variant="body1">
          <Link href={modifyURLForSearchExpansion(params, location)}>
            {label}
          </Link>
        </Typography>
      </ListItem>
    );

  return (
    <BasicWhiteCard
      data-testid={"notfound-card"}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        alignSelf: "stretch",
      }}
    >
      <Grid
        item={true}
        xs={12}
        sx={{
          textAlign: "center",
          marginTop: "70px",
          marginBottom: "70px",
          background: primaryPalette.teal.neutral_teal_t1,
        }}
      >
        <Typography variant="subtitle1" color="textSecondary" paragraph>
          Mastermind searched over 36 million abstracts, and over 9 million
          genomic full-text articles.
        </Typography>

        <Typography
          data-testid={"notfound-message"}
          variant="h5"
          color="textPrimary"
          sx={{ marginTop: "40px" }}
        >
          No Articles Match your search.
        </Typography>

        <Typography
          variant="subtitle2"
          color="textSecondary"
          paragraph
          sx={{ marginTop: "40px" }}
        >
          Alternatively, try one of the following options:
        </Typography>
        <List sx={{ marginLeft: "20%" }}>
          {renderExpansionLink(
            mutationParams,
            "Expand your search to see all the variants in this gene"
          )}
          {renderExpansionLink(
            categoryParams,
            "Expand your search to include all article categories"
          )}
          {renderExpansionLink(
            associationParams,
            "Expand your search to include all phenotypes/diseases/therapies"
          )}
        </List>
      </Grid>
    </BasicWhiteCard>
  );
};

export default NotFoundContent;
