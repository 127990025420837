import React from "react";
import { useAppSelector } from "../../../../store/hooks";
import AnonymousPaywall from "./AnonymousPaywall";
import { isProUser } from "../../../../utils/user";
import BasicPaywall from "./BasicPaywall";

export default function Paywall(props: { suppCount: number }) {
  const { isLoggedIn, user } = useAppSelector((state) => state.user);
  const { suppCount } = props;

  if (isLoggedIn && !isProUser(user)) {
    return <BasicPaywall suppCount={suppCount} />;
  } else {
    return <AnonymousPaywall />;
  }
}
