import { Tooltip, TooltipProps, tooltipClasses, styled } from "@mui/material";
import React from "react";
import { primaryPalette } from "../../styles/theme";

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: primaryPalette.grey.neutral_grey_g1,
    color: primaryPalette.grey.neutral_grey_g8,
    fontFamily: "Rubik Regular",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    padding: "10px 20px",
    borderRadius: "4px",
    boxShadow: "0px 12px 12px 0px rgba(0, 0, 0, 0.08)",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: primaryPalette.grey.neutral_grey_g1,
  },
}));
