import React from "react";
import ModalBox from "../../common/ModalBox";
import { hasCriteria, Summary } from "../../gene/summary/Summary/Summary";
import { AcmgCriteria } from "../../../types/gene";
import {
  initialACMGCriteria,
  useGetGeneInfoQuery,
} from "../../../network/genes";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAppSelector } from "../../../store/hooks";
import { SearchBarTerms } from "../../../store/slices/searchSlice";
import { selectUrlTermIds } from "../../../store/selectors/urlSelectors";

const style = (acmgCriteria: AcmgCriteria) => {
  const width = hasCriteria(acmgCriteria) ? "80%" : "50%";

  return {
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    gap: "10px",
    bgcolor: "background.paper",
    overflowY: "auto",
    maxHeight: "75%",
    width: width,
    display: "block",
  };
};

const GeneSummaryModal = () => {
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const { data: geneData } = useGetGeneInfoQuery(
    urlTermIds[SearchBarTerms.gene][0]
      ? {
          gene: urlTermIds[SearchBarTerms.gene][0],
        }
      : skipToken
  );

  return (
    <ModalBox
      style={style(geneData?.acmg_criteria?.data ?? initialACMGCriteria)}
      data-testid="gene-summary-modal"
    >
      <Summary isModal={true} />
    </ModalBox>
  );
};

export default GeneSummaryModal;
