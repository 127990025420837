import * as React from "react";
import { styled } from "@mui/material/styles";

import { Card, CardContent, Divider, Skeleton, Stack } from "@mui/material";
import HeaderGene from "./HeaderGene/HeaderGene";
import { useLazyGetGeneInfoQuery } from "../../../../network/genes";
import {
  DiseaseResponse,
  useLazyGetDiseaseRelationshipsQuery,
} from "../../../../network/disease-relationships";
import GeneGDR from "./GeneGDR/GeneGDR";
import {
  primaryPalette,
  boxShadowStyles,
  theme,
} from "../../../../styles/theme";
import { VariantResult } from "../../../../models/variant-result";
import { useAppSelector } from "../../../../store/hooks";
import { isProUser } from "../../../../utils/user";
import { getProSignUpLink } from "../../../../utils/links";
import ComponentLevelPaywall from "../../../gene/common/ComponentLevelPaywall";
import { SearchBarTerms } from "../../../../store/slices/searchSlice";
import { selectUrlTermIds } from "../../../../store/selectors/urlSelectors";

const CustomStack = styled(Stack)({
  padding: "5px",
  alignItems: "center",
  borderRadius: "0.25rem",
  boxShadow:
    "-2px -4px 4px rgba(255, 252, 240, 0.5), 2px 4px 4px rgba(5, 46, 43, 0.08)",
  "&:last-child": {
    marginBottom: "0",
  },
  "& .MuiStack-root": {
    marginTop: 0,
  },
});

const CustomDivider = styled(Divider)({
  margin: "5px 0px !important",
  color: primaryPalette.grey.neutral_grey_g3,
});

const GeneCard = () => {
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const { user } = useAppSelector((state) => state.user);
  const [
    getGeneInfoTrigger,
    { isLoading: isGeneInfoLoading, isError: isGeneInfoError },
  ] = useLazyGetGeneInfoQuery();
  const [
    getDiseaseRelationshipsTrigger,
    { isLoading: isDiseaseRelationshipLoading, data: geneDiseaseData },
  ] = useLazyGetDiseaseRelationshipsQuery();

  React.useEffect(() => {
    const genes = urlTermIds[SearchBarTerms.gene];
    genes.map(
      (gene) => void getGeneInfoTrigger({ gene: gene, source: "article" })
    );
    void getDiseaseRelationshipsTrigger(genes);
  }, [urlTermIds]);

  // if we don't have this, we can get into an infinite loop if the endpoint errors
  if (isGeneInfoError) {
    return <></>;
  }

  return (
    <Card
      sx={{
        padding: 0,
        overflow: "unset",
        boxShadow: "none",
      }}
    >
      <CardContent
        sx={{
          fontFamily: theme.typography.fontFamily,
          padding: "10px",
          outline: "1px solid transparent",
          borderRadius: "0.5rem",
          overflow: "hidden",
          boxShadow: boxShadowStyles.geneCard,
          transitionProperty: "box-shadow, outline-color",
          "&:last-child": {
            padding: "10px",
          },
        }}
      >
        {isGeneInfoLoading || isDiseaseRelationshipLoading ? (
          <Skeleton width={"100%"} height={"200px"} variant={"rectangular"} />
        ) : (
          <Stack spacing={1}>
            {urlTermIds[SearchBarTerms.gene].map((gene) => {
              const geneDiseases =
                geneDiseaseData?.[VariantResult.geneCasing(gene)];
              return (
                <CustomStack
                  key={`${gene}-card`}
                  direction="column"
                  spacing={3}
                  divider={
                    <CustomDivider
                      role={"divider"}
                      orientation="horizontal"
                      flexItem
                    />
                  }
                >
                  <HeaderGene gene={gene} />
                  {(geneDiseases?.diseases ?? []).length > 0 &&
                    isProUser(user) && (
                      <GeneGDR
                        geneDiseases={
                          geneDiseases?.diseases as DiseaseResponse[]
                        }
                      />
                    )}
                </CustomStack>
              );
            })}
            {!isProUser(user) && (
              <ComponentLevelPaywall
                titleText={"pro edition feature"}
                bodyText={"Genomenon has curated evidence for this variant"}
                signUpLink={getProSignUpLink(user)}
                showLogIn={true}
              />
            )}
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

export default GeneCard;
