import React from "react";
import styled from "styled-components";
import { primaryPalette } from "../styles/theme";
import { Button, ButtonGroup } from "@mui/material";

const SectionDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: fit-content;
  border: 1px solid black;
  height: fit-content;
  padding: 10px;
`;

const ComponentSectionDiv = styled.div({
  display: "flex",
  flexDirection: "row",
  gap: "5px",
  alignItems: "center",
});

const ButtonDiv = styled.div<ButtonDivProps>`
  padding: 10px 15px;
  width: fit-content;
  height: fit-content;
  background: ${(props: ButtonDivProps) =>
    props.background ? props.background : primaryPalette.teal.neutral_teal_t2};
`;

type ButtonDivProps = {
  background?: string;
};

const BumpSection = () => (
  <SectionDiv>
    <h3>Bump Styles</h3>
    <ComponentSectionDiv>
      <h5>Bump Button Teal</h5>
      <ButtonDiv background={primaryPalette.teal.neutral_teal_t6}>
        <Button variant="bump">Blarg</Button>
      </ButtonDiv>
    </ComponentSectionDiv>
    <ComponentSectionDiv>
      <h5>Bump Button Neutral</h5>
      <ButtonDiv>
        <Button variant="bump" color="secondary">
          Blarg
        </Button>
      </ButtonDiv>
    </ComponentSectionDiv>
    <ComponentSectionDiv>
      <h5>Bump Button Neutral Depressed</h5>
      <ButtonDiv>
        <Button variant="bumpDepressed" color="secondary">
          Blarg
        </Button>
      </ButtonDiv>
    </ComponentSectionDiv>
  </SectionDiv>
);

const BumpGroupSection = () => (
  <SectionDiv>
    <h3>Bump Group Styles</h3>
    <ComponentSectionDiv>
      <h5>Bump Group Primary</h5>
      <ButtonDiv background={primaryPalette.teal.neutral_teal_t6}>
        <ButtonGroup>
          <Button variant="bump">Bleep</Button>
          <Button variant="bump">Bloop</Button>
        </ButtonGroup>
      </ButtonDiv>
    </ComponentSectionDiv>
    <ComponentSectionDiv>
      <h5>Bump Group Secondary</h5>
      <ButtonGroup>
        <Button variant="bump" color="secondary">
          Bleep
        </Button>
        <Button variant="bumpDepressed" color="secondary">
          Bloop
        </Button>
      </ButtonGroup>
    </ComponentSectionDiv>
    <ComponentSectionDiv>
      <h5>Bump Group (3) Secondary</h5>
      <ButtonDiv>
        <ButtonGroup>
          <Button variant="bump" color="secondary">
            Bleep
          </Button>
          <Button variant="bumpDepressed" color="secondary">
            Bloop
          </Button>
          <Button variant="bump" color="secondary">
            Scooby
          </Button>
        </ButtonGroup>
      </ButtonDiv>
    </ComponentSectionDiv>
  </SectionDiv>
);

function CommonComponents() {
  return (
    <div className="page" style={{ display: "flex" }}>
      <BumpSection />
      <BumpGroupSection />
    </div>
  );
}

export default CommonComponents;
