import { Box } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../../../store/hooks";
import { SearchBarTerms } from "../../../../../store/slices/searchSlice";
import GeneMatch from "./GeneMatch";
import VariantMatch from "./VariantMatch";
import { selectUrlTermIds } from "../../../../../store/selectors/urlSelectors";

const ArticleGeneVarSection = () => {
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));

  return (
    <Box
      data-testid="gene-variant-matches"
      p="0 10px 0 30px"
      display="flex"
      alignItems="center"
    >
      {urlTermIds[SearchBarTerms.gene] ? <GeneMatch /> : null}
      {urlTermIds[SearchBarTerms.variant] ? <VariantMatch /> : null}
    </Box>
  );
};

export default ArticleGeneVarSection;
