import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FeatureFlags = "curated_content";

export interface FeatureState {
  home: boolean;
  articles: boolean;
  featuresLoaded: boolean;
  splitIO: SplitIOFeature;
}

export type SplitIOFeature = {
  features: {
    [key in FeatureFlags]: boolean;
  };
};

export const initialFeatureState: FeatureState = {
  home: false,
  articles: false,
  featuresLoaded: false,
  splitIO: {
    features: {
      curated_content: false,
    },
  },
};

export const featuresSlice = createSlice({
  name: "features",
  initialState: initialFeatureState,
  reducers: {
    loadFeatures: (
      state: FeatureState,
      action: PayloadAction<FeatureState | undefined>
    ) => {
      if (action.payload) {
        state.articles = action.payload.articles;
        state.home = action.payload.home;
        state.featuresLoaded = true;
      } else {
        state.articles = false;
        state.home = false;
        state.featuresLoaded = true;
      }
    },
    loadSplitIOFeatures: (
      state: FeatureState,
      action: PayloadAction<SplitIOFeature | undefined>
    ) => {
      if (action.payload) {
        state.splitIO = action.payload;
      }
    },
  },
});

export const { loadFeatures, loadSplitIOFeatures } = featuresSlice.actions;

export default featuresSlice.reducer;
