import { Chip, MenuItem } from "@mui/material";
import { isProUser } from "../../../../../utils/user";
import React from "react";
import { useAppSelector } from "../../../../../store/hooks";
import { primaryPalette } from "../../../../../styles/theme";
import { MenuItem as IMenuItem } from "../../../../../store/slices/articleSlice";

export type ListMenuItemProps = {
  type: string;
  onClick: (item: IMenuItem) => void;
  item: IMenuItem;
};

function ListMenuItem(props: ListMenuItemProps) {
  const { type, onClick, item } = props;
  const { user } = useAppSelector((state) => state.user);

  return (
    <MenuItem
      data-testid={`${type}-menu-item-${item.id}`}
      key={item.id}
      onClick={() => onClick(item)}
      sx={{
        pointerEvents: item.proOnly && !isProUser(user) ? "none" : "auto",
        cursor: item.proOnly && !isProUser(user) ? "default" : "pointer",
        color: item.proOnly && !isProUser(user) ? "gray" : "black",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {item.label}
      {item.proOnly && !isProUser(user) ? (
        <Chip
          label={"PRO"}
          size="small"
          sx={{
            backgroundColor: primaryPalette.blue.variant_01,
            marginLeft: "10px",
            color: "white",
          }}
        />
      ) : null}
    </MenuItem>
  );
}

export default ListMenuItem;
