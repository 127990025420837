import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type TextMatchFilterState = {
  [key: string]: boolean;
};

export const initialTextMatchFilterState: TextMatchFilterState = {
  viewGene: true,
  viewVariant: true,
  viewCnv: true,
  viewKeyword: true,
  viewPhenotype: true,
  viewTherapy: true,
};

export const textMatchFilterSlice = createSlice({
  name: "textMatchFilter",
  initialState: initialTextMatchFilterState,
  reducers: {
    setViewGene: (state, action: PayloadAction<boolean>) => {
      state.viewGene = action.payload;
    },
    setViewVariant: (state, action: PayloadAction<boolean>) => {
      state.viewVariant = action.payload;
    },
    setViewCnv: (state, action: PayloadAction<boolean>) => {
      state.viewCnv = action.payload;
    },
    setViewKeyword: (state, action: PayloadAction<boolean>) => {
      state.viewKeyword = action.payload;
    },
    setViewPhenotype: (state, action: PayloadAction<boolean>) => {
      state.viewPhenotype = action.payload;
    },
    setViewTherapy: (state, action: PayloadAction<boolean>) => {
      state.viewTherapy = action.payload;
    },
  },
});

export const {
  setViewGene,
  setViewVariant,
  setViewCnv,
  setViewKeyword,
  setViewPhenotype,
  setViewTherapy,
} = textMatchFilterSlice.actions;

export default textMatchFilterSlice.reducer;
