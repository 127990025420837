import { RibbonJsonRecord } from "../../../../network/variants/types";
import { ClinVarRecord } from "../../../../types/articles";
import { VariantSubTypes } from "../../../../types/variant";

export const acmgRecords: RibbonJsonRecord = {
  pm2: {
    short_desc: "Rare",
  },
  pp3: {
    short_desc: "Predicted Damaging",
  },
  pp2: {
    short_desc: "Typically Missense",
  },
  bs2: {
    short_desc: "Multiple Cases",
  },
  ps3: {
    short_desc: "Single Case",
  },
};

export const acmgRecordsMultiplePerCategory: RibbonJsonRecord = {
  pm2: {
    short_desc: "Rare",
  },
  bs1: {
    short_desc: "Bleep",
  },
  pp3: {
    short_desc: "Predicted Damaging",
  },
  bp4: {
    short_desc: "Predicted Damaging",
  },
  pp2: {
    short_desc: "Typically Missense",
  },
  bp1: {
    short_desc: "Typically Missense",
  },
  bs2: {
    short_desc: "Multiple Cases",
  },
  bs4: {
    short_desc: "Multiple Cases",
  },
  ps3: {
    short_desc: "Single Case",
  },
  bs3: {
    short_desc: "Single Case",
  },
};

export const mockCurationDescription = "ENPP1-deficiency (GACI/ARHR2)";

export const mockLastAssessed = "2024-02-21T21:30:54.352Z";

export const mockPathogenicVariant = {
  acmg_call: VariantSubTypes.PATHOGENIC,
  article_count: 100,
  gene: "apob",
  id: "apob:G266V",
  type: "substitution",
};

export const mockBenignVariant = {
  acmg_call: VariantSubTypes.BENIGN,
  article_count: 101,
  gene: "braf",
  id: "braf:K173Q",
  type: "substitution",
};

export const mockVUSVariant = {
  acmg_call: VariantSubTypes.VUS,
  article_count: 102,
  gene: "brca1",
  id: "brca1:R774C",
  type: "substitution",
};

export const mockVariantBadPathogenicity = {
  acmg_call: "scooby doo",
  article_count: 100,
  gene: "APOB",
  id: "apob:Bloop",
  type: "substitution",
};

export const clinVarRecords: ClinVarRecord[] = [
  {
    id: "VCV000013586",
    fullName: "NM_006208.3(ENPP1):c.2320C>T (p.Arg774Cys)",
    displayName: "NM_006208.3:c.2320C>T (p.Arg774Cys)",
    url: "https://www.ncbi.nlm.nih.gov/clinvar/variation/13586",
    interpretation: "Conflicting interpretations of pathogenicity",
    lastEvaluated: "2023-10-15",
    description: "single nucleotide variant",
    reviewStatus: "criteria provided, conflicting interpretations",
    reviewStars: 1,
    submissions: 9,
    mostRecent: "2023-01-10",
    citations: 2,
    submitterCalls: [
      {
        submitter:
          "Laboratory for Molecular Medicine, Mass General Brigham Personalized Medicine",
        call: "Likely benign",
      },
      {
        submitter: "GeneDx",
        call: "Benign",
      },
      {
        submitter: "Eurofins Ntd Llc (ga)",
        call: "Benign",
      },
      {
        submitter: "Illumina Laboratory Services, Illumina",
        call: "Likely benign",
      },
      {
        submitter: "Illumina Laboratory Services, Illumina",
        call: "Likely benign",
      },
      {
        submitter: "OMIM",
        call: "Benign",
      },
      {
        submitter:
          "Clinical Genomics, Uppaluri K&H Personalized Medicine Clinic",
        call: "Uncertain significance",
      },
      {
        submitter: "GeneReviews",
        call: "not provided",
      },
      {
        submitter: "Invitae",
        call: "Benign",
      },
    ],
  },
  {
    id: "VCV000013589",
    fullName: "NM_006208.3(ENPP1):c.517A>C (p.Lys173Gln)",
    displayName: "NM_006208.3:c.517A>C (p.Lys173Gln)",
    url: "https://www.ncbi.nlm.nih.gov/clinvar/variation/13589",
    interpretation: "Conflicting interpretations of pathogenicity",
    lastEvaluated: "2023-10-15",
    description: "single nucleotide variant",
    reviewStatus: "criteria provided, conflicting interpretations",
    reviewStars: 1,
    submissions: 12,
    mostRecent: "2023-01-10",
    citations: 6,
    submitterCalls: [
      {
        submitter: "Illumina Laboratory Services, Illumina",
        call: "Benign",
      },
      {
        submitter: "Illumina Laboratory Services, Illumina",
        call: "Benign",
      },
      {
        submitter: "Genome-Nilou Lab",
        call: "Benign",
      },
      {
        submitter: "Genome-Nilou Lab",
        call: "Benign",
      },
      {
        submitter: "Genome-Nilou Lab",
        call: "Benign",
      },
      {
        submitter:
          "Laboratory of Cyto-molecular Genetics, Department of Anatomy, All India Institute of Medical Sciences (AIIMS), New Delhi",
        call: "Likely pathogenic",
      },
      {
        submitter: "OMIM",
        call: "risk factor",
      },
      {
        submitter: "OMIM",
        call: "risk factor",
      },
      {
        submitter: "Eurofins Ntd Llc (ga)",
        call: "Benign",
      },
      {
        submitter: "OMIM",
        call: "risk factor",
      },
      {
        submitter:
          "Clinical Genomics, Uppaluri K&H Personalized Medicine Clinic",
        call: "Benign",
      },
      {
        submitter: "Invitae",
        call: "Benign",
      },
    ],
  },
  {
    id: "VCV000013594",
    fullName: "NM_006208.3(ENPP1):c.797G>T (p.Gly266Val)",
    displayName: "NM_006208.3:c.797G>T (p.Gly266Val)",
    url: "https://www.ncbi.nlm.nih.gov/clinvar/variation/13594",
    interpretation: "Pathogenic",
    lastEvaluated: "2023-10-15",
    description: "single nucleotide variant",
    reviewStatus: "criteria provided, single submitter",
    reviewStars: 1,
    submissions: 4,
    mostRecent: "2021-06-28",
    citations: 0,
    submitterCalls: [
      {
        submitter: "Institute of Human Genetics, Klinikum rechts der Isar",
        call: "Pathogenic",
      },
      {
        submitter: "UniProtKB/Swiss-Prot",
        call: "not provided",
      },
      {
        submitter: "OMIM",
        call: "Pathogenic",
      },
      {
        submitter: "OMIM",
        call: "Pathogenic",
      },
    ],
  },
  {
    id: "VCV000029842",
    fullName: "NM_006208.3(ENPP1):c.913C>A (p.Pro305Thr)",
    displayName: "NM_006208.3:c.913C>A (p.Pro305Thr)",
    url: "https://www.ncbi.nlm.nih.gov/clinvar/variation/29842",
    interpretation: "Conflicting interpretations of pathogenicity",
    lastEvaluated: "2023-10-15",
    description: "single nucleotide variant",
    reviewStatus: "criteria provided, conflicting interpretations",
    reviewStars: 1,
    submissions: 6,
    mostRecent: "2023-01-06",
    citations: 7,
    submitterCalls: [
      {
        submitter: "OMIM",
        call: "Pathogenic",
      },
      {
        submitter: "GeneDx",
        call: "Pathogenic",
      },
      {
        submitter: "Illumina Laboratory Services, Illumina",
        call: "Pathogenic",
      },
      {
        submitter: "Illumina Laboratory Services, Illumina",
        call: "Uncertain significance",
      },
      {
        submitter: "GeneReviews",
        call: "not provided",
      },
      {
        submitter: "Invitae",
        call: "Pathogenic",
      },
    ],
  },
];
