import { Box, Grid, Link, Typography } from "@mui/material";
import React from "react";
import DOMPurify from "dompurify";

import { useAppSelector } from "../../../../../store/hooks";
import { primaryPalette } from "../../../../../styles/theme";
import { pubmedLink } from "../../../../../utils/links";
import { ArticleHeader } from "../../StyledViewerComponents";
import AuthorList from "./AuthorList";

const ArticleMetadataSection = () => {
  const { data: articleData } = useAppSelector(
    (state) => state.featuredArticle
  );
  const authors = (articleData?.article?.authors ?? []).filter(
    (a) => a !== " "
  );

  if (!articleData) {
    return null;
  }

  return (
    <Box padding="10px 10px 10px 30px">
      <Grid container spacing={1}>
        <Grid item xl={8} paddingRight={2}>
          <Box data-testid="article-meta">
            <ArticleHeader>
              <Box
                display="flex"
                gap={1}
                color={primaryPalette.teal.neutral_teal_t4}
              >
                <Typography variant="h6">
                  {articleData.article.journal}
                </Typography>
                <Typography
                  variant="text12"
                  color={primaryPalette.teal.neutral_teal_t6}
                >
                  {articleData.article.publication_date}
                </Typography>
              </Box>

              <Typography
                variant="text12"
                color={primaryPalette.teal.neutral_teal_t6}
              >
                PMID{" "}
                <Link
                  underline="always"
                  target="_blank"
                  rel="noopener"
                  href={pubmedLink(articleData.article.pmid)}
                >
                  {" "}
                  {articleData.article.pmid}
                </Link>{" "}
              </Typography>
            </ArticleHeader>
            <Typography
              variant="articleHeadlineA1"
              color="#040404"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(articleData.article.title),
              }}
            />
          </Box>
        </Grid>

        <Grid item xl={4}>
          {authors && authors.length > 0 ? (
            <AuthorList authorList={authors} />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ArticleMetadataSection;
