"use client";

import * as React from "react";
import { styled } from "@mui/material/styles";

import { IconBack } from "../../../../common/icons/Generic";
import { Stack, Typography, Link } from "@mui/material";
import { VariantResult } from "../../../../../models/variant-result";
import { primaryPalette } from "../../../../../styles/theme";
import { useGetGeneInfoQuery } from "../../../../../network/genes";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import _ from "lodash";

const CustomStack = styled(Stack)({
  alignItems: "center",
  overflowX: "clip",
  width: "100%",
  marginTop: 0,
  "&:last-child": {
    marginBottom: "0",
  },
  "& > a": {
    display: "flex",
    textDecoration: "none",
    alignItems: "center",
    "& > svg": {
      maxWidth: "0.75rem",
      height: "auto",
      margin: "0 0.25rem 0 0.125rem",
    },
    "& > h2": {
      color: primaryPalette.grey.neutral_grey_g8,
    },
  },
});

type HeaderGeneProps = {
  gene: string;
};

const HeaderGene = ({ gene }: HeaderGeneProps) => {
  const { data: geneData } = useGetGeneInfoQuery(
    gene
      ? {
          gene: gene,
          source: "article",
        }
      : skipToken
  );
  return (
    <CustomStack direction="row" spacing={3}>
      <Link href={`/gene?gene=${gene}`} data-testid={"gene-back-link"}>
        <IconBack />
        <Typography variant="h2">{VariantResult.geneCasing(gene)}</Typography>
      </Link>
      <Stack direction="column">
        <Typography
          variant="text11"
          color={primaryPalette.grey.neutral_grey_g8}
        >
          {geneData?.gene_description}
        </Typography>
        <Typography
          variant="text11"
          color={primaryPalette.grey.neutral_grey_g6}
        >
          {_.isEmpty(geneData) ? "Non-protein coding" : geneData?.gene_type}
        </Typography>
      </Stack>
    </CustomStack>
  );
};

export default HeaderGene;
