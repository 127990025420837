import React, { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { BottomAxisProps } from "./BottomAxis";
import { primaryPalette } from "../../../styles/theme";
import { Domain } from "../../../types/gene";
import { updateVariantFilter } from "../../../store/slices/variantSlice";
import { useGetGeneInfoQuery } from "../../../network/genes";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { SearchBarTerms } from "../../../store/slices/searchSlice";
import { selectUrlTermIds } from "../../../store/selectors/urlSelectors";

interface ProteinsProps {
  scaleX: BottomAxisProps["scale"];
  setDomainPopoverData: React.Dispatch<
    React.SetStateAction<Domain | undefined>
  >;
  setTooltipAnchorEl: React.Dispatch<
    React.SetStateAction<SVGRectElement | null>
  >;
}

export function Proteins({
  scaleX,
  setDomainPopoverData,
  setTooltipAnchorEl,
}: ProteinsProps) {
  const dispatch = useAppDispatch();
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const { data: geneData } = useGetGeneInfoQuery(
    urlTermIds[SearchBarTerms.gene][0]
      ? {
          gene: urlTermIds[SearchBarTerms.gene][0],
        }
      : skipToken
  );
  const children: React.JSX.Element[] = [];
  const domainData = useMemo(
    () => (geneData?.domains ? geneData?.domains : []),
    [geneData?.domains]
  );

  const handleDomainClick = (domain: Domain) => {
    dispatch(updateVariantFilter(`${domain.start}-${domain.end}`));
  };

  const handleMouseEnter = (
    e: React.MouseEvent<SVGRectElement, MouseEvent>,
    domain: Domain
  ) => {
    setDomainPopoverData(domain);
    setTooltipAnchorEl(e.currentTarget);
  };

  const handleMouseLeave = () => {
    setDomainPopoverData(undefined);
    setTooltipAnchorEl(null);
  };

  domainData.forEach((d) => {
    children.push(
      <rect
        key={d.name}
        data-testid={`protein-domain-${d.name}`}
        style={{ cursor: "pointer" }}
        x={scaleX(d.start)}
        y={0}
        width={Math.abs(scaleX(d.end) - scaleX(d.start))}
        height={10}
        fill={primaryPalette.teal.variant_03}
        rx={6}
        onClick={() => handleDomainClick(d)}
        onMouseEnter={(e) => handleMouseEnter(e, d)}
        onMouseLeave={handleMouseLeave}
      />
    );
  });

  return <React.Fragment>{children}</React.Fragment>;
}
