import React, { useEffect } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import VariantBreakdown from "../../components/gene/variant-breakdown/VariantBreakdown/VariantBreakdown";
import { Summary } from "../../components/gene/summary/Summary/Summary";
import { VariantList } from "../../components/gene/variant-list/VariantList/VariantList";
import { useAppDispatch } from "../../store/hooks";
import { VariantHistogramContainer } from "../../components/gene/histogram/VariantHistogramContainer";
import { Modals } from "../../store/slices/modalSlice";
import { useOpenSelectedModal } from "../../components/modals/modalHooks";
import GeneDiseaseRelationship from "../../components/gene/gene-disease-relationship";
import { useSearchParams } from "react-router-dom";
import { UrlParams } from "../../types/url-params";
import { VariantResult } from "../../models/variant-result";

export const CIRCLE_GRAPH_BUTTON_HISTOGRAM_MINIMAP_HEIGHT = {
  height: 80,
  upDownPadding: 15,
  sidePadding: 10,
};

function Gene() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const openSelectedModal = useOpenSelectedModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    let title = "Mastermind Gene Detail";
    if (searchParams.get(UrlParams.GENE)) {
      title += ` - ${VariantResult.geneCasing(
        searchParams.get(UrlParams.GENE) ?? ""
      )}`;
    }
    document.title = title;
  });

  useEffect(() => {
    // We check the url params here since the redux terms may not be immediately
    // available and we do not want to show this modal while we are parsing them
    if (!searchParams.get(UrlParams.GENE)) {
      openSelectedModal(
        Modals.alertWithoutLogin,
        { message: "At least one gene is required." },
        false
      );
      return;
    }
  }, [dispatch, searchParams]);

  return (
    <div className="page">
      <Grid container data-testid={"gene-page"} style={{ margin: "0px" }}>
        <Grid item xs={12} lg={4}>
          <VariantBreakdown />
          <Summary />
        </Grid>
        <Grid item xs={12} lg={8}>
          <VariantHistogramContainer />
          <Grid
            container
            spacing={2}
            sx={{ marginTop: 0, marginLeft: 0, width: "100%" }}
          >
            <Grid
              item
              xs={12}
              xl={8}
              sx={{ paddingLeft: isMobile ? "0px!important" : "16px" }}
            >
              <VariantList />
            </Grid>
            <Grid
              item
              xs={12}
              xl={4}
              sx={{ paddingLeft: isMobile ? "0px!important" : "16px" }}
            >
              <GeneDiseaseRelationship />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Gene;
