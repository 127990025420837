import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../types/user";

export interface UserState {
  isLoggedIn: boolean;
  user: User | Record<string, never>;
  loadingUser: boolean;
}

export const initialState: UserState = {
  isLoggedIn: false,
  user: {},
  loadingUser: true,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // For use only on app load.
    // On App load, we call getCurrentUser and determine if user is logged in
    // or not. Load the results of that here.
    loadUser: (state: UserState, action: PayloadAction<User | undefined>) => {
      if (action.payload) {
        state.isLoggedIn = true;
        state.user = action.payload;
        state.loadingUser = false;
      } else {
        state.user = {};
        state.isLoggedIn = false;
        state.loadingUser = false;
      }
    },
    // When a user actively logs in, update state
    loginUser: (state: UserState, action: PayloadAction<User>) => {
      state.isLoggedIn = true;
      state.user = action.payload;
      state.loadingUser = false;
    },
    // When a user logs out, update state
    resetUser: (state: UserState) => {
      state.user = {};
      state.isLoggedIn = false;
      state.loadingUser = false;
    },
  },
});

export const { resetUser, loginUser, loadUser } = userSlice.actions;

export default userSlice.reducer;
