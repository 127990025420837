import * as React from "react";
import { Link as RRLink, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Badge, Box, Button, IconButton, useMediaQuery } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useOpenSelectedModal } from "../modals/modalHooks";
import { Modals } from "../../store/slices/modalSlice";
import { elementHeights, primaryPalette, theme } from "../../styles/theme";
import {
  MastermindHomeLogo,
  MastermindNavbarLogo,
} from "../common/icons/MastermindNavbarLogo";
import { useHandleError } from "../../hooks/errorHandlerHook";
import { fetchNotifications } from "../../network/notifications";
import { setNotifications } from "../../store/slices/notificationSlice";
import NavBarDropdown from "./NavBarDropdown/NavBarDropdown";
import AccountDropdown from "./CustomDropdowns/AccountDropdown";
import NotificationsDropdown from "./CustomDropdowns/NotificationsDropdown";
import NavBarItem from "./NarBarItem";
import { MenuItemId } from "./constants";
import AlertsDropdown from "./CustomDropdowns/AlertsDropdown";
import HelpDropdown from "./CustomDropdowns/HelpDropdown";
import { requestTrial } from "../../network/user-actions";
import { enqueueSnackbar } from "notistack";
import { isProUser } from "../../utils/user";
import { useCookies } from "react-cookie";

export const FIRST_TRIAL_BUTTON_MOBILE = "Request trial";
export const FIRST_TRIAL_BUTTON = "Request a Professional Edition trial";
export const FIRST_TRIAL_BANNER =
  "Your request for a Professional 7-day trial has been submitted. Our sales team will be in contact soon.";
export const SECOND_TRIAL_BUTTON_MOBILE = "Request Pilot";
export const SECOND_TRIAL_BUTTON = "Contact Sales to Pursue a Pilot";
export const SECOND_TRIAL_BANNER =
  "Your interest in our Professional Pilot has been shared! Our sales team will be in contact soon.";
export const ALREADY_SHOWN_TRIAL_BANNER = "already-shown-trial-banner-cookie";

const hoursSinceDatetime = (last_click: null | string) => {
  if (!last_click) {
    return 0;
  }
  const lastTimedTrialRequestDatetime = new Date(last_click);
  return (
    (new Date().getTime() - lastTimedTrialRequestDatetime.getTime()) /
    1000 /
    60 /
    60
  );
};

const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState<string | null>("");
  const [trialButton, setTrialButton] = React.useState<{
    label: string;
    shouldShow: boolean;
  }>({
    label: "Request Trial",
    shouldShow: false,
  });
  const [cookies, setCookie] = useCookies([ALREADY_SHOWN_TRIAL_BANNER]);
  const { isLoggedIn, user } = useAppSelector((state) => state.user);
  const { userMessages } = useAppSelector((state) => state.notifications);
  const dispatch = useAppDispatch();
  const handleError = useHandleError();
  const openSelectedModal = useOpenSelectedModal();
  const location = useLocation();
  const isHome = location.pathname === "/";
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const hoursSinceLastTrialRequest = hoursSinceDatetime(
    user.last_timed_trial_request
  );

  /* For non-pro logged in users, we offer trials. First, the user sees a Request Trial button.
   - "Request Trial Button"
   - User clicks
   - Button disappears and banner replaces with info that request has been sent; This banner is present for 48 hours after clicking.
   - If user remains non-pro (not given a trial), "Request Pilot" button shows
   - User clicks
   - Button disappears and banner replaced with info that request has been sent; This banner is present for 24 hours after clicking.
   - No more button or banner
  */
  const showTrialButton = () => {
    if (!isLoggedIn || isProUser(user)) return { shouldShow: false, label: "" };

    let label = "";
    let shouldShow = false;
    if (user.total_timed_trial_requests === 0) {
      label = isMobile ? FIRST_TRIAL_BUTTON_MOBILE : FIRST_TRIAL_BUTTON;
      shouldShow = true;
    } else if (
      user.total_timed_trial_requests === 1 &&
      hoursSinceLastTrialRequest >= 48
    ) {
      label = isMobile ? SECOND_TRIAL_BUTTON_MOBILE : SECOND_TRIAL_BUTTON;
      shouldShow = true;
    }
    return { shouldShow: shouldShow, label: label };
  };

  const showTrialBanner = () => {
    if (!isLoggedIn || isProUser(user)) return { shouldShow: false, text: "" };

    let text = "";
    let shouldShow = false;
    if (
      user.total_timed_trial_requests === 1 &&
      hoursSinceLastTrialRequest < 48
    ) {
      text = FIRST_TRIAL_BANNER;
      shouldShow = true;
    } else if (
      user.total_timed_trial_requests === 2 &&
      hoursSinceLastTrialRequest < 24
    ) {
      text = SECOND_TRIAL_BANNER;
      shouldShow = true;
    }
    return { shouldShow: shouldShow, text: text };
  };

  React.useEffect(() => {
    if (isLoggedIn) {
      fetchNotifications()
        .then((res) => {
          dispatch(setNotifications(res.data));
        })
        .catch((err) => {
          handleError(err);
        });
    }
  }, [isLoggedIn]);

  const handleDropdownOpen = (
    event: React.MouseEvent<HTMLElement>,
    item: string
  ) => {
    setAnchorEl(event.currentTarget);
    setOpen(item);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
    setOpen(null);
  };

  React.useEffect(() => {
    setTrialButton(showTrialButton());

    const trialBannerContent = showTrialBanner();
    if (trialBannerContent.shouldShow && !cookies[ALREADY_SHOWN_TRIAL_BANNER]) {
      enqueueSnackbar(trialBannerContent.text, {
        variant: "info",
        preventDuplicate: true,
      });
      setCookie(ALREADY_SHOWN_TRIAL_BANNER, true, {
        expires: new Date(new Date().getTime() + 60 * 60 * 24 * 1000),
      });
    }
  }, [user]);

  const handleTrialRequest = () => {
    requestTrial()
      .then(() => {
        setTrialButton({ shouldShow: false, label: "" });
        enqueueSnackbar("Your request was received", {
          variant: "success",
        });
      })
      .catch((err) => {
        handleError(err);
        enqueueSnackbar(
          "We encountered an error processing your request.  Please try again later.",
          { variant: "error" }
        );
      });
  };

  return (
    <AppBar
      data-testid="nav-bar"
      position={isHome ? "relative" : "fixed"}
      sx={{
        zIndex: theme.zIndex.appBar,
        boxShadow: "none",
        background: isHome
          ? primaryPalette.gradients.blue.branding
          : primaryPalette.blue.variant_02,
        "&:before": {
          ...(isHome
            ? {
                content: `" "`,
                position: "absolute",
                width: "100%",
                height: isMobile ? "115%" : "140%",
                top: isMobile ? "-15%" : "-40%",
                opacity: 0.3,
                backgroundImage:
                  "url('../../../assets/mastermind_data_burst_white.svg')",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }
            : {}),
        },
      }}
    >
      <Toolbar
        component="nav"
        sx={{
          minHeight: "unset!important",
          justifyContent: isHome ? "flex-end" : "space-between",
          height: elementHeights.navbar,
        }}
      >
        {!isHome ? (
          <RRLink
            to="/"
            style={{
              display: "block",
              float: "left",
              paddingLeft: "5px",
              marginRight: "10px",
            }}
            data-testid={MenuItemId.LOGO}
          >
            <MastermindNavbarLogo />
          </RRLink>
        ) : null}

        {trialButton.shouldShow ? (
          <Button
            data-testid="trial-request"
            onClick={() => handleTrialRequest()}
            variant="outlined"
            size="small"
            sx={{
              color: primaryPalette.gray.variant_01,
              border: `1px solid ${primaryPalette.gray.variant_01}`,
              background: primaryPalette.gradients.blue.branding,
              fontWeight: "bold",
              textTransform: "unset",
              whiteSpace: "nowrap",
              borderRadius: "4px",
              "&:hover, &:focus": {
                border: `1px solid ${primaryPalette.gray.variant_01}`,
                background: primaryPalette.gradients.blue.branding,
              },
            }}
          >
            {trialButton.label}
          </Button>
        ) : null}

        <Box>
          <NavBarItem
            id={MenuItemId.CONTACT}
            label="Contact Us"
            onClick={() => openSelectedModal(Modals.contactUs)}
          />

          <NavBarItem id={MenuItemId.API} label="API" route="/api" />

          <NavBarItem
            id={MenuItemId.ALERTS}
            label="Alerts"
            onClick={(e) => handleDropdownOpen(e, MenuItemId.ALERTS)}
          >
            <NavBarDropdown
              handleClose={handleDropdownClose}
              anchorEl={anchorEl}
              open={open === MenuItemId.ALERTS}
            >
              <AlertsDropdown />
            </NavBarDropdown>
          </NavBarItem>

          {isLoggedIn ? (
            <NavBarItem
              id={MenuItemId.ACCOUNT}
              label="My Account"
              onClick={(e) => handleDropdownOpen(e, MenuItemId.ACCOUNT)}
            >
              <NavBarDropdown
                handleClose={handleDropdownClose}
                anchorEl={anchorEl}
                open={open === MenuItemId.ACCOUNT}
              >
                <AccountDropdown />
              </NavBarDropdown>
            </NavBarItem>
          ) : (
            <NavBarItem
              id={MenuItemId.LOGIN}
              label="Login"
              onClick={() => openSelectedModal(Modals.login)}
            />
          )}

          <NavBarItem
            id={MenuItemId.HELP}
            label="Help"
            onClick={(e) => handleDropdownOpen(e, MenuItemId.HELP)}
          >
            <NavBarDropdown
              handleClose={handleDropdownClose}
              anchorEl={anchorEl}
              open={open === MenuItemId.HELP}
            >
              <HelpDropdown />
            </NavBarDropdown>
          </NavBarItem>

          {isLoggedIn ? (
            <>
              <IconButton
                data-testid={MenuItemId.NOTIFICATIONS}
                size="large"
                color="inherit"
                onClick={(e) => handleDropdownOpen(e, MenuItemId.NOTIFICATIONS)}
              >
                <Badge
                  data-testid="notification-badge"
                  badgeContent={userMessages.length}
                  color="warning"
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>

              <NavBarDropdown
                handleClose={handleDropdownClose}
                anchorEl={anchorEl}
                open={open === MenuItemId.NOTIFICATIONS}
              >
                <NotificationsDropdown />
              </NavBarDropdown>
            </>
          ) : null}
        </Box>
      </Toolbar>

      {isHome ? (
        <Box height="250px" position="relative">
          <Box
            position="absolute"
            top="50%"
            left={0}
            right={0}
            width="35%"
            minWidth="400px"
            maxWidth="90%"
            mx="auto"
            sx={{ transform: "translateY(-50%)" }}
          >
            <MastermindHomeLogo />
          </Box>
        </Box>
      ) : null}
    </AppBar>
  );
};

export default Navbar;
