"use client";

import * as React from "react";
import { styled } from "@mui/material/styles";
import { primaryPalette } from "../../../../../styles/theme";

import { ACMGRecordsProps } from "../../../../../types/card";
import * as VariantKpiIcons from "../../../../common/icons/VariantKpis";
import { Badge, ListItemIcon, ListItemText, Stack } from "@mui/material";
import { ACMGCategories } from "../../../../../types/variant";
import { AcmgCategoryMapping } from "../../../../../utils/variant";

const ACMGItemsContainer = styled("div")({
  padding: "0 0.625rem 0.625rem",
  columnCount: 2,
});

const ACMGItemContainer = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  gap: "0.125rem",
  flex: "1 0 0",
});

const ACMGItemIcon = styled(ListItemIcon)({
  minWidth: "unset",
  "& svg": {
    maxWidth: "0.75rem",
    height: "auto",
  },
});

const ACMGItemText = styled(ListItemText)({
  color: primaryPalette.gray.variant_07,
  margin: 0,
  padding: "0 0.5rem 0.25rem 0.125rem",
  "& .MuiTypography-root": {
    fontSize: "0.75rem",
    lineHeight: 1.2,
  },
});

const iconMap: {
  [key in ACMGCategories]: React.JSX.Element; // Index signature
} = {
  [ACMGCategories.FUNCTIONAL]: <VariantKpiIcons.IconVial />,
  [ACMGCategories.INTRINSIC]: <VariantKpiIcons.IconGene />,
  [ACMGCategories.COMPUTATIONAL]: <VariantKpiIcons.IconComputation />,
  [ACMGCategories.CLINICAL]: <VariantKpiIcons.IconDoctor />,
  [ACMGCategories.POPULATION]: <VariantKpiIcons.IconPopulation />,
};

export const acmgGenericDescriptions: {
  [key in ACMGCategories]: string;
} = {
  [ACMGCategories.FUNCTIONAL]: "Functional Data",
  [ACMGCategories.INTRINSIC]: "Intrinsic Data",
  [ACMGCategories.COMPUTATIONAL]: "Computational Data",
  [ACMGCategories.CLINICAL]: "Clinical Data",
  [ACMGCategories.POPULATION]: "Population Data",
};

export const iconOrder = [
  ACMGCategories.FUNCTIONAL,
  ACMGCategories.CLINICAL,
  ACMGCategories.COMPUTATIONAL,
  ACMGCategories.POPULATION,
  ACMGCategories.INTRINSIC,
];

const ACMGRecords: React.FC<ACMGRecordsProps> = ({ acmgRecords }) => {
  if (Object.keys(acmgRecords).length) {
    return (
      <ACMGItemsContainer>
        {iconOrder.map((cat) => {
          const acmgList = AcmgCategoryMapping[cat];
          const categoryACMG = Object.keys(acmgRecords).filter((value) =>
            acmgList.includes(value)
          );
          // show a generic label if more than 1 acmg_call in category
          const label =
            categoryACMG.length > 1
              ? acmgGenericDescriptions[cat]
              : acmgRecords[categoryACMG[0]]?.short_desc;
          return (
            <ACMGItemContainer key={cat} data-testid={`acmg_category-${cat}`}>
              <ACMGItemIcon>{iconMap[cat]}</ACMGItemIcon>
              {categoryACMG.length > 0 ? (
                <Stack direction="row">
                  <ACMGItemText primary={label} />
                  {categoryACMG.length > 1 && (
                    <Badge
                      badgeContent={categoryACMG.length}
                      color="primary"
                      data-testid={"acmg-badge"}
                      sx={{
                        "& .MuiBadge-badge": {
                          fontSize: 9,
                          height: 15,
                          minWidth: 15,
                          right: "-2px",
                          top: "1px",
                        },
                      }}
                    />
                  )}
                </Stack>
              ) : (
                <ACMGItemText primary={"N/A"} />
              )}
            </ACMGItemContainer>
          );
        })}
      </ACMGItemsContainer>
    );
  }
  return <></>;
};

export default ACMGRecords;
