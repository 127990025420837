import React from "react";
import { useAppSelector } from "../../../../store/hooks";
import { getProSignUpLink } from "../../../../utils/links";
import ComponentLevelPaywall from "../../../gene/common/ComponentLevelPaywall";

export default function BasicPaywall(props: { suppCount: number }) {
  const { user } = useAppSelector((state) => state.user);
  const { suppCount } = props;

  return (
    <ComponentLevelPaywall
      titleText={"pro edition feature"}
      bodyText={`${suppCount} articles found with matches in supplemental data.`}
      signUpLink={getProSignUpLink(user)}
      showLogIn={false}
    />
  );
}
