import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { debounce } from "@mui/material/utils";
import { getSuggestions, Suggestion } from "../../../../network/suggestions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  addTag,
  executeSearch,
  SearchBarTerms,
} from "../../../../store/slices/searchSlice";
import { SearchOption } from "./SearchOption/SearchOption";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useHandleError } from "../../../../hooks/errorHandlerHook";
import { LightTooltip } from "../../../common/Tooltips";
import { primaryPalette } from "../../../../styles/theme";
import {
  isConcurrent,
  isIntro,
  isProUser,
  isTrial,
} from "../../../../utils/user";
import ComponentLevelPaywall from "../../../gene/common/ComponentLevelPaywall";
import { getProSignUpLink } from "../../../../utils/links";
import { checkSearchHasTag } from "../../../../utils/search";
import { useSnackbar } from "notistack";

const SearchInstructionsOption = () => (
  <div
    data-testid={"search-instructions-option"}
    style={{ padding: "4px 10px", display: "flex", gap: "5px" }}
  >
    <Typography
      variant="text12"
      style={{
        fontWeight: 600,
        textTransform: "uppercase",
        color: primaryPalette.teal.variant_07,
      }}
    >
      ADD TO SEARCH
    </Typography>
    <LightTooltip
      arrow
      placement={"right"}
      title="Shift + click to add multiple search terms"
    >
      <InfoOutlinedIcon
        fontSize="small"
        sx={{ color: primaryPalette.teal.variant_07 }}
      />
    </LightTooltip>
  </div>
);

type SearchAutocompleteProps = {
  maxWidth: string;
  isHome: boolean;
};

const SearchAutocomplete = ({ maxWidth, isHome }: SearchAutocompleteProps) => {
  const dispatch = useAppDispatch();
  const search = useAppSelector((state) => state.search);
  const { user } = useAppSelector((state) => state.user);
  const [options, setOptions] = useState<Suggestion[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>();
  const handleError = useHandleError();
  const { enqueueSnackbar } = useSnackbar();
  const canSelectAssociations =
    isTrial(user) || isProUser(user) || isConcurrent(user) || isIntro(user);

  useEffect(() => {
    if (search.editTagValue !== "") {
      setInputValue(search.editTagValue);
      inputRef.current?.focus();
    }
  }, [search.editTagValue]);

  const getOptionsDelayed = useCallback(
    debounce((query: string, callback: (options: Suggestion[]) => void) => {
      setIsLoading(true);
      const geneIds = search.gene.map((g) => g.id);
      getSuggestions(query, geneIds)
        .then((res) => {
          callback(res.data);
        })
        .catch((err) => {
          handleError(err);
          enqueueSnackbar("Something went wrong", { variant: "error" });
        });
    }, 200),
    [search]
  );

  const getOptionsCallback = (filteredOptions: Suggestion[]) => {
    setOptions([
      ...filteredOptions,
      {
        type: SearchBarTerms.keyword,
        id: inputValue,
        text: inputValue,
        synonym: "",
      },
    ]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (inputValue) {
      getOptionsDelayed(inputValue, getOptionsCallback);
    }
  }, [
    inputValue,
    getOptionsDelayed,
    search.gene,
    search.variant,
    search.cnv,
    search.unii,
    search.hpo,
    search.keyword,
    search.disease,
  ]);

  const runSearchOnEnter = React.useMemo(() => {
    return search.searchHasChanged && checkSearchHasTag(search);
  }, [search]);

  return (
    <Autocomplete
      data-testid={"mm-suggest"}
      id={"mm-suggest"}
      autoHighlight={true}
      PaperComponent={(paperProps) => {
        const { children, ...restPaperProps } = paperProps;
        return (
          <Paper {...restPaperProps}>
            {children}
            {!canSelectAssociations && (
              <ComponentLevelPaywall
                titleText={"pro edition feature"}
                bodyText={
                  "You could access so much more. Search for diseases, phenotypes, and therapies with Mastermind Pro."
                }
                signUpLink={getProSignUpLink(user)}
                showLogIn={false}
                onMouseDown={(e) => e.preventDefault()}
              />
            )}
          </Paper>
        );
      }}
      options={options}
      disableCloseOnSelect
      clearOnBlur
      openOnFocus
      freeSolo
      size={"small"}
      getOptionLabel={(option) => {
        return (option as Suggestion)?.text || (option as string);
      }}
      // disable filtering on client
      filterOptions={(options) => {
        // filter out existing terms from suggestions list
        const terms = [
          ...search.gene,
          ...search.variant,
          ...search.disease,
          ...search.unii,
          ...search.hpo,
          ...search.keyword,
          ...search.cnv,
        ];
        return options.filter(
          (s) => !terms.some((t) => t.id === s.id && t.type === s.type)
        );
      }}
      loading={isLoading}
      onInputChange={(_, newInputValue, reason) => {
        if (reason !== "reset") {
          setInputValue(newInputValue);
        }
      }}
      onKeyDown={(e: React.KeyboardEvent) => {
        // clear input value on "escape" to allow
        // user to submit search with "enter" key
        if (e.code === "Escape") {
          setInputValue("");
        }
        // only allow user to submit search with "enter" key if input is empty,
        // search tags exist, and search tags have changed since previous search
        if (e.code === "Enter" && inputValue === "" && runSearchOnEnter) {
          dispatch(executeSearch());
        }
      }}
      getOptionDisabled={(option) =>
        ["disease", "hpo", "unii"].includes(option.type) &&
        !canSelectAssociations
      }
      onChange={(e, newValue, reason) => {
        const event = e as React.MouseEvent;
        const val = newValue as Suggestion;
        if (val && reason === "selectOption") {
          if (event.shiftKey) {
            dispatch(addTag({ suggestion: val, shouldReplace: false }));
            const filteredOptions = options.filter(
              (op) => !(op.id === val.id && op.type === val.type)
            );
            setOptions(filteredOptions);
            if (filteredOptions.length === 0) {
              setInputValue("");
            }
          } else {
            dispatch(addTag({ suggestion: val, shouldReplace: true }));
            setInputValue("");
            setOptions([]);
          }
        }
      }}
      sx={{
        width: isHome ? "100%" : "300px",
        maxWidth: maxWidth,
        background: primaryPalette.teal.neutral_teal_t1,
        borderRadius: "6px 0 0 6px",
        "& .MuiOutlinedInput-root": {
          paddingRight: "6px!important",
          borderRadius: "0!important",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
          "&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderRadius: "4px 0 0 4px",
              borderColor: primaryPalette.focus.variant_02,
              borderWidth: "1px",
            },
        },
      }}
      isOptionEqualToValue={(option, value) => option.text === value.text}
      renderTags={() => null}
      inputValue={inputValue}
      renderOption={(params, option, state) => (
        <React.Fragment key={`option-dropdown-idx-${state.index}`}>
          {state.index === 0 && <SearchInstructionsOption />}
          <SearchOption
            key={`dropdown-sugg-${option.id}-${option.type}`}
            option={option}
            props={params}
            isDisabled={
              !canSelectAssociations &&
              ["disease", "hpo", "unii"].includes(option.type)
            }
          />
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          data-testid={"autocomplete-text-input"}
          {...params}
          autoFocus
          placeholder={"Add genes, variants, etc..."}
          inputRef={inputRef}
          InputProps={{
            sx: {
              height: "46px",
            },
            ...params.InputProps,
            endAdornment: (
              <IconButton
                sx={{ visibility: inputValue ? "visible" : "hidden" }}
                onClick={() => setInputValue("")}
              >
                <ClearIcon id="search-input-clear" />
              </IconButton>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchAutocomplete;
