import styled from "styled-components";
import { CondensedArticleSentence } from "../../../../network/articles";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import DOMPurify from "dompurify";
import { primaryPalette, textMatchColors } from "../../../../styles/theme";
import { ClickAwayListener, Divider, Typography } from "@mui/material";
import TextMatchTooltip from "../TextMatchTooltip";
import { LightTooltip } from "../../../common/Tooltips";
import { ArticleViewerText } from "../StyledViewerComponents";

const HighlightedSentence = styled.div`
  .gene highlight {
    background-color: ${textMatchColors.gene};
  }
  .variant highlight {
    background-color: ${textMatchColors.variant};
  }
  .cnv highlight {
    background-color: ${textMatchColors.cnv};
  }
  .keyword highlight {
    background-color: ${textMatchColors.keyword};
  }
  .hpo highlight {
    background-color: ${textMatchColors.phenotype};
  }
  .unii highlight {
    background-color: ${textMatchColors.therapy};
  }
`;

export default function ArticleSentence(props: {
  sentence: CondensedArticleSentence;
}) {
  const { sentence } = props;

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const articleSX = {
    fontSize: "13px",
    lineHeight: "18px",
    "&:hover": {
      cursor: "pointer",
    },
  };

  const popupContent = () => {
    return (
      <React.Fragment>
        <Typography
          variant="h5"
          color={primaryPalette.teal.neutral_teal_t4}
          marginBottom="5px"
        >
          Matches
        </Typography>
        {sentence.contentMatches.map((contentMatch, index) => {
          return (
            <React.Fragment key={index}>
              <TextMatchTooltip sentence={contentMatch} />
              {index !== sentence.contentMatches.length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  return (
    <Grid sm={6} md={12} xl={6}>
      <HighlightedSentence>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <LightTooltip
              disableFocusListener
              disableHoverListener
              disableTouchListener
              open={open}
              title={popupContent()}
              placement={"bottom"}
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: primaryPalette.grey.neutral_grey_g1,
                    maxWidth: "none",
                  },
                },
              }}
            >
              <ArticleViewerText
                onClick={handleTooltipOpen}
                sx={articleSX}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(sentence.content, {
                    ALLOWED_TAGS: ["highlight"],
                  }),
                }}
              />
            </LightTooltip>
          </div>
        </ClickAwayListener>
      </HighlightedSentence>
    </Grid>
  );
}
