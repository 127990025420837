import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { primaryPalette } from "../../styles/theme";
import { freeCuratedGenes, searchExamples } from "../../components/home";
import SearchExampleSection from "../../components/home/SearchExampleSection";

export default function Home() {
  const freeText = "free".toUpperCase();

  return (
    <div
      data-testid="home-page"
      className="home"
      style={{
        backgroundColor: primaryPalette.teal.neutral_teal_t2,
        minHeight: "calc(100vh - 383px)",
      }}
    >
      <Box
        data-testid="home-page-example-links"
        display="flex"
        justifyContent="center"
        py={1}
        px={4}
        sx={{
          background: primaryPalette.blue.variant_01,
          color: primaryPalette.gray.variant_01,
        }}
      >
        <Box>
          {searchExamples.map((ex, index) => (
            <SearchExampleSection
              key={`search-examples-${index}`}
              title={ex.title}
              examples={ex.examples}
            />
          ))}
        </Box>
      </Box>

      <Box my={4} mx="auto" px={4} maxWidth="850px">
        <Typography component="p" variant="text14" mb={2}>
          Mastermind offers {freeText} curated Gene-Disease Relationships and{" "}
          {freeText} Curated variant evidence for the following genes:
        </Typography>
        <Grid
          container
          data-testid="curated-gene-example-links"
          rowSpacing={1}
          columnSpacing={1}
        >
          {freeCuratedGenes.map((gene) => (
            <Grid item key={gene} xs={4} sm={3} md={2} textAlign="center">
              <Link
                to={`/gene?gene=${gene.toLowerCase()}&gene_op=and&search_source=homepage_example`}
                data-testid="curated-gene-link"
                style={{
                  fontSize: "14px",
                  lineHeight: 1,
                  color: "inherit",
                }}
              >
                {gene}
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}
