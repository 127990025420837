import { VariantResult } from "../../../../models/variant-result";

// export type Order = "asc" | "desc";
export enum Order {
  ASC = "asc",
  DESC = "desc",
}

export function getComparator<Key extends keyof VariantResult>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string | any },
  b: { [key in Key]: number | string | any }
) => number {
  return (a, b) => comparatorNullsLast(a, b, orderBy, order === Order.ASC);
}

// Function to order objects based on specific key's value
// Sorts nulls, undefined, and "N/A" last
export function comparatorNullsLast<T>(
  a: T,
  b: T,
  orderBy: keyof T,
  ascending: boolean
) {
  if (a[orderBy] === b[orderBy]) {
    return 0;
  }
  if (a[orderBy] === null || a[orderBy] === undefined || a[orderBy] === "N/A") {
    return 1;
  }
  if (b[orderBy] === null || b[orderBy] === undefined || b[orderBy] === "N/A") {
    return -1;
  }
  if (ascending) {
    return a[orderBy] < b[orderBy] ? -1 : 1;
  }
  return a[orderBy] < b[orderBy] ? 1 : -1;
}

export function filterMutations(
  variantResult: VariantResult,
  searchVal: string
) {
  let filterCdnaPosition,
    filterCdnaEditType,
    filterProteinPattern,
    filterProteinPosition;
  if (searchVal.match(/^c\.\d+([^aAtTgGcC]|[a-zA-Z]{2,})?$/)) {
    const position = searchVal.match(/\d+/);
    if (position) {
      filterCdnaPosition = Math.floor(parseInt(position[0]) / 3 + 2 / 3);
      filterCdnaEditType = searchVal.match(/[a-zA-Z]+$/);
    }
  } else if (searchVal.match(/^\d+/)) {
    filterProteinPosition = searchVal;
  } else {
    filterProteinPattern = searchVal.toLowerCase().replace(/^p\./, "");
  }

  if (!searchVal || searchVal === "") {
    return true;
  }

  // TODO: Figure out what the mmKey route is for and why its setting this filterExactMatch...

  // Match exact gene:variant name from the exact match results
  // if (filterExactMatch && filterExactMatch.find(
  //   () => { return variantResult.getName().toLowerCase() == key.toLowerCase(); }
  // )) {
  //   return true;
  // }

  if (filterCdnaPosition && variantResult.position === filterCdnaPosition) {
    // mutation.getName() is always of format <gene_symbol>:<protein_variant_name> (without the p.),
    // but we only want to match on the protein variant name.
    // Match case-sensitively so that e.g. "d" from "del" doesn't match the D amino acid
    if (!filterCdnaEditType) {
      return true;
    }

    // del or delins substring for single cDNA position can only be delins or fs
    if ("delins".match(new RegExp("^" + filterCdnaEditType.toString()))) {
      return variantResult.mutation.match(/\ddelins|fs$/);
      // ins substring can be either ins or fs
    } else if ("ins".match(new RegExp("^" + filterCdnaEditType.toString()))) {
      return variantResult.mutation.match(/\dins|fs$/);
      // Everything else should match up by substring
    } else {
      return variantResult.mutation.includes(filterCdnaEditType[0]);
    }
  }

  // Match proten patterns based on a range of amino acid positions (e.g. 1-10)
  if (filterProteinPosition && /[,-]/.test(filterProteinPosition)) {
    const proteinPattern = filterProteinPosition
      .split(/[,-]/)
      .map((pattern) => parseInt(pattern));
    const patternArr = [];

    for (let i = proteinPattern[0]; i <= proteinPattern[1]; i++) {
      patternArr.push(i);
    }

    return patternArr.some((pattern) => {
      return variantResult.position?.toString() === pattern.toString();
    });
  }

  if (
    filterProteinPattern &&
    variantResult.mutation.toLowerCase().indexOf(filterProteinPattern) !== -1
  ) {
    return true;
  }

  // Match protein positions exactly
  if (
    filterProteinPosition &&
    variantResult.position?.toString() === filterProteinPosition
  ) {
    return true;
  }

  return false;
}
