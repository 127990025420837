import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { executeSearch } from "../../../store/slices/searchSlice";
import { primaryPalette } from "../../../styles/theme";
import { checkSearchHasTag } from "../../../utils/search";

import FilterButton from "../../filter-search/FilterButton";
import SearchAutocomplete from "./SearchAutocomplete";
import { getSearchStyles } from "..";

const SearchInput = () => {
  const isHome = location.pathname === "/";
  const [isDisabled, setIsDisabled] = useState(false);
  const dispatch = useAppDispatch();
  const search = useAppSelector((state) => state.search);
  const maxWidthValue: string = isHome ? getSearchStyles(search) : "300px";

  useEffect(() => {
    setIsDisabled(!(search.searchHasChanged && checkSearchHasTag(search)));
  }, [search]);

  return (
    <ButtonGroup>
      <React.Fragment>
        <SearchAutocomplete maxWidth={maxWidthValue} isHome={isHome} />
        <ButtonGroup
          sx={{
            marginRight: "10px",
          }}
        >
          <FilterButton />
        </ButtonGroup>
        <Button
          data-testid="execute-search-button"
          variant="searchBump"
          color="primary"
          onClick={() => {
            dispatch(executeSearch());
          }}
          disabled={isDisabled}
          style={{
            color: !isDisabled
              ? primaryPalette.gray.variant_01
              : primaryPalette.gray.variant_06,
            fontStyle: !isDisabled ? "normal" : "italic",
            height: "46px",
            minWidth: "fit-content",
          }}
        >
          <Typography variant="text14">Search</Typography>
        </Button>
      </React.Fragment>
    </ButtonGroup>
  );
};

export default SearchInput;
