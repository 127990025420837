import React from "react";
import EvidenceViewer from "../EvidenceViewer";
import { ArrowBack } from "@mui/icons-material";
import { Button } from "@mui/material";
import EvidenceViewerErrorState from "../EvidenceViewerErrorState";
import { useAppDispatch } from "../../../../store/hooks";
import { displayCuratedContent } from "../../../../store/slices/articleSlice";

type EvidenceViewerWrapperProps = {
  error: boolean;
};

const EvidenceViewerWrapper = ({ error }: EvidenceViewerWrapperProps) => {
  const dispatch = useAppDispatch();

  // some error state
  if (error) {
    return <EvidenceViewerErrorState />;
  }

  // some loading state

  // some empty state

  // curated content
  return (
    <>
      {/* Not sure we end up going this route but it's here until we figure out how to return back to the article viewer */}
      <Button
        onClick={() => dispatch(displayCuratedContent(false))}
        startIcon={<ArrowBack />}
      >
        Return to Article
      </Button>
      <EvidenceViewer />
    </>
  );
};

export default EvidenceViewerWrapper;
