import React from "react";
import { Stack } from "@mui/material";
import EvidenceHeader from "./EvidenceHeader";
import ACMGCriteriaSection from "./ACMGCriteriaSection";
import EvidenceArticleListBody from "./EvidenceArticleListBody";
import { primaryPalette, boxShadowStyles } from "../../../styles/theme";

export default function EvidenceViewer() {
  return (
    <Stack
      data-testid="evidence-viewer-pane"
      spacing={2}
      sx={{
        backgroundColor: primaryPalette.gray.variant_01,
        borderRadius: "16px",
        boxShadow: boxShadowStyles.evidenceViewer,
      }}
    >
      <EvidenceHeader />
      <ACMGCriteriaSection />
      <EvidenceArticleListBody />
    </Stack>
  );
}
