import * as React from "react";
import { useAppSelector } from "../../../store/hooks";
import styled from "styled-components";
import { primaryPalette } from "../../../styles/theme";
import {
  SearchBarKeywordTerms,
  SearchBarTerms,
  SearchBarTermsType,
} from "../../../store/slices/searchSlice";
import SearchTermSearchBumps from "./SearchTermSearchBumps";
import KeywordSearchBumps from "./KeywordSearchBumps";

const TagContainerDiv = styled.div`
  display: flex;
  align-self: stretch;
  flex: none;
`;

const TagTermContainerDiv = styled.div`
  position: relative;
  margin-right: 30px;
  padding: 10px 10px 0px 20px;
  display: flex;
  align-items: center;
  background: ${primaryPalette.blue.variant_01};
  border-right: ${`1px solid ${primaryPalette.blue.variant_01}`};
  // create right arrow after each tag term container
  &:nth-child(even) {
    background: ${primaryPalette.blue.brand_main};
    border-right: ${`1px solid ${primaryPalette.blue.brand_main}`};
  }
  &:nth-child(odd) {
    background: ${primaryPalette.blue.variant_01};
    border-right: ${`1px solid ${primaryPalette.blue.variant_01}`};
  }
  &:after {
    content: "";
    position: absolute;
    display: block;
    right: -60px;
    top: 0;
    bottom: 0;
    width: 60px;
    height: 100%;
    background: inherit;
    -webkit-clip-path: polygon(0% 0, 50% 50%, 0 100%);
    clip-path: polygon(0% 0, 50% 50%, 0 100%);
    z-index: 2;
  }
  // fill color under arrow
  &:before {
    z-index: 1;
    background: inherit;
    content: "";
    position: absolute;
    display: block;
    left: -30px;
    top: 0;
    bottom: 0;
    width: 30px;
    height: 100%;
  }
  // do not fill color under "arrow" on first child
  &:first-of-type:before {
    left: 0;
    width: 0;
  }
`;

export default function SearchTagContainer() {
  const search = useAppSelector((state) => state.search);

  return (
    <TagContainerDiv data-testid={"tag-container-div"}>
      {(Object.keys(SearchBarTerms) as Array<keyof SearchBarTermsType>).map(
        (v) => {
          if (search[v].length === 0)
            return (
              <React.Fragment key={`tag-term-container-${v}`}></React.Fragment>
            );

          return (
            <TagTermContainerDiv
              key={`tag-term-container-${v}`}
              data-testid={`tag-term-container-${v}`}
            >
              <SearchTermSearchBumps
                type={v}
                terms={search[v]}
                boolConnector={search.booleans[v]}
              />
            </TagTermContainerDiv>
          );
        }
      )}
      {Object.keys(search.cats).length + Object.keys(search.sig_terms).length >
        0 && (
        <TagTermContainerDiv
          key={`tag-term-container-${SearchBarKeywordTerms.sig_terms}-${SearchBarKeywordTerms.cats}`}
          data-testid={`tag-term-container-${SearchBarKeywordTerms.sig_terms}-${SearchBarKeywordTerms.cats}`}
        >
          <KeywordSearchBumps />
        </TagTermContainerDiv>
      )}
    </TagContainerDiv>
  );
}
